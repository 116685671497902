import { contract, UserNotificationType, UserRole } from '@pushflow/backend-http-contract'
import { initQueryClient } from '@ts-rest/react-query'
import React from 'react'

export type ApiClient = ReturnType<typeof initQueryClient<typeof contract, { baseUrl: '' }>>

export interface IUser {
  id: number
  name: string | null
  email: string
  postback: string | null
  telegram: string | null
  roles: UserRole[]
  isEmailConfirmed: boolean
  activeNotificationTypes: UserNotificationType[]
  isTrafficEnabled: boolean
}

const AppContext = React.createContext<{
  token: string | null
  setToken: (token: string) => void
  user: IUser | null
  refetchUser: () => void
  lang: string
  updateLang: (lang: string) => void
  setStatisticDays: (days: number) => void
  statisticDays: number
  apiClient: ApiClient
}>({
  token: null,
  setToken: () => {
    //
  },
  refetchUser: () => {
    //
  },
  user: null,
  lang: '',
  updateLang: () => {
    //
  },
  statisticDays: 14,
  setStatisticDays: () => {
    //
  },
  apiClient: {} as ApiClient,
})

function useAppState() {
  const context = React.useContext(AppContext)

  if (context === undefined) {
    throw new Error('useAppState must be used within a AppContext')
  }
  return context
}

export { AppContext, useAppState }
