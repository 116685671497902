import { initContract } from '@ts-rest/core';
import { auth } from './auth';

import {
  ErrorCode,
  ErrorCodeToMessage,
  HTTPStatusCodes,
} from './common/error.enum';

import { user } from './user';
import { videoCreative } from './video-creative';
import { creative } from './creative';
import { blackWhiteList } from './black-white-list';
import { stream } from './stream';
import { campaign } from './campaign';
import { report } from './report';

import { UserStatus } from './common/user-status';

import { trafficBlockers } from './traffic-blockers';
import { UserRole } from './common/user-role';
import { CampaignStatus } from './common/campaign-status';
import { CampaignType } from './common/campaign-type';
import { CreativeStatus } from './common/creative-status';
import { CreativeModerationStatus } from './common/creative-moderation-status';
import { UserNotificationType } from './common/user-notification-type';

import { scheduleTaskManager } from './schedule-task-manager';

import { StreamType } from './common/stream-type';
import { userAuditory } from './user-auditory';
import { inventory } from './inventory';
import { campaignTarget } from './campaign-target';
import { rule } from './rule';
import { ruleLog } from './rule-log';
import { streamSubstreamSettings } from './stream-substream-settings';
import { RequestValidationErrorSchema } from '@ts-rest/nest';
import { z } from 'zod';

const c = initContract();

export const contract = c.router(
  {
    auth,
    user,
    creative,
    stream,
    videoCreative,
    blackWhiteList,
    campaign,
    report,
    trafficBlockers,
    userAuditory,
    scheduleTaskManager,
    inventory,
    campaignTarget,
    streamSubstreamSettings,
    rule,
    ruleLog,
  },
  {
    commonResponses: {
      400: c.type<z.infer<typeof RequestValidationErrorSchema>>(),
    },
  },
);

export {
  ConditionTarget,
  Condition,
  ConditionSet,
  ResultAction,
  ResultActionValues,
  ResultActionTargetForChangeBid,
  ResultActionTargetForAddToList,
  RuleTarget,
  FrequencyType,
  TargetIdObject,
  Rule,
  FullRule,
  ResultActionTargetId,
  RuleTargetEnum,
  FrequencyTypeEnum,
  ConditionTargetEnum,
  ConditionTargetValues,
  RuleStatus,
} from './rule';

export {
  RuleLog,
  RuleLogSchema,
  CampaignId,
  CampaignIdSchema,
  StreamId,
  StreamIdSchema,
  SubstreamId,
  SubstreamIdSchema,
  FullRuleLog,
  FullRuleLogSchema,
  MatchedEntriesResult,
  MatchedEntriesResultSchema,
} from './rule-log';

export {
  TrafficBlockersData,
  TrafficBlockersCampaignData,
  TrafficBlockersStreamData,
  TrafficBlockersStreamSubstreamData,
  TrafficBlockersInterval,
  BlockersData,
  TotalData,
  BlockedByFeilds,
  TotalByFeilds,
  TRAFFIC_BLOCKERS_INTERVALS,
} from './traffic-blockers';

export { InventoryData } from './inventory';

export { AdmType } from './common/adm-types';

export { ErrorCodeToMessage };

export {
  ErrorCode,
  HTTPStatusCodes,
  UserStatus,
  UserRole,
  UserNotificationType,
  CampaignStatus,
  CampaignType,
  CreativeStatus,
  CreativeModerationStatus,
  StreamType,
};
