import { FullRuleLog, RuleLog } from '@pushflow/backend-http-contract'
import { Table } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { ActionOverview } from './action-overview'
import { MatchedEntriesView } from './matched-targets'
import { Link } from 'react-router-dom'
import { useAppState } from '../../../context/AppContext'

export const RuleLogView: FC<{ ruleIds: number[]; pageSize: number }> = ({ ruleIds, pageSize: pageSizeDefault }) => {
  const { apiClient } = useAppState()
  const [pageNumber, setPageNumber] = useState(1)
  const [pageSize, setPageSize] = useState(pageSizeDefault)
  const { data: logs, refetch } = apiClient.ruleLog.getRuleLogs.useQuery(['rule-logs', { ruleIds }], {
    query: {
      ruleIds: ruleIds,
      pagination: { pageNumber, pageSize },
    },
  })

  useEffect(() => {
    refetch()
  }, [pageNumber, pageSize])

  return (
    <Table<FullRuleLog>
      dataSource={logs?.body.items || []}
      rowKey={row => row.id.toString()}
      pagination={{
        current: pageNumber,
        pageSize: pageSize,
        total: logs?.body.total || 0,
        onChange: (page, pageSize) => {
          setPageNumber(page)
          setPageSize(pageSize)
        },
      }}
      columns={[
        {
          title: 'Log ID',
          dataIndex: 'id',
        },
        {
          title: 'Rule ID',
          dataIndex: 'ruleId',
          render: (id: number) => {
            return <Link to={`/automations/${id}`}>{id}</Link>
          },
        },
        {
          title: 'Actions',
          render: (row: RuleLog) => {
            return (
              <>
                <ActionOverview
                  ruleTarget={row.ruleRaw.ruleTarget}
                  resultAction={row.ruleRaw.resultAction}
                  resultActionTarget={row.ruleRaw.resultActionTarget}
                />
              </>
            )
          },
        },
        {
          title: 'Processed entries',
          render: (row: RuleLog) => {
            return <MatchedEntriesView matched={row.matchedEntries} />
          },
        },
        {
          title: 'Datetime',
          dataIndex: 'createdAt',
          render: (date: string) => {
            return <>{new Date(date).toLocaleString()}</>
          },
        },
      ]}
    />
  )
}
