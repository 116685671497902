export const getRuleError = (
  rule: any,
  checkCampaigns: 'all' | 'selected',
  campaignIds: number[]
): { type: 'error' | 'warning'; message: string } | null => {
  const { title, resultAction, resultActionTarget, frequency, frequencyType } = rule
  if (title === '') {
    return { type: 'warning', message: 'Please, enter rule name' }
  }
  if (checkCampaigns === 'selected' && campaignIds.length === 0) {
    return { type: 'warning', message: 'Please, select campaigns' }
  }
  if (resultAction === 'increase_bid' || resultAction === 'decrease_bid') {
    if (
      !resultActionTarget?.changeBidObj ||
      !resultActionTarget?.changeBidObj?.amount ||
      resultActionTarget?.changeBidObj?.amount <= 0
    ) {
      return { type: 'warning', message: 'Please, enter amount greater than 0' }
    }
    if (resultAction === 'increase_bid' && resultActionTarget?.changeBidObj?.limit > 200) {
      return { type: 'warning', message: 'Limit should be less than 200% for Increase bid action' }
    }
    if (resultAction === 'decrease_bid' && resultActionTarget?.changeBidObj?.limit < 40) {
      return { type: 'warning', message: 'Limit should be more than 40% for Decrease bid action' }
    }
  }
  if (resultAction === 'add_to_list') {
    if (!resultActionTarget?.listId) {
      return { type: 'warning', message: 'Please, select list' }
    }
  }
  if (frequencyType === 'every' && !frequency) {
    return { type: 'warning', message: 'Please, enter frequency' }
  }
  if (frequencyType === 'at' && !frequency && frequency !== 0) {
    return { type: 'warning', message: 'Please, enter frequency time' }
  }

  return null
}
