import { FrequencyType } from '@pushflow/backend-http-contract'
import { Tag } from 'antd'
import React, { FC } from 'react'

const formatTime = (time: number) => {
  if (time < 10) {
    return `0${time}:00`
  }
  return `${time}:00`
}

export const RepeatView: FC<{
  frequencyType: FrequencyType
  frequency: number
}> = ({ frequency, frequencyType }) => {
  return (
    <div
      style={{
        fontSize: '6pt',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'baseline',
      }}
    >
      {frequencyType === 'every' ? 'Every' : 'At'}
      <Tag>{frequencyType === 'at' ? formatTime(frequency) : frequency}</Tag>{' '}
      {frequencyType === 'every' ? 'hours' : "o'clock"}
    </div>
  )
}
