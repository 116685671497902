import BarChart from '../../components/BarChart'
import Statistic from '../../components/Statistic'
import calcCtr from '../../helpers/calcCtr'

import React from 'react'
import { Col, Row } from 'antd'
import { useAppState } from '../../context/AppContext'

const CampaignStatsContainer = (props: { campaignId: number }) => {
  const { apiClient } = useAppState()

  const { data, isLoading, isError } = apiClient.campaign.getCampaignStatistics.useQuery(
    ['form', 'campaign', props.campaignId, 'stats'],
    {
      params: {
        campaignId: props.campaignId,
      },
    },
    {
      queryKey: ['form', 'campaign', props.campaignId, 'stats'],
      initialData: {
        status: 200,
        body: { bids: 0, wonBids: 0, clicks: 0, cost: 0, chart: [] },
        headers: new Headers(),
      },
    }
  )

  if (isLoading) {
    return <p>Loading...</p>
  }

  if (isError || !data) {
    return <p>Sorry, this section is temporarily unavailable</p>
  }

  return (
    <>
      <Row>
        <Col span={8}>
          <Statistic title="Bids in the last 14 days" value={data.body.bids} suffix={undefined} isBorder={false} />
        </Col>
        <Col span={8}>
          <Statistic
            title="WonBids in the last 14 days"
            value={data.body.wonBids}
            suffix={calcCtr(data.body.wonBids, data.body.bids)}
            isBorder={false}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Clicked in the last 14 days"
            value={data.body.clicks}
            suffix={calcCtr(data.body.clicks, data.body.wonBids)}
            isBorder={false}
          />
        </Col>
        <Col span={8}>
          <Statistic
            title="Cost in the last 14 days"
            value={data.body.cost > 0 ? data.body.cost.toFixed(2) : 0}
            suffix={undefined}
            isBorder={false}
            // suffix={calcCtr(campaignStatistic.cost, campaignStatistic.shown)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 32 }}>
        <BarChart
          labels={data.body.chart.map(v => v.date)}
          datasets={[
            {
              label: 'Bids',
              data: data.body.chart.map(v => v.bids),
              backgroundColor: 'rgb(250, 155, 154)',
            },
            {
              label: 'Won Bids',
              data: data.body.chart.map(v => v.wonBids),
              backgroundColor: 'rgb(148, 167, 255)',
            },
            {
              label: 'Clicks',
              data: data.body.chart.map(v => v.clicks),
              backgroundColor: '#b2df8a',
            },
            {
              label: 'Cost',
              data: data.body.chart.map(v => v.cost),
              backgroundColor: 'rgb(47, 118, 255)',
            },
          ]}
          axesStacked={false}
          animations={false}
        />
      </Row>
    </>
  )
}

export default CampaignStatsContainer
