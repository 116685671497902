import React, { FC } from 'react'
import { RuleList } from './components/rule-list'
import { Button, PageHeader, Typography } from 'antd'
import { useNavigate } from 'react-router-dom'
import { RuleLogView } from './components/rule-log'

export const RuleListPage: FC = () => {
  const navigate = useNavigate()

  return (
    <>
      <PageHeader
        title="Automation rules"
        extra={[
          <Button
            key="create-new-rule-button"
            type="primary"
            onClick={() => {
              navigate('/automations/create')
            }}
          >
            Create new rule
          </Button>,
        ]}
      />

      <RuleList />
      <Typography.Title level={5}>Last logs</Typography.Title>
      <RuleLogView ruleIds={[]} pageSize={5} />
    </>
  )
}
