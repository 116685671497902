import { Alert, Form, Select, Input as Input2, Radio, Switch, Row, Col, Tooltip, RadioChangeEvent, Divider } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getCountryArray } from '../../common/static/countryCodes'
import { getShortLangArray } from '../../common/static/langCodes'
import { CONNECTION_TYPES } from '../../common/types/connectionTypes'
import { ISP_TYPES } from '../../common/types/ispTypes'
import HelpPopover from '../../components/HelpPopover/HelpPopover'
import Button from '../../components/buttons/Button/Button'
import FormSubHeader from '../../components/forms/FormSubHeader/FormSubHeader'
import Input from '../../components/forms/Input/Input'
import '../../components/forms/Input/Input.sass'
import MultiSelect from '../../components/forms/MultiSelect/MultiSelect'
import { defaultFormLayout, defaultFormLayoutOffset } from '../../helpers/formLayouts'
import showNotification from '../../helpers/showNotification'
import { TargetDaysAndHoursInput } from './components/TargetDaysAndHoursInput/TargetDaysAndHoursInput'
import { IncludeOrExclude } from '../../components/forms/IncludeOrExclude/IncludeOrExclude'
import { LINK, PUSH, VIDEO_PRE_ROLL } from '../../common/types/campaignTypes'
import { CPC, CPM } from '../../common/types/campaignBidModels.js'
import TimezoneSelect from '../../components/forms/TimezoneSelect/TimezoneSelect'
import { InputWithMacroses } from '../../components/forms/InputWithMacroses/InputWithMacroses'
import SubstreamsInput, { isSubstreamsValid } from './components/SubstreamsInput/SubstreamsInput'
// import CampaignEstimateModal from './CampaignEstimate'
import { useAppState } from '../../context/AppContext'
import LabelWithTooltip from '../../components/forms/LabelWithTooltip/LabelWithTooltip'
import { UserSwitchOutlined } from '@ant-design/icons'
import { useQueryClient } from '@tanstack/react-query'
import { useCampaignTags, useFetchTargets } from './hooks'
import { getStringsFormatted, getSubscriptionAgeHoursString } from './util'
import { CampaignType, UserRole, contract } from '@pushflow/backend-http-contract'
import { DataResponse } from '@ts-rest/react-query'

const { Option } = Select

const countries = getCountryArray()

type CampaignData = Omit<
  DataResponse<typeof contract.campaign.getCampaignSettings>['body'],
  'id' | 'status' | 'notificationEnabled' | 'userId'
>

const INITIAL_VALUES: CampaignData = {
  campaignType: 'PUSH',
  title: '',
  url: '',
  tags: [],
  bidType: 'CPC',
  price: 0,
  targetCpa: 0,
  streams: [],
  streamsInclude: true,
  substreams: '',
  substreamsInclude: true,
  whitelists: [],
  blacklists: [],
  auditories: [],
  auditoriesInclude: true,
  domains: '',
  domainsInclude: true,
  userAgents: '',
  userAgentsInclude: true,
  countryCode: [],
  countryCodeInclude: true,
  region: [],
  regionInclude: true,
  lang: [],
  langInclude: true,
  device: [],
  deviceInclude: true,
  os: [],
  osInclude: true,
  osVersion: [],
  osVersionInclude: true,
  browser: [],
  browserInclude: true,
  browserVersion: [],
  browserVersionInclude: true,
  connectionType: [],
  connectionTypeInclude: true,
  isp: [],
  ispInclude: true,
  subscriptionAgeEnabled: false,
  subscriptionAgeFrom: 0,
  subscriptionAgeTo: 0,
  subscriptionAgeUnitsChoice: 'hours',
  maxShownUser: 0,
  maxClickedUser: 0,
  maxShownUserAmtPeriod: 0,
  maxShownUserPeriod: 0,
  maxClickedUserAmtPeriod: 0,
  maxClickedUserPeriod: 0,
  maxDailyBudget: 0,
  maxTotalBudget: 0,
  maxClickedThreshold: 0,
  maxClickedAuditory: 0,
  maxShownThreshold: 0,
  maxShownAuditory: 0,
  scheduleTimezone: '+0',
  targetDaysAndHours: [],
}

const CampaignSettingsForm: FC<{
  campaign?: DataResponse<typeof contract.campaign.getCampaignSettings>['body']
  refetch?: () => void
}> = props => {
  const queryClient = useQueryClient()

  const { user, apiClient } = useAppState()

  const { data: campaignsTags } = useCampaignTags()

  const [form] = Form.useForm()

  const campaignType = Form.useWatch('campaignType', form)

  const { data: streams, refetch: refetchStreams } = apiClient.stream.getStreamAutocomplete.useQuery(
    ['streamsAutocomplete'],
    {
      query: {
        streamType: campaignType,
      },
    }
  )

  useEffect(() => {
    refetchStreams()
  }, [campaignType])

  const { data: blackWhiteLists } = apiClient.blackWhiteList.getListListPaginated.useQuery(['black-white-lists'], {
    query: {
      limit: 100,
      offset: 0,
    },
  })

  const campaignSettingsValues = apiClient.campaign.getCampaignSettingValues.useQuery(['settings-values'])

  const targets = useFetchTargets()

  const selectedOs = Form.useWatch('os', form) ?? []
  const selectedBrowser = Form.useWatch('browser', form) ?? []

  const selectedCountries: string[] = Form.useWatch('countryCode', form) ?? []

  const { data: regionsOptions } = apiClient.campaignTarget.getRegions.useQuery(['regions'], {
    query: {},
    overrideClientOptions: {
      jsonQuery: false,
    },
  })

  const regionsObj =
    selectedCountries && selectedCountries.length > 0
      ? Object.fromEntries(
          Object.entries(regionsOptions?.body || {}).filter(([country]) => {
            return selectedCountries.map(c => c.split(' ')[0]).includes(country)
          })
        )
      : regionsOptions?.body
  const regionsArr = Object.entries(regionsObj || {})

  const onCountryChange = (value: string[]) => {
    if (value.length === 0) {
      form.setFields([{ name: 'region', value: [] }])
    }
    const wrongRegions: string[] = []
    const allowedRegions: string[] = []
    value.forEach(country => {
      const regions = regionsObj?.[country.split(' ')[0].toLowerCase()]
      if (regions) {
        allowedRegions.push(...regions)
      }
    })
    const selectedRegions: string[] = form.getFieldValue('region')
    if (!selectedRegions || selectedRegions.length === 0) {
      return
    }
    selectedRegions.forEach(region => {
      if (!allowedRegions.includes(region)) {
        wrongRegions.push(region)
      }
    })
    if (wrongRegions.length > 0) {
      form.setFields([
        { name: 'region', value: selectedRegions.filter((region: string) => !wrongRegions.includes(region)) },
      ])
    }
  }

  const subscriptionAgeEnabled = Form.useWatch('subscriptionAgeEnabled', form)
  const subscriptionAgeFrom = Form.useWatch('subscriptionAgeFrom', form)
  const subscriptionAgeTo = Form.useWatch('subscriptionAgeTo', form)

  const subAgeUnits = Form.useWatch('subscriptionAgeUnitsChoice', form)

  const [isBudgetsLimitValid, setIsBudgetsLimitValid] = useState(() => {
    if (!props.campaign) {
      return false
    }

    const isMaxDailyBudgetSet = props.campaign.maxDailyBudget > 0
    const isMaxTotalBudgetSet = props.campaign.maxTotalBudget > 0

    return isMaxDailyBudgetSet || isMaxTotalBudgetSet
  })

  const createCampaignMutation = apiClient.campaign.createCampaign.useMutation({
    onSuccess: data => {
      showNotification({ type: 'success', message: 'Saved' })

      apiClient.campaign.getCampaignSettings.setQueryData(queryClient, ['form', 'campaign', data.body.id], () => data)

      navigate(`/campaign/${data.body.id}`)
    },
    onError: err => {
      if (err.status === 400) {
        showNotification({ type: 'error', message: 'Form validation error' })

        return
      }

      showNotification({ type: 'error', message: 'Error' })
    },
    onSettled: () => {
      setSubmitting(false)
    },
  })

  const updateCampaignMutation = apiClient.campaign.updateCampaignSettings.useMutation({
    onSuccess: () => {
      showNotification({ type: 'success', message: 'Saved' })
    },
    onError: err => {
      if (err.status === 400) {
        showNotification({ type: 'error', message: 'Form validation error' })

        return
      }

      showNotification({ type: 'error', message: 'Error' })
    },
    onSettled: () => {
      setSubmitting(false)
    },
  })

  const navigate = useNavigate()

  const [isFormValid, setIsFormValid] = useState(true)
  const [formInvalidReason, setFormInvalidReason] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const validateSubage = (
    subscriptionAgeEnabled: boolean,
    subscriptionAgeFrom: number,
    subscriptionAgeTo: number
  ): {
    reason: string
    status: boolean
  } => {
    if (subscriptionAgeEnabled && subscriptionAgeFrom > subscriptionAgeTo) {
      return { reason: 'Subscription period "from" should be less than "to"', status: false }
    }
    return { reason: '', status: true }
  }

  const validateBudget = () => {
    const maxDailyBudget = form.getFieldValue('maxDailyBudget')
    const maxTotalBudget = form.getFieldValue('maxTotalBudget')

    const isMaxDailyBudgetSet = maxDailyBudget !== null && maxDailyBudget > 0
    const isMaxTotalBudgetSet = maxTotalBudget !== null && maxTotalBudget > 0

    setIsBudgetsLimitValid(isMaxDailyBudgetSet || isMaxTotalBudgetSet)
  }

  const submit = (formValues: any) => {
    const maxDailyBudget = form.getFieldValue('maxDailyBudget')
    const maxTotalBudget = form.getFieldValue('maxTotalBudget')

    const isMaxDailyBudgetSet = maxDailyBudget !== null && maxDailyBudget > 0
    const isMaxTotalBudgetSet = maxTotalBudget !== null && maxTotalBudget > 0

    const isBudgetsLimitValid = isMaxDailyBudgetSet || isMaxTotalBudgetSet

    let substreamsValid = true

    if (typeof formValues.substreams === 'string') {
      substreamsValid = isSubstreamsValid(getStringsFormatted(formValues.substreams))
    }

    const subageCheck = validateSubage(
      formValues.subscriptionAgeEnabled,
      formValues.subscriptionAgeFrom,
      formValues.subscriptionAgeTo
    )

    const isFormValid = isBudgetsLimitValid && substreamsValid && subageCheck.status

    if (!isFormValid) {
      if (!subageCheck.status) {
        setFormInvalidReason(subageCheck.reason)
      } else {
        setFormInvalidReason('Some fields are invalid')
      }

      setIsFormValid(false)

      return
    }

    setSubmitting(true)

    if (props.campaign) {
      const result = contract.campaign.updateCampaignSettings.body.safeParse(formValues)

      if (!result.success) {
        const fieldsData: any[] = []

        const flattenErrors = result.error.flatten().fieldErrors

        for (const [key, data] of Object.entries(flattenErrors)) {
          fieldsData.push({
            name: key,
            errors: data,
          })
        }

        form.setFields(fieldsData)

        showNotification({
          type: 'error',
          message: 'Incorrect fields',
          description: Object.keys(flattenErrors).join(', '),
        })

        setSubmitting(false)

        return
      }

      updateCampaignMutation.mutate({ params: { campaignId: props.campaign.id }, body: formValues })

      return
    }

    const result = contract.campaign.createCampaign.body.safeParse(formValues)

    if (!result.success) {
      const fieldsData: any[] = []

      const flattenErrors = result.error.flatten().fieldErrors

      for (const [key, data] of Object.entries(flattenErrors)) {
        fieldsData.push({
          name: key,
          errors: data,
        })
      }

      form.setFields(fieldsData)

      showNotification({
        type: 'error',
        message: 'Incorrect fields',
        description: Object.keys(flattenErrors).join(', '),
      })

      setSubmitting(false)

      return
    }

    createCampaignMutation.mutate({ body: formValues })
  }

  const handleCampaignTypeChange = (e: RadioChangeEvent) => {
    if (e.target.value === PUSH) {
      form.setFields([{ name: 'bidType', value: CPC }])
    } else if (e.target.value === LINK) {
      form.setFields([{ name: 'bidType', value: CPM }])
    }
  }

  return (
    <Form
      form={form}
      initialValues={props.campaign ?? INITIAL_VALUES}
      onFieldsChange={() => setIsFormValid(true)}
      onFinish={submit}
      {...defaultFormLayout}
    >
      {props.campaign && user && user.roles.includes(UserRole.ADMIN) ? (
        <Row style={{ paddingBottom: '24px' }} gutter={5}>
          <Col style={{ textAlign: 'right' }} sm={defaultFormLayout.labelCol.sm} xs={defaultFormLayout.labelCol.xs}>
            Owner:
          </Col>
          <Col>
            <b>{props.campaign.id}</b>
          </Col>
        </Row>
      ) : null}

      <Form.Item {...defaultFormLayout} label="Campaign type" name="campaignType" required>
        <Radio.Group onChange={handleCampaignTypeChange} disabled={!!props.campaign}>
          <Radio.Button value={PUSH}>Push</Radio.Button>
          <Radio.Button value={LINK}>Link</Radio.Button>
          <Radio.Button value={VIDEO_PRE_ROLL}>Video</Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Input label="Title" name="title" isRequired />

      <InputWithMacroses label="URL" isRequired type="url" name="url" form={form} />

      <Form.Item
        name="tags"
        label={
          <LabelWithTooltip
            label="Tags"
            labelTooltip="The tags of a campaign are used solely for filtering campaigns on the reports page."
          />
        }
      >
        <Select
          mode="tags"
          allowClear
          options={campaignsTags.map(tag => ({
            label: tag,
            value: tag,
          }))}
          // span={3}
        />
      </Form.Item>

      <Divider />

      <MultiSelect
        title="Streams"
        decoratorTitle="streams"
        type="array"
        extra={<IncludeOrExclude name="streamsInclude" form={form} />}
        required
        filterFn={(input, option) => {
          const stream = streams?.body.items.find(s => s.uid === option.value)
          return (
            option.key.toLowerCase().includes(input.toLowerCase()) ||
            stream?.title.toLowerCase().includes(input.toLowerCase())
          )
        }}
      >
        {streams?.body.items
          ? streams.body.items
              .filter(s => s.streamType === campaignType)
              .map(stream => (
                <Option value={stream.uid} key={stream.id}>
                  {stream.title}{' '}
                  <span style={{ opacity: 0.6 }}>
                    ID: {stream.id}{' '}
                    {stream.hasClientHints ? (
                      <Tooltip title="Client Hits Enabled">
                        <UserSwitchOutlined
                          color="#00ff00"
                          style={{
                            color: 'green',
                            fontWeight: 'bold',
                          }}
                        />
                      </Tooltip>
                    ) : null}
                  </span>
                </Option>
              ))
          : null}
      </MultiSelect>

      <SubstreamsInput form={form} name="substreams" autoSize={{ minRows: 4, maxRows: 12 }} />

      <MultiSelect
        decoratorTitle={'whitelists'}
        title="Whitelists"
        type={'array'}
        filterFn={(input, option) => {
          const list = blackWhiteLists?.body.items.find(l => l.id === option.value)
          return (
            option.key.toLowerCase().includes(input.toLowerCase()) ||
            list?.title.toLowerCase().includes(input.toLowerCase())
          )
        }}
      >
        {blackWhiteLists?.body.items
          ? blackWhiteLists.body.items.map(list => (
              <Option value={list.id} key={list.id}>
                {list.title}{' '}
                <span style={{ opacity: 0.6 }}>
                  Count: {list.items.length}, ID:{list.id}
                </span>
              </Option>
            ))
          : null}
      </MultiSelect>

      <MultiSelect
        decoratorTitle={'blacklists'}
        title="Blacklists"
        type={'array'}
        filterFn={(input, option) => {
          const list = blackWhiteLists?.body.items.find(l => l.id === option.value)
          return (
            option.key.toLowerCase().includes(input.toLowerCase()) ||
            list?.title.toLowerCase().includes(input.toLowerCase())
          )
        }}
      >
        {blackWhiteLists?.body.items
          ? blackWhiteLists.body.items.map(list => (
              <Option value={list.id} key={list.id}>
                {list.title}{' '}
                <span style={{ opacity: 0.6 }}>
                  Count: {list.items.length}, ID:{list.id}
                </span>
              </Option>
            ))
          : null}
      </MultiSelect>

      <MultiSelect
        title="User Auditories"
        decoratorTitle="auditories"
        type="array"
        extra={<IncludeOrExclude name="auditoriesInclude" form={form} />}
      >
        {campaignSettingsValues.data?.body?.userAuditories.map(userAuditory => (
          <Option value={userAuditory.id} key={userAuditory.id}>
            {userAuditory.title} <span style={{ opacity: 0.6 }}>ID: {userAuditory.id}</span>
          </Option>
        ))}
      </MultiSelect>

      <Form.Item
        name="domains"
        label="Domains"
        hidden={campaignType === LINK}
        extra={<IncludeOrExclude name="domainsInclude" form={form} />}
      >
        <Input2.TextArea wrap="off" name="domains" autoSize={{ minRows: 4, maxRows: 12 }} />
      </Form.Item>

      <Form.Item
        name="userAgents"
        label="User-Agents"
        extra={<IncludeOrExclude name="userAgentsInclude" form={form} />}
      >
        <Input2.TextArea wrap="off" name="domains" autoSize={{ minRows: 4, maxRows: 12 }} />
      </Form.Item>

      <MultiSelect
        decoratorTitle={'countryCode'}
        title="Countries"
        type={'array'}
        extra={<IncludeOrExclude name="countryCodeInclude" form={form} />}
        onChange={onCountryChange}
      >
        {countries.map(country => (
          <Option value={country.value} key={country.value}>
            {country.name} / {country.value}
          </Option>
        ))}
      </MultiSelect>

      <MultiSelect
        decoratorTitle="region"
        title="Regions"
        type="array"
        extra={<IncludeOrExclude name="regionInclude" form={form} />}
        filterFn={(input, option) => {
          return option.key.toLowerCase().includes(input.toLowerCase())
        }}
        tooltip="Regions are available only when some countries selected"
        disabled={selectedCountries.length === 0}
      >
        {regionsArr
          ?.map(([country, regions]) => {
            return regions.map(region => {
              return (
                <Option value={region} key={`${country} ${region}`}>
                  {country.toLowerCase()} / {region}
                </Option>
              )
            })
          })
          .flat()}
      </MultiSelect>

      <MultiSelect
        decoratorTitle={'lang'}
        title="Languages"
        type={'array'}
        extra={<IncludeOrExclude name="langInclude" form={form} />}
        filterFn={(input, option) => {
          const value = option.key.split(':')[0]
          const name = option.key.split(':')[1]
          return value.toLowerCase().includes(input.toLowerCase()) || name.toLowerCase().includes(input.toLowerCase())
        }}
      >
        {getShortLangArray().map(lang => (
          <Option value={lang.value} key={`${lang.value}:${lang.name}`}>
            {lang.name} / {lang.value}
          </Option>
        ))}
      </MultiSelect>

      <MultiSelect
        decoratorTitle={'device'}
        title="Devices"
        type={'array'}
        extra={<IncludeOrExclude name="deviceInclude" form={form} />}
        options={targets.data?.device.map(({ id, label }) => ({ value: id, title: label, label }))}
      />

      <MultiSelect
        decoratorTitle={'os'}
        title="OS"
        type={'array'}
        extra={<IncludeOrExclude name="osInclude" form={form} />}
        options={targets.data?.os.map(({ id, label }) => ({ value: id, title: label, label }))}
      />

      <MultiSelect
        decoratorTitle={'osVersion'}
        title="OS Version"
        type={'array'}
        extra={<IncludeOrExclude name="osVersionInclude" form={form} />}
        options={targets.data?.os
          .filter(({ id }) => selectedOs.includes(id))
          .flatMap(os =>
            os.versions.map(version => ({
              value: version.id,
              title: `${os.label} ${version.label}`,
              label: `${os.label} ${version.label}`,
            }))
          )}
      />

      <MultiSelect
        decoratorTitle={'browser'}
        title="Browsers"
        type={'array'}
        extra={<IncludeOrExclude name="browserInclude" form={form} />}
        options={targets.data?.browser.map(({ id, label }) => ({ value: id, title: label, label }))}
      />

      <MultiSelect
        decoratorTitle={'browserVersion'}
        title="Browser Version"
        type={'array'}
        extra={<IncludeOrExclude name="browserVersionInclude" form={form} />}
        options={targets.data?.browser
          .filter(({ id }) => selectedBrowser.includes(id))
          .flatMap(browser =>
            browser.versions.map(version => ({
              value: version.id,
              title: `${browser.label} ${version.label}`,
              label: `${browser.label} ${version.label}`,
            }))
          )}
      />

      <MultiSelect
        decoratorTitle={'connectionType'}
        title="Connection Type"
        type={'array'}
        extra={<IncludeOrExclude name="connectionTypeInclude" form={form} />}
      >
        {CONNECTION_TYPES.map((type, i) => (
          <Option value={type} key={i}>
            {type}
          </Option>
        ))}
      </MultiSelect>

      <MultiSelect
        decoratorTitle={'isp'}
        title="ISP"
        type={'array'}
        extra={<IncludeOrExclude name="ispInclude" form={form} />}
      >
        {ISP_TYPES.map((ispType, i) => (
          <Option value={ispType} key={i}>
            {ispType}
          </Option>
        ))}
      </MultiSelect>

      <Form.Item
        label="Subscription period"
        hidden={campaignType !== CampaignType.PUSH}
        tooltip={'Enter integer values for subscription period. Floats will be rounded to nearest integer.'}
        help={
          subAgeUnits === 'days'
            ? `Will be converted as "From ${getSubscriptionAgeHoursString({
                subscriptionAgeFrom,
                subscriptionAgeTo,
                subscriptionAgeUnitsChoice: subAgeUnits,
              })} hours"`
            : undefined
        }
        style={{ marginBottom: 0 }}
      >
        <span
          style={{
            display: 'inline-block',
            margin: '0 8px',
            lineHeight: '32px',
          }}
        >
          From
        </span>
        <Input
          name="subscriptionAgeFrom"
          isNumber
          style={{ display: 'inline-block' }}
          min={0}
          step={1}
          precision={0}
          disabled={!subscriptionAgeEnabled}
        />
        <span>&nbsp;{subAgeUnits}</span>
        <span
          style={{
            display: 'inline-block',
            margin: '0 8px',
            lineHeight: '32px',
          }}
        >
          to
        </span>
        <Input
          name="subscriptionAgeTo"
          isNumber
          style={{ display: 'inline-block' }}
          min={0}
          step={1}
          precision={0}
          disabled={!subscriptionAgeEnabled}
        />
        <span>&nbsp;{subAgeUnits}</span>

        <Row gutter={24} style={{ marginBottom: '10px' }}>
          <Col span={12}>
            <Form.Item name="subscriptionAgeUnitsChoice" noStyle>
              <Radio.Group disabled={!subscriptionAgeEnabled}>
                <Radio.Button value="days">Days</Radio.Button>
                <Radio.Button value="hours">Hours</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="subscriptionAgeEnabled" valuePropName="checked" noStyle>
              <Switch checkedChildren="Enabled" unCheckedChildren="Disabled" />
            </Form.Item>
          </Col>
        </Row>
      </Form.Item>

      <Divider />

      <FormSubHeader title="User Overall Limits" layout={defaultFormLayoutOffset} />

      <Form.Item label={'Max shown per user'} style={{ marginBottom: 0 }}>
        <Input style={{ display: 'inline-block', marginRight: 8 }} name="maxShownUser" isNumber />
      </Form.Item>

      <Form.Item label={'Max clicked per user'} style={{ marginBottom: 0 }}>
        <Input style={{ display: 'inline-block', marginRight: 8 }} name="maxClickedUser" isNumber />
      </Form.Item>

      <FormSubHeader title="User Periodic Limits" layout={defaultFormLayoutOffset} />

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={'Max shown per user'} style={{ marginBottom: 0 }} labelCol={{ span: 12 }}>
            <Input style={{ display: 'inline-block' }} name="maxShownUserAmtPeriod" isNumber />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'maxShownUserPeriod'} label={'for last N days'} labelCol={{ span: 12 }}>
            <Select>
              {[0, 1, 2, 7].map(days => {
                const hours = days * 24
                return (
                  <Option value={hours} key={hours}>
                    {days === 0 ? '-' : `${days} days (${hours} hours)`}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label={'Max clicked per user'} style={{ marginBottom: 0 }} labelCol={{ span: 12 }}>
            <Input style={{ display: 'inline-block' }} name="maxClickedUserAmtPeriod" isNumber />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={'maxClickedUserPeriod'} label={'for last N days'} labelCol={{ span: 12 }}>
            <Select>
              {[0, 1, 2, 7].map(days => {
                const hours = days * 24
                return (
                  <Option value={hours} key={hours}>
                    {days === 0 ? '-' : `${days} days (${hours} hours)`}
                  </Option>
                )
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <FormSubHeader title={'Budget limits'} layout={defaultFormLayoutOffset} />

      <Form.Item label="Max daily budget" tooltip="Max USD spent for one day" style={{ marginBottom: 0 }}>
        <Input
          onChange={validateBudget}
          style={{ display: 'inline-block', marginRight: 8 }}
          name="maxDailyBudget"
          isNumber
        />
      </Form.Item>

      <Form.Item label="Max total budget" tooltip="Max USD spent for whole campaign" style={{ marginBottom: 0 }}>
        <Input
          onChange={validateBudget}
          style={{ display: 'inline-block', marginRight: 8 }}
          name="maxTotalBudget"
          isNumber
        />
      </Form.Item>

      {!isBudgetsLimitValid && (
        <Form.Item {...defaultFormLayoutOffset}>
          <Alert message="One of the budgets limits should be set" type="error" />
        </Form.Item>
      )}

      <Divider />

      <FormSubHeader title={'Auditories conditions'} layout={defaultFormLayoutOffset} />

      <Row gutter={24}>
        <Col span={8}>
          <Form.Item
            label="Clicks"
            tooltip="When user clicked N times his ID will be added to auditory"
            labelCol={{ span: 12 }}
            name={'maxClickedThreshold'}
          >
            <Input className="" name="maxClickedThreshold" isNumber />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item name="maxClickedAuditory">
            <Select style={{ width: '100%' }}>
              {campaignSettingsValues.data?.body.userAuditories.map(auditory => (
                <Option value={auditory.id} key={auditory.id}>
                  {auditory.title}
                </Option>
              ))}
              <Option value={0}>None</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {campaignType === PUSH && (
        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Impressions"
              tooltip="When campaign shown N times to user his ID will be added to auditory"
              labelCol={{ span: 12 }}
            >
              <Input name="maxShownThreshold" isNumber />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item name="maxShownAuditory">
              <Select style={{ width: '100%' }}>
                {campaignSettingsValues.data?.body.userAuditories.map(auditory => (
                  <Option value={auditory.id} key={auditory.id}>
                    {auditory.title}
                  </Option>
                ))}
                <Option value={0}>None</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      )}

      <FormSubHeader
        title={
          <>
            Time schedule <HelpPopover content="Messages will be sent every hour depend on this settings." />
          </>
        }
        layout={defaultFormLayoutOffset}
      />

      <TimezoneSelect title="Timezone" name="scheduleTimezone" isVertical={false} />

      <Form.Item label="Days And Hours" name="targetDaysAndHours">
        <TargetDaysAndHoursInput form={form} />
      </Form.Item>

      <Divider />

      <FormSubHeader title="Price" layout={defaultFormLayoutOffset} />

      <Form.Item {...defaultFormLayout} name="bidType" label="Bid Model">
        <Radio.Group>
          <Radio.Button value={CPC}>{CPC}</Radio.Button>
          <Radio.Button value={CPM} disabled={campaignType === PUSH}>
            {CPM}
          </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item label="Price" style={{ marginBottom: 0 }}>
        <Input isRequired style={{ display: 'inline-block', marginRight: 8 }} name="price" isNumber width={200} />
      </Form.Item>

      <Form.Item label={`Target CPA`} style={{ marginBottom: 0 }}>
        <Input style={{ display: 'inline-block', marginRight: 8 }} name="targetCpa" isNumber width={200} />
      </Form.Item>

      {!isFormValid && (
        <Form.Item {...defaultFormLayoutOffset}>
          <Alert message={formInvalidReason} type="error" />
        </Form.Item>
      )}

      <Form.Item {...defaultFormLayoutOffset}>
        <Button type="primary" htmlType="submit" loading={submitting}>
          Save
        </Button>
      </Form.Item>
    </Form>
  )
}

export default CampaignSettingsForm
