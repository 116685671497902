import React, { FC, useState } from 'react'
import { EyeOutlined } from '@ant-design/icons'
import { Button, Modal, Row, Tag, Tooltip, Typography } from 'antd'
import { useAppState } from '../../../context/AppContext'
import { TargetIdObject } from '@pushflow/backend-http-contract'

export const ListOfCamapgins: FC<{ campaignIds: number[]; titles?: { id: number; title: string }[] }> = ({
  campaignIds,
  titles,
}) => {
  return (
    <>
      <Row style={{ gap: '1rem' }}>
        {campaignIds.map(campaignId => {
          const c = titles?.find(t => t.id === campaignId)
          const title = c?.title || 'Unknown'

          return (
            <Tooltip title={title} key={campaignId}>
              <Tag>{campaignId}</Tag>
            </Tooltip>
          )
        })}
      </Row>
    </>
  )
}

export const ListOfStreams: FC<{ streamIds: { campaignId: number; streamId: number }[] }> = ({ streamIds }) => {
  return (
    <>
      <Tooltip title={`Campaign | Stream`}>
        <Row style={{ gap: '1rem' }}>
          {streamIds.map(stream => (
            <Tag key={`${stream.campaignId}:${stream.streamId}`}>
              {stream.campaignId} | {stream.streamId}
            </Tag>
          ))}
        </Row>
      </Tooltip>
    </>
  )
}

export const ListOfSubstreams: FC<{
  substreams: { campaignId: number; streamId: number; substream: string }[]
}> = ({ substreams }) => {
  return (
    <>
      <Tooltip title={`Campaign | Stream:Substream`}>
        <Row style={{ gap: '1rem' }}>
          {substreams.map(substream => {
            const k = `${substream.campaignId}:${substream.streamId}:${substream.substream}`
            return (
              <Tag key={k}>
                {substream.campaignId} | {substream.streamId}:{substream.substream}
              </Tag>
            )
          })}
        </Row>
      </Tooltip>
    </>
  )
}

const ListOfMatchedItems: FC<{ matched: TargetIdObject }> = ({ matched }) => {
  if (
    matched.campaignIds.length === 0 &&
    matched.streamIds &&
    matched.streamIds.length === 0 &&
    matched.substreams &&
    matched.substreams.length === 0
  ) {
    return <Tag color="orange">Nothing matches to current codition</Tag>
  }
  return (
    <>
      {matched.campaignIds.length > 0 && <ListOfCamapgins campaignIds={matched.campaignIds} />}
      {matched.streamIds && matched.streamIds.length > 0 && <ListOfStreams streamIds={matched.streamIds} />}
      {matched.substreams && matched.substreams.length > 0 && <ListOfSubstreams substreams={matched.substreams} />}
    </>
  )
}

export const MatchedTargetsModal: FC<{ ruleId: number }> = ({ ruleId }) => {
  const { apiClient } = useAppState()

  const { data: matched } = apiClient.rule.getTargetsMatchedToRule.useQuery(['rule-matched', { ruleId }], {
    query: { id: ruleId },
  })

  const [visible, setVisible] = useState(false)

  return (
    <>
      <Tooltip placement="left" title="Show matched targets">
        <Button icon={<EyeOutlined />} onClick={() => setVisible(!visible)}></Button>
      </Tooltip>
      <Modal open={visible} footer={null} onCancel={() => setVisible(false)}>
        <Typography.Title level={4}>Targets that match with current codition</Typography.Title>
        <ListOfMatchedItems
          matched={
            matched?.body || {
              campaignIds: [],
              streamIds: [],
              substreams: [],
            }
          }
        />
      </Modal>
    </>
  )
}

export const MatchedEntriesView: FC<{
  matched: { substream?: string; streamId?: number; campaignId: number }[]
}> = ({ matched }) => {
  const subs = [] as any[]
  const others = [] as any[]
  matched.forEach(m => {
    if ('substream' in m && 'streamId' in m && 'campaignId' in m) {
      subs.push(m)
    } else {
      others.push(m)
    }
  })
  const streams = [] as any[]
  const campaigns = [] as any[]
  others.forEach(m => {
    if ('streamId' in m && 'campaignId' in m) {
      streams.push(m)
    } else if ('campaignId' in m) {
      campaigns.push(m)
    }
  })

  return (
    <>
      {campaigns.length > 0 && <ListOfCamapgins campaignIds={campaigns.map(c => c.campaignId)} />}
      {streams.length > 0 && (
        <ListOfStreams
          streamIds={(streams as any).map((s: any) => ({ campaignId: s.campaignId, streamId: s.streamId }))}
        />
      )}
      {subs.length > 0 && (
        <ListOfSubstreams
          substreams={(subs as any).map((s: any) => ({
            campaignId: s.campaignId,
            streamId: s.streamId,
            substream: s.substream,
          }))}
        />
      )}
      {campaigns.length === 0 && streams.length === 0 && subs.length === 0 && (
        <Tag color="yellow">Nothing processed</Tag>
      )}
    </>
  )
}
