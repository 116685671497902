import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  createCampaignRule,
  deleteCampaignRule,
  fetchCampaignDebugLink,
  fetchCampaignRules,
  fetchRule,
  fetchStreamInfo,
  fetchSubstreamInfo,
  runRuleAgain,
  updateCampaignRule,
  fetchTargets,
  createTemplateRule,
  fetchRuleTemplateList,
  createRuleFromTemplate,
  duplicateCampaign,
  fetchCampaignTags,
  copyRule,
} from './fetchers'
import { CampaignRuleWithData } from '../../../types/campaign-rule'

export const useCampaignDebugLink = (campaignId: number) => {
  return useQuery({
    queryKey: ['campaign', campaignId, 'debugLink'],
    queryFn: () => fetchCampaignDebugLink(campaignId),
  })
}

export const useCampaignRules = (campaignId: number) => {
  return useQuery({
    queryKey: ['campaign', 'rules', campaignId],
    queryFn: async () => fetchCampaignRules(campaignId),
  })
}

export const useRule = (params: { campaignId: number; ruleId: number | null }) => {
  const { campaignId, ruleId } = params
  return useQuery({
    queryKey: ['campaign', 'rules', campaignId, ruleId],
    queryFn: () => fetchRule({ campaignId, ruleId }),
  })
}

export const useCreateCampaignRuleMutation = (campaignId: number) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createCampaignRule,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaign', 'rules', campaignId] })
    },
  })
}

export const useUpdateCampaignRuleMutation = (params: { campaignId: number; rule: CampaignRuleWithData | null }) => {
  const { campaignId, rule } = params
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: updateCampaignRule,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaign', 'rules', campaignId] })
      queryClient.invalidateQueries({ queryKey: ['campaign', 'rules', campaignId, rule?.id] })
    },
  })
}

export const useDeleteCampaignRuleMutation = (params: { campaignId: number; ruleId: number | null }) => {
  const { campaignId, ruleId } = params
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: deleteCampaignRule,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaign', 'rules', campaignId, ruleId] })
    },
  })
}

export const useRunRuleAgainMutation = (params: { campaignId: number; ruleId: number | null }) => {
  const { campaignId, ruleId } = params
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: runRuleAgain,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaign', 'rules', campaignId, ruleId] })
    },
  })
}

export const useCreateTemplateCampaignRule = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createTemplateRule,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaign', 'rules'] })
    },
  })
}

export const useRuleTemplateList = () => {
  return useQuery({
    queryKey: ['campaign', 'ruleTemplates'],
    queryFn: fetchRuleTemplateList,
  })
}

export const useCreateRuleFromTemplate = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: createRuleFromTemplate,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaign', 'rules'] })
    },
  })
}

export const useCopyRuleMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: copyRule,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaign', 'rules'] })
    },
  })
}

export const useStreamInfo = (params: { campaignId: number; streamId: number | null }) => {
  const { campaignId, streamId } = params
  return useQuery({
    queryKey: ['campaign', 'streamInfo', campaignId, streamId],
    queryFn: () => fetchStreamInfo({ campaignId, streamId }),
    enabled: !!streamId,
  })
}

export const useSubstreamInfo = (params: {
  campaignId: number
  streamId: number | null
  substreamId: string | null
}) => {
  const { campaignId, streamId, substreamId } = params
  return useQuery({
    queryKey: ['campaign', 'substreamInfo', campaignId, streamId, substreamId],
    queryFn: () => fetchSubstreamInfo({ campaignId, streamId, substreamId }),
    enabled: !!substreamId && !!streamId,
  })
}

export const useFetchTargets = () => {
  return useQuery({
    queryKey: ['campaign', 'targets'],
    queryFn: () => fetchTargets(),
  })
}

export const useDuplicateCampaignMutation = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: duplicateCampaign,
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['campaigns'] })
    },
  })
}

export const useCampaignTags = () => {
  return useQuery({
    queryKey: ['campaign', 'tags'],
    queryFn: () => fetchCampaignTags(),
    initialData: [],
  })
}
