import {
  ArrowRightOutlined,
  BarChartOutlined,
  CodeOutlined,
  DashboardOutlined,
  FilterOutlined,
  HistoryOutlined,
  LinkOutlined,
  LoginOutlined,
  PictureOutlined,
  PieChartOutlined,
  SendOutlined,
  SettingOutlined,
  UserAddOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  canManageCampaigns,
  canManageCreatives,
  canManageStreams,
  isAdmin,
} from '@pushflow/server/src/helpers/featureFlags'
import { Menu } from 'antd'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import { useAppState } from '../context/AppContext'
import { ImpersonateButton } from './ImpersonateButton'

const menuStyle = { paddingBottom: '50px', lineHeight: '64px', backgroundColor: 'transparent' }
const mainMenuItem = { ...menuStyle, fontSize: 16 }

const MenuItemGroupTitle = ({ title }) => (
  <div
    style={{
      color: 'rgba(0, 0, 0, 0.30)',
      marginLeft: 6,
    }}
  >
    {title}
  </div>
)

const SidebarMenu = () => {
  const { user } = useAppState()
  const { t } = useTranslation()
  const location = useLocation()

  if (!user) {
    return (
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
        style={mainMenuItem}
      >
        <Menu.Item icon={<UserAddOutlined />} key="/signup">
          <Link to="/signup">{t('AppLayout.Menu.Sign up')}</Link>
        </Menu.Item>
        <Menu.Item icon={<LoginOutlined />} key="/login">
          <Link to="/login">{t('AppLayout.Menu.Login')}</Link>
        </Menu.Item>
      </Menu>
    )
  }
  const isActive = () => {
    if (location.pathname.startsWith('/campaign')) return ['/campaign']
    if (location.pathname.startsWith('/report')) return ['/report']
    if (location.pathname.startsWith('/feed')) return ['/feed']
    if (location.pathname.startsWith('/subscriptionslist')) return ['/subscriptionslist']
    return [location.pathname]
  }
  return (
    <div>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={isActive()}
        style={mainMenuItem}
      >
        <Menu.ItemGroup key="g1" title={<MenuItemGroupTitle title="Statistics" />} style={{ marginBottom: 24 }}>
          <Menu.Item key="/" icon={<DashboardOutlined />}>
            <Link to="/">{t('AppLayout.Menu.Dashboard')}</Link>
          </Menu.Item>
          <Menu.Item key="/report" icon={<BarChartOutlined />}>
            <Link to="/report">{t('AppLayout.Menu.Reports')}</Link>
          </Menu.Item>
          {canManageCampaigns(user) && (
            <Menu.Item key="/invetory" icon={<PieChartOutlined />}>
              <Link to="/invetory">Inventory</Link>
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        <Menu.ItemGroup key="g2" title={<MenuItemGroupTitle title="Workflow" />} style={{ marginBottom: 24 }}>
          {canManageCampaigns(user) && (
            <Menu.Item key="/campaign" icon={<SendOutlined />}>
              <Link to="/campaign">{t('AppLayout.Menu.Campaigns')}</Link>
            </Menu.Item>
          )}

          {canManageCreatives(user) && (
            <Menu.Item key="/creatives" icon={<PictureOutlined />}>
              <Link to="/creatives">{t('AppLayout.Menu.Creatives')}</Link>
            </Menu.Item>
          )}
          {canManageCampaigns(user) && (
            <Menu.Item key="/userauditories" icon={<UserOutlined />}>
              <Link to="/userauditories">{t('AppLayout.Menu.UserAuditories')}</Link>
            </Menu.Item>
          )}

          {canManageCampaigns(user) && (
            <Menu.Item key="/subscriptionlist" icon={<SendOutlined />}>
              <Link to="/subscriptionlist">{t('AppLayout.Menu.SubscriptionsList')}</Link>
            </Menu.Item>
          )}

          {canManageCampaigns(user) && (
            <Menu.Item key="/automations" icon={<CodeOutlined />}>
              <Link to="/automations">{'Automations'}</Link>
            </Menu.Item>
          )}

          {canManageStreams(user) && (
            <Menu.Item key="/streams" icon={<FilterOutlined />}>
              <Link to="/streams">{t('AppLayout.Menu.Streams')}</Link>
            </Menu.Item>
          )}
        </Menu.ItemGroup>

        {isAdmin(user) && (
          <Menu.ItemGroup key="admin" title={<MenuItemGroupTitle title="Admin" />} style={{ marginBottom: 24 }}>
            <Menu.Item key="/admin/creative-moderation" icon={<PictureOutlined />}>
              <Link to="/admin/creative-moderation">Creative moderation</Link>
            </Menu.Item>

            <Menu.Item key="/admin/users" icon={<UserOutlined />}>
              <Link to="/admin/users">Users</Link>
            </Menu.Item>

            <Menu.Item key="/admin/domains" icon={<LinkOutlined />}>
              <Link to="/admin/domains">Domains</Link>
            </Menu.Item>

            <Menu.Item key="/admin/targets" icon={<ArrowRightOutlined />}>
              <Link to="/admin/targets">Targets</Link>
            </Menu.Item>

            <Menu.Item key="/admin/schedule-task" icon={<HistoryOutlined />}>
              <Link to="/admin/schedule-task">Schedule tasks</Link>
            </Menu.Item>
          </Menu.ItemGroup>
        )}
      </Menu>
      <Menu
        theme="light"
        mode="inline"
        defaultSelectedKeys={[location.pathname]}
        selectedKeys={[location.pathname]}
        style={menuStyle}
      >
        <Menu.ItemGroup title={`${user.email} (ID:${user.id})` || 'More'}>
          <Menu.Item key="/options" icon={<SettingOutlined />}>
            <Link to="/options">{t('AppLayout.Menu.Options')}</Link>
          </Menu.Item>
          <Menu.Item key="/logout" icon={<LoginOutlined />}>
            <Link to="/logout">{t('AppLayout.Menu.Logout')}</Link>
          </Menu.Item>
          <Menu.Item key="/impersonate">
            <ImpersonateButton />
          </Menu.Item>
        </Menu.ItemGroup>
      </Menu>
    </div>
  )
}

export default SidebarMenu
