import {
  ResultActionValues,
  ResultAction,
  ResultActionTargetId,
  RuleTarget,
  ResultActionTargetForChangeBid,
} from '@pushflow/backend-http-contract'
import { Col, Input, Row, Select } from 'antd'
import React, { FC } from 'react'
import { resultActionNameMap, ruleTargetNameMap } from './action-overview'

export const getListType = (rt: RuleTarget) => {
  let listType: undefined | 'STREAM' | 'SUBSTREAM' = undefined
  if (rt === 'campaign_stream') {
    listType = 'STREAM'
  } else if (rt === 'campaign_stream_substream') {
    listType = 'SUBSTREAM'
  }

  return listType
}

export const ResultActionForm: FC<{
  ruleTarget: RuleTarget
  resultAction: ResultAction
  onResultAction: (resultAction: ResultAction) => void
  resultActionTargetId: ResultActionTargetId
  onResultActionTargetId: (resultActionTargetId: ResultActionTargetId) => void
  blackWhiteLists: { id: number; title: string }[]
}> = ({ ruleTarget, resultAction, onResultAction, resultActionTargetId, onResultActionTargetId, blackWhiteLists }) => {
  const amount = resultActionTargetId?.changeBidObj?.amount || 0
  const limit = resultActionTargetId?.changeBidObj?.limit || 0
  const listId = resultActionTargetId?.listId || blackWhiteLists[0]?.id || 0

  return (
    <Row align={'middle'} style={{ gap: '1rem' }}>
      <Select
        placeholder="Select action"
        style={{ width: 200 }}
        value={resultAction}
        onChange={(v: any) => {
          onResultAction(v)
        }}
        options={(ResultActionValues || [])
          .filter(a => {
            if (ruleTarget === 'campaign') {
              return a === 'stop'
            }
            return true
          })
          .map((v: ResultAction) => ({
            label: resultActionNameMap[v],
            value: v,
          }))}
      />
      {(resultAction === 'increase_bid' || resultAction === 'decrease_bid') && (
        <Row>
          <Col>
            for{' '}
            <Input
              style={{ width: '70px' }}
              type="number"
              value={amount}
              onChange={v => {
                onResultActionTargetId(ResultActionTargetForChangeBid(Number(v.target.value), limit))
              }}
              min={0}
              max={100}
            />
            % of {ruleTargetNameMap[ruleTarget].toLowerCase()} price.
          </Col>
          &nbsp;
          <Col>
            Limit&nbsp;to&nbsp;
            <Input
              style={{ width: '70px' }}
              type="number"
              value={limit}
              onChange={v => {
                onResultActionTargetId(ResultActionTargetForChangeBid(amount, Number(v.target.value)))
              }}
              min={0}
              max={200}
            />
            % of campaign price.
          </Col>
        </Row>
      )}
      {resultAction === 'add_to_list' && (
        <>
          to list{' '}
          <Select
            placeholder="Select list"
            style={{ width: 200 }}
            value={listId}
            onChange={(v: any) => {
              onResultActionTargetId({ changeBidObj: null, listId: v })
            }}
            options={blackWhiteLists.map(bwl => ({
              label: `ID: ${bwl.id} | ${bwl.title}`,
              value: bwl.id,
            }))}
            filterOption={(str, o) => {
              const { label } = o || { value: '', label: '' }
              return label.toLowerCase().includes(str.toLowerCase()) && label !== ''
            }}
          />
        </>
      )}
    </Row>
  )
}
