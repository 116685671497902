import { PageHeader } from 'antd'
import React from 'react'
import { Link } from 'react-router-dom'
import Title from '../Title/Title'
import './PageListHeader.sass'
import Button from '../../buttons/Button/Button'

const buttonStyle = { minWidth: 120 }

const PageListHeader = ({ title, buttons = [], buttonSize = 'large', level = 2, alerts = [], ...restProps }) => {
  function renderButtons() {
    if (!buttons) return

    return buttons.map((button, key) => {
      if (button.link) {
        return (
          <Link to={button.link} key={key}>
            <Button type={button.type} icon={button.icon} size={buttonSize} style={buttonStyle}>
              {button.title}
            </Button>
          </Link>
        )
      } else {
        return (
          <Button
            key={key}
            type={button.type}
            icon={button.icon}
            size="large"
            onClick={button.onClick}
            style={buttonStyle}
          >
            {button.title}
          </Button>
        )
      }
    })
  }

  return (
    <PageHeader
      title={<Title level={level}>{title}</Title>}
      extra={[alerts, renderButtons()]}
      className="PageListHeader"
      {...restProps}
    />
  )
}

export default PageListHeader
