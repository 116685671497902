import {
  ConditionSet,
  RuleTarget,
  ResultAction,
  ResultActionTargetId,
  FrequencyType,
  ResultActionTargetForChangeBid,
  ResultActionTargetForAddToList,
  FullRule,
  Rule,
} from '@pushflow/backend-http-contract'
import { Button, Col, Divider, Form, Input, Row, Select, Typography } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useAppState } from '../../../context/AppContext'
import showNotification from '../../../helpers/showNotification'
import { ActionOverview } from './action-overview'
import { ConditionSetOverview } from './condition-set-overview'
import { ConditionSetForm } from './condition-set.form'
import { getListType, ResultActionForm } from './result-action.form'
import { useQueryClient } from '@tanstack/react-query'
import { getRuleError } from '../utils/validate-rule'
import { FrequencyForm } from './frequency.form'

export const getFrequency = (frequency: string | number) => {
  if (typeof frequency === 'number') {
    return `${frequency}`
  }
  if (frequency.includes(':')) {
    return `${frequency.split(':')[0]}:00`
  }
  return frequency
}

export const RuleEdit: FC<{ rule: FullRule }> = ({ rule }) => {
  const { apiClient } = useAppState()
  const queryClient = useQueryClient()

  const { mutateAsync: updateRule } = apiClient.rule.updateRule.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['rule'] })
      await queryClient.invalidateQueries({ queryKey: ['rules'] })
      await queryClient.invalidateQueries({ queryKey: ['rule-matched'] })
    },
  })
  const { data: campaigns } = apiClient.campaign.getCampaignsAutocomplete.useQuery(['campaigns-autocomplete'], {
    query: {},
  })

  const [showConditionEditor, setShowConditionEditor] = useState(false)
  const [showActionEditor, setShowActionEditor] = useState(false)
  const [checkCamapigns, setCheckCampaigns] = useState<'all' | 'selected'>(
    rule.targetId && rule.targetId.campaignIds.length > 0 ? 'selected' : 'all'
  )

  const [title, setTitle] = useState(rule.title)
  const [conditions, setConditions] = useState<ConditionSet>(rule.condition)
  const [ruleTarget, setRuleTarget] = useState<RuleTarget>(rule.ruleTarget)
  const [analysisPeriod, setAnalysisPeriod] = useState(rule.analysisPeriod)
  const [resultAction, setResultAction] = useState<ResultAction>(rule.resultAction)
  const [resultActionTarget, setResultActionTarget] = useState<ResultActionTargetId>(rule.resultActionTarget)
  const [frequencyType, setFrequencyType] = useState<FrequencyType>(rule.frequencyType)
  const [frequency, setFrequency] = useState<number>(rule.frequency)

  const [campaignIds, setCampaignIds] = useState<number[]>(
    rule.targetId && rule.targetId.campaignIds.length > 0 ? rule.targetId.campaignIds : []
  )

  const { data: blackWhiteLists, refetch: refetchBlWls } = apiClient.blackWhiteList.getListListPaginated.useQuery(
    ['blackWhiteLists'],
    {
      query: { filter: { listType: getListType(ruleTarget) }, limit: 1000, offset: 0 },
      overrideClientOptions: {
        jsonQuery: false,
      },
    }
  )

  useEffect(() => {
    refetchBlWls()
  }, [ruleTarget])

  useEffect(() => {
    if (checkCamapigns === 'all') {
      setCampaignIds([])
    }
    if (checkCamapigns === 'selected') {
      setCampaignIds(rule.targetId?.campaignIds || [])
    }
  }, [checkCamapigns])

  const handleSubmit = async () => {
    const newRule: Omit<Rule, 'status'> = {
      title,
      condition: conditions,
      ruleTarget,
      targetId: {
        campaignIds,
        streamIds: null,
        substreams: null,
      },
      resultAction,
      resultActionTarget,
      analysisPeriod,
      frequencyType,
      frequency,
    }
    const err = getRuleError(newRule, checkCamapigns, campaignIds)
    if (err) {
      showNotification({ type: err.type, message: err.message })
      return
    }

    try {
      const res = await updateRule({
        body: { id: rule.id, ...newRule },
      })
      showNotification({ type: 'success', message: `Rule ${res.body.id} updated` })
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Form.Item label="Rule name">
        <Input
          style={{ width: '50%' }}
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Rule name"
        />
      </Form.Item>
      <Col>
        <Row align={'middle'} style={{ gap: '10px' }}>
          <Col>For </Col>
          <Col span={4}>
            <Select
              style={{ width: '100%' }}
              value={checkCamapigns}
              options={[
                { label: 'all campaigns', value: 'all' },
                { label: 'selected campaigns', value: 'selected' },
              ]}
              onChange={(v: any) => setCheckCampaigns(v)}
            />
          </Col>
          {checkCamapigns === 'selected' && (
            <Col span={18}>
              <Select
                placeholder="Select campaigns"
                style={{ width: '100%' }}
                mode="multiple"
                value={campaignIds}
                maxTagCount={4}
                options={campaigns?.body.campaigns.map(c => {
                  return { label: `ID: ${c.id} | ${c.title}`, value: c.id }
                })}
                onChange={(v: number[]) => setCampaignIds(v)}
              />
            </Col>
          )}
          <br />
          <Col span={4}>check stats of </Col>
          <Col>
            <Select
              labelInValue={true}
              value={ruleTarget}
              options={[
                { label: 'campaigns', value: 'campaign' },
                { label: 'streams of campaign', value: 'campaign_stream' },
                { label: 'substreams of campaign', value: 'campaign_stream_substream' },
              ]}
              onChange={(v: any) => {
                setRuleTarget(v.value)
                setResultAction('stop')
                setResultActionTarget(null)
              }}
            />
          </Col>
          <Col>for last</Col>
          <Col span={2}>
            <Input
              placeholder="Enter days (integer 1-7)"
              value={analysisPeriod}
              onChange={e => {
                const value = parseInt(e.target.value)
                if (!isNaN(value)) {
                  if (value >= 1 && value <= 7) {
                    setAnalysisPeriod(value)
                    return
                  }
                  if (value < 1) {
                    setAnalysisPeriod(1)
                    return
                  }
                  if (value > 7) {
                    setAnalysisPeriod(7)
                    return
                  }
                }
                setAnalysisPeriod(1)
              }}
              min={1}
              max={7}
              style={{ width: '100%' }}
              type="number"
            />
          </Col>
          <Col>days</Col>
        </Row>
      </Col>
      <Divider />
      <Typography.Title level={5}>IF</Typography.Title>
      <ConditionSetOverview
        ruleTarget={ruleTarget}
        conditions={conditions}
        isTop={true}
        analysisPeriod={analysisPeriod}
      />
      <Button
        size="small"
        onClick={() => {
          setShowConditionEditor(!showConditionEditor)
        }}
      >
        {showConditionEditor ? 'Hide' : 'Show'} editor
      </Button>
      {showConditionEditor && (
        <ConditionSetForm condition={conditions} onConditionSetChange={cs => setConditions(cs)} />
      )}
      <Divider />
      <Typography.Title level={5}>THEN</Typography.Title>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <ActionOverview
          ruleTarget={ruleTarget}
          resultAction={resultAction}
          resultActionTarget={resultActionTarget}
          onClick={() => setShowActionEditor(!showActionEditor)}
        />
      </div>
      <Button
        size="small"
        onClick={() => {
          setShowActionEditor(!showActionEditor)
        }}
      >
        {showActionEditor ? 'Hide' : 'Show'} editor
      </Button>
      {showActionEditor && (
        <ResultActionForm
          ruleTarget={ruleTarget}
          resultAction={resultAction}
          blackWhiteLists={blackWhiteLists?.body.items || []}
          onResultAction={ra => {
            setResultAction(ra)
            if (ra === 'stop') {
              setResultActionTarget(null)
            }
            if (ra === 'increase_bid' || ra === 'decrease_bid') {
              const change = 10
              const limit = ra === 'increase_bid' ? 110 : 40
              setResultActionTarget(ResultActionTargetForChangeBid(change, limit))
            }
            if (ra === 'add_to_list') {
              if (!blackWhiteLists || blackWhiteLists?.body.items.length === 0) {
                showNotification({ type: 'warning', message: 'No black/white lists available. Please, create one' })
                return
              }
              setResultActionTarget(ResultActionTargetForAddToList(blackWhiteLists?.body.items[0].id || 0))
            }
          }}
          resultActionTargetId={resultActionTarget}
          onResultActionTargetId={rat => setResultActionTarget(rat)}
        />
      )}
      <Divider />
      <Typography.Title level={5}>Repeat</Typography.Title>
      <FrequencyForm
        frequency={frequency}
        frequencyType={frequencyType}
        onChange={(ft, f) => {
          setFrequencyType(ft)
          setFrequency(f)
        }}
      />
      <Divider />
      <Button type="primary" onClick={handleSubmit}>
        Save rule
      </Button>
    </>
  )
}
