import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { paginationRequest, paginationResponse } from './common/pagination';
import { ErrorCode } from './common/error.enum';
import {
  CommonResponse,
  FiveHundreedResponseSchema,
} from './common/common-response';

const c = initContract();

export const ConditionTargetValues = [
  'clicks',
  'ecpm',
  'conversion',
  'roi',
  'cost',
] as const;
export const ConditionTargetEnum = z.enum(ConditionTargetValues);

export type ConditionTarget = z.infer<typeof ConditionTargetEnum>;

export const ConditionSchema = z.object({
  conditionTarget: ConditionTargetEnum,
  operator: z.enum(['<', '>']),
  value: z.coerce.number(),
});

export type Condition = z.infer<typeof ConditionSchema>;

// Zod lacks support for recursive types, so we have to use union to define recursive types
// this approach also limits the depth of the recursion for rules
export const ConditionSetBaseSchema = z.object({
  combinator: z.enum(['and', 'or']),
  conditions: z.array(ConditionSchema),
});
// In total we have 2 levels of nesting: { and: [RuleSchema | { or: [RuleSchema] } ] }
export const ConditionSetSchema = z.object({
  combinator: z.enum(['and', 'or']),
  conditions: z.array(z.union([ConditionSchema, ConditionSetBaseSchema])),
});

export type ConditionSet = z.infer<typeof ConditionSetSchema>;

export const ResultActionValues = [
  'stop',
  'increase_bid',
  'decrease_bid',
  'add_to_list',
] as const;

export const ResultActionEnum = z.enum(ResultActionValues);

export type ResultAction = z.infer<typeof ResultActionEnum>;

export const ResultActionTargetIdSchema = z
  .object({
    changeBidObj: z
      .object({
        amount: z.number().gte(0).lte(100), // amount to change in percentage, change price for this amount. you can not change more that x2 from price
        limit: z.number().gte(40).lte(200), // percentage from campaign bid, limit for price changes not more than 200%
      })
      .nullable(),
    listId: z.number().nullable(),
  })
  .nullable();

export const ResultActionTargetForChangeBid = (
  amount: number,
  limit: number,
) => {
  return {
    changeBidObj: {
      amount,
      limit,
    },
    listId: null,
  };
};
export const ResultActionTargetForAddToList = (listId: number) => {
  return {
    changeBidObj: null,
    listId,
  };
};

export type ResultActionTargetId = z.infer<typeof ResultActionTargetIdSchema>;

export const RuleTargetEnum = z.enum([
  'campaign',
  'campaign_stream',
  'campaign_stream_substream',
]);

export type RuleTarget = z.infer<typeof RuleTargetEnum>;

export const FrequencyTypeEnum = z.enum(['every', 'at']).default('every');

export type FrequencyType = z.infer<typeof FrequencyTypeEnum>;

const TargetIdObjectSchema = z.object({
  campaignIds: z.number().array(),
  streamIds: z
    .object({
      streamId: z.number(),
      campaignId: z.number(),
    })
    .array()
    .nullable(),
  substreams: z
    .object({
      streamId: z.number(),
      campaignId: z.number(),
      substream: z.string(),
    })
    .array()
    .nullable(),
});
export type TargetIdObject = z.infer<typeof TargetIdObjectSchema>;

export const RuleStatusSchema = z.enum(['WORKING', 'PAUSED']);

export type RuleStatus = z.infer<typeof RuleStatusSchema>;

export const RuleSchema = z.object({
  title: z.string(),
  status: RuleStatusSchema,
  condition: ConditionSetSchema,
  ruleTarget: RuleTargetEnum,
  targetId: TargetIdObjectSchema.nullable(),
  resultAction: ResultActionEnum,
  resultActionTarget: ResultActionTargetIdSchema.nullable(),
  analysisPeriod: z.number(),
  frequencyType: FrequencyTypeEnum,
  frequency: z.number(),
});

export type Rule = z.infer<typeof RuleSchema>;

const FullRuleSchema = RuleSchema.merge(
  z.object({
    id: z.number(),
    userId: z.number(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime().nullable(),
  }),
);

export type FullRule = z.infer<typeof FullRuleSchema>;

export const rule = c.router({
  createRule: {
    method: 'POST',
    path: '/rule/createRule',
    body: RuleSchema,
    responses: {
      200: FullRuleSchema,
      ...CommonResponse([ErrorCode.RULE_NAME_ALREADY_EXISTS]),
    },
  },

  getRule: {
    method: 'GET',
    path: '/rule/getRule',
    query: z.object({
      id: z.number(),
    }),
    responses: {
      200: FullRuleSchema,
      ...CommonResponse([ErrorCode.RULE_NOT_FOUND]),
    },
  },

  getRules: {
    method: 'GET',
    path: '/rule/getRules',
    query: z.object({
      filters: z
        .object({
          title: z.string().optional(),
        })
        .optional(),
      pagination: paginationRequest,
    }),
    responses: {
      200: z.object({
        items: z.array(FullRuleSchema),
        pagination: paginationResponse,
      }),
      500: FiveHundreedResponseSchema,
    },
  },

  updateRule: {
    method: 'POST',
    path: '/rule/updateRule',
    body: RuleSchema.partial().merge(
      z.object({
        id: z.number(),
      }),
    ),
    responses: {
      ...CommonResponse([
        ErrorCode.RULE_NOT_FOUND,
        ErrorCode.RULE_NAME_ALREADY_EXISTS,
      ]),
      200: FullRuleSchema,
    },
  },

  deleteRule: {
    method: 'DELETE',
    path: '/rule/deleteRule',
    body: z.object({
      id: z.number(),
    }),
    responses: {
      ...CommonResponse([ErrorCode.RULE_NOT_FOUND]),
      200: z.object({ ok: z.boolean() }),
    },
  },

  copyRule: {
    method: 'POST',
    path: '/rule/copyRule',
    body: z.object({
      id: z.number(),
    }),
    responses: {
      ...CommonResponse([ErrorCode.RULE_NOT_FOUND]),
      200: z.object({ id: z.number() }),
    },
  },

  changeRuleStatus: {
    method: 'POST',
    path: '/rule/changeRuleStatus',
    body: z.object({
      id: z.number(),
      status: RuleStatusSchema,
    }),
    responses: {
      ...CommonResponse([ErrorCode.RULE_NOT_FOUND]),
      200: z.object({ ok: z.boolean() }),
    },
  },

  getTargetsMatchedToRule: {
    method: 'GET',
    path: '/rule/getTargetsMatchedToRule',
    query: z.object({
      id: z.coerce.number(),
    }),
    responses: {
      ...CommonResponse([ErrorCode.RULE_NOT_FOUND]),
      200: TargetIdObjectSchema,
    },
  },
});
