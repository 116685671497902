import { Condition, ConditionSet, ConditionTarget } from '@pushflow/backend-http-contract'
import { RuleGroupType, RuleType } from 'react-querybuilder'

export const conditionSetToQuery = (c: ConditionSet): RuleGroupType => {
  return {
    combinator: c.combinator,
    rules: c.conditions.map(r => {
      if ('conditions' in r) {
        return conditionSetToQuery(r)
      }
      return {
        field: r.conditionTarget,
        operator: r.operator,
        value: r.value,
      }
    }),
  }
}

export const queryToConditionSet = (q: RuleGroupType): ConditionSet => {
  const processCondition = (condition: RuleType | RuleGroupType): Condition | ConditionSet => {
    if ('rules' in condition) {
      return {
        combinator: condition.combinator as 'and' | 'or',
        conditions: condition.rules.map(r => processCondition(r)) as ConditionSet['conditions'],
      }
    }

    return {
      conditionTarget: condition.field as ConditionTarget,
      operator: condition.operator as '<' | '>',
      value: condition.value as number,
    }
  }

  return {
    combinator: q.combinator as 'and' | 'or',
    conditions: q.rules.map(r => processCondition(r)) as ConditionSet['conditions'],
  }
}
