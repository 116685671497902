import React, { FC } from 'react'
import { RuleEdit } from './components/rule-edit'
import { useParams } from 'react-router-dom'
import { useAppState } from '../../context/AppContext'
import { Divider, PageHeader } from 'antd'
import { RuleLogView } from './components/rule-log'
import { MatchedTargetsModal } from './components/matched-targets'

export const RuleEditPage: FC = () => {
  const params = useParams()
  const { apiClient } = useAppState()

  const { data } = apiClient.rule.getRule.useQuery(['rule', { id: +params.id! }], {
    query: {
      id: +params.id!,
    },
  })

  return (
    <>
      <PageHeader
        title={`Edit rule ${data?.body.id}`}
        extra={[<div key="matched-targets-modal">{data?.body && <MatchedTargetsModal ruleId={data?.body.id} />}</div>]}
      />
      {data?.body && <RuleEdit rule={data.body} />}
      <Divider />
      <PageHeader title="Rule logs" />
      <RuleLogView ruleIds={params.id && 'id' in params ? [+params.id] : []} pageSize={50} />
    </>
  )
}
