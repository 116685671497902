import React, { FC } from 'react'
import { useAppState } from '../../../context/AppContext'
import { Popconfirm, Row, Table, Tag, Tooltip } from 'antd'
import { FullRule } from '@pushflow/backend-http-contract'
import { Link } from 'react-router-dom'
import { CopyOutlined, DeleteOutlined, PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import showNotification from '../../../helpers/showNotification'
import { useQueryClient } from '@tanstack/react-query'
import { ResultActionView } from './action-overview'
import { ConditionSetOverview } from './condition-set-overview'
import { RepeatView } from './repeat-view'
import { ListOfCamapgins } from './matched-targets'

export const RuleList: FC = () => {
  const { apiClient } = useAppState()
  const [pageNumber, setPageNumber] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(100)

  const { data } = apiClient.rule.getRules.useQuery(['rules-list'], {
    query: {
      pagination: {
        pageNumber,
        pageSize,
      },
    },
  })

  const { data: campaigns } = apiClient.campaign.getCampaignsAutocomplete.useQuery(['campaigns-autocomplete'], {
    query: {},
  })

  return (
    <>
      <Table<FullRule>
        dataSource={data?.body.items || []}
        rowKey={row => row.id.toString()}
        pagination={{
          current: pageNumber,
          pageSize,
          total: data?.body.pagination.total || 0,
          onChange: setPageNumber,
          onShowSizeChange: setPageSize,
        }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'id',
          },
          {
            title: 'Title',
            dataIndex: 'title',
            render: (title: string, row) => <Link to={`/automations/${row.id}`}>{title}</Link>,
          },
          {
            title: 'Applied to campaigns',
            render: (row: FullRule) => {
              return <AppliedTargetsView targetIdObj={row.targetId} campaigns={campaigns?.body.campaigns || []} />
            },
          },
          {
            title: 'IF',
            render: (row: FullRule) => {
              return (
                <ConditionSetOverview
                  ruleTarget={row.ruleTarget}
                  conditions={row.condition}
                  isTop
                  analysisPeriod={row.analysisPeriod}
                />
              )
            },
          },
          {
            title: 'THEN',
            render: (row: FullRule) => {
              return <ResultActionView ruleTarget={row.ruleTarget} resultAction={row.resultAction} />
            },
          },
          {
            title: 'Repeat',
            render: (row: FullRule) => {
              return <RepeatView frequencyType={row.frequencyType} frequency={row.frequency} />
            },
          },
          {
            title: 'Actions',
            render: (row: FullRule) => {
              return (
                <Row style={{ gap: '1rem' }}>
                  <ChangeStatusIcon rule={row} />
                  <CopyRuleIcon id={row.id} />
                  <DeleteRuleIcon id={row.id} />
                </Row>
              )
            },
          },
        ]}
      />
    </>
  )
}

const DeleteRuleIcon: FC<{ id: number }> = ({ id }) => {
  const { apiClient } = useAppState()
  const queryClient = useQueryClient()
  const { mutateAsync: deleteRule } = apiClient.rule.deleteRule.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['rules-list'] })
    },
  })
  return (
    <Popconfirm
      title="Are you sure to delete this rule?"
      placement="left"
      onConfirm={() => {
        deleteRule({ body: { id } })
          .then(() => {
            showNotification({
              message: 'Rule deleted successfully',
              type: 'success',
            })
          })
          .catch(err => {
            console.error(err)
          })
      }}
      okText="Yes"
      cancelText="No"
    >
      <DeleteOutlined style={{ color: 'red' }} />
    </Popconfirm>
  )
}

const CopyRuleIcon: FC<{ id: number }> = ({ id }) => {
  const { apiClient } = useAppState()
  const queryClient = useQueryClient()
  const { mutateAsync: copyRule } = apiClient.rule.copyRule.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['rules-list'] })
    },
  })
  return (
    <Popconfirm
      title="Are you sure to copy this rule?"
      placement="left"
      onConfirm={() => {
        copyRule({ body: { id } })
          .then(() => {
            showNotification({
              message: 'Rule copied successfully',
              type: 'success',
            })
          })
          .catch(err => {
            console.error(err)
          })
      }}
      okText="Yes"
      cancelText="No"
    >
      <CopyOutlined style={{ color: 'blue' }} />
    </Popconfirm>
  )
}

const ChangeStatusIcon: FC<{ rule: FullRule }> = ({ rule }) => {
  const { apiClient } = useAppState()
  const queryClient = useQueryClient()
  const { mutateAsync: changeStatus } = apiClient.rule.changeRuleStatus.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['rules-list'] })
    },
  })
  return (
    <Popconfirm
      title={`Are you sure to change status of this rule to ${rule.status === 'WORKING' ? 'STOPPED' : 'WORKING'}?`}
      placement="left"
      onConfirm={() => {
        changeStatus({ body: { id: rule.id, status: rule.status === 'WORKING' ? 'PAUSED' : 'WORKING' } })
          .then(() => {
            showNotification({
              message: 'Rule status changed successfully',
              type: 'success',
            })
          })
          .catch(err => {
            console.error(err)
          })
      }}
      okText="Yes"
      cancelText="No"
    >
      {rule.status === 'WORKING' ? (
        <PauseCircleOutlined style={{ color: 'red' }} />
      ) : (
        <PlayCircleOutlined style={{ color: 'green' }} />
      )}
    </Popconfirm>
  )
}

export const AppliedTargetsView: FC<{
  targetIdObj: FullRule['targetId']
  campaigns?: { id: number; title: string }[]
}> = ({ targetIdObj, campaigns }) => {
  return (
    <>
      {targetIdObj && targetIdObj.campaignIds.length > 0 && (
        <ListOfCamapgins titles={campaigns} campaignIds={targetIdObj.campaignIds} />
      )}
      {(!targetIdObj || targetIdObj.campaignIds.length === 0) && (
        <Tooltip title="This rule applied to all campaigns">
          <Tag color="orange">All</Tag>
        </Tooltip>
      )}
    </>
  )
}
