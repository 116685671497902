import React, { FC, useState } from 'react'
import { DeleteOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons'
import PageListHeader from '../../components/layout/PageListHeader/PageListHeader'
import { useTranslation } from 'react-i18next'
import HelpPopover from '../../components/HelpPopover/HelpPopover'
import { ArchivedCampaignsList } from '../../containers/campaign/ArchivedCampaignsList/ArchivedCampaignsList'
import { Alert, Menu } from 'antd'
import { ToggleTrafficButton } from './components/toggle-traffic-button'
import { CampaignsList } from './components/campaign-list'
import { useAppState } from '../../context/AppContext'

const CAMPAIGNS_TAB = 'CAMPAIGNS_TAB'
const ARCHIVED_CAMPAIGNS_TAB = 'ARCHIVED_CAMPAIGNS_TAB'

export const CampaignsListPage: FC = () => {
  const { user } = useAppState()

  const components = {
    [CAMPAIGNS_TAB]: CampaignsList,
    [ARCHIVED_CAMPAIGNS_TAB]: ArchivedCampaignsList,
  }

  const { t } = useTranslation()

  const [currentTab, setTab] = useState<keyof typeof components>(CAMPAIGNS_TAB)

  const handleTabClick = (e: any) => setTab(e.key)

  const TabComponent = components[currentTab]

  const hiddenAlert = <Alert message="" className="ant-btn-lg" type="error" style={{ visibility: 'hidden' }} />
  const suspendedTrafficAlert = (
    <Alert
      message={`Traffic of all your campaigns suspended!`}
      className="ant-btn-lg"
      type="error"
      showIcon
      action={<ToggleTrafficButton {...{ danger: true }} />}
    />
  )
  const alert = user?.isTrafficEnabled ? hiddenAlert : suspendedTrafficAlert

  return (
    <div className="CampaignsListPage">
      <PageListHeader
        title={
          <>
            {t('Campaign.Campaigns')}
            <HelpPopover fontSize={18}>{t('CampaignsListPage.Help.Campaign')} </HelpPopover>
          </>
        }
        alerts={[alert as never]}
        buttons={[
          {
            link: `/campaign/create`,
            type: 'primary',
            icon: <PlusOutlined />,
            title: t('Actions.Create'),
          } as never,
        ]}
      />

      <Menu
        onClick={handleTabClick}
        selectedKeys={[currentTab]}
        mode="horizontal"
        style={{ marginBottom: 24, marginTop: 24 }}
      >
        <Menu.Item key={CAMPAIGNS_TAB} icon={<SendOutlined />} style={{ fontSize: 18 }}>
          Campaigns
        </Menu.Item>
        <Menu.Item key={ARCHIVED_CAMPAIGNS_TAB} icon={<DeleteOutlined />} style={{ fontSize: 18 }}>
          Archived campaigns
        </Menu.Item>
      </Menu>
      <TabComponent />
    </div>
  )
}
