{
  "AppLayout": {
    "Menu": {
      "Campaigns": "Campaigns",
      "Creatives": "Creatives",
      "Reports": "Reports",
      "Feeds": "Feeds",
      "Streams": "Streams",
      "Login": "Login",
      "Sign up": "Sign up",
      "Options": "Options",
      "Billing": "Billing",
      "Logout": "Logout",
      "Referral": "Referral program",
      "Traffic quality detector": "Traffic quality detector",
      "Dashboard": "Dashboard",
      "SubscriptionsList": "Black & White Lists",
      "UserAuditories": "User Auditories"
    },
    "Email confirmation": "Please confirm your email to use the service."
  },
  "Report": {
    "Title": "Report",
    "Loading": "Loading...",
    "Close": "Close",
    "Weekdays": {
      "Sunday": "Sunday",
      "Monday": "Monday",
      "Tuesday": "Tuesday",
      "Wednesday": "Wednesday",
      "Thursday": "Thursday",
      "Friday": "Friday",
      "Saturday": "Saturday"
    },
    "Months": {
      "January": "January",
      "February": "February",
      "March": "March",
      "April": "April",
      "May": "May",
      "June": "June",
      "July": "July",
      "August": "August",
      "September": "September",
      "October": "October",
      "November": "November",
      "December": "December"
    },
    "Tabs": {
      "CampaignsTab": "Campaigns",
      "StreamsTab": "Streams",
      "AdvertisersReportTab": "Advertisers Reports",
      "CampaignDiscrepancyTab": "Discrepancy report"
    },
    "Controls": {
      "DownloadReport": "Download report",
      "CreateReport": "Create report",
      "Filters": "Filters",
      "ClearAll": "Clear filters",
      "Timezone": "Timezone"
    },
    "CampaignReport": {
      "Controls": {
        "GroupBy": "Group by",
        "GroupBy1": "Additional group by",
        "GroupByErrorMsg": "Please select a term for groupping by. This is a required field.",
        "GroupByPlaceholder": "Select a term for groupping by",
        "DateRangeLabel": "Date range",
        "DateRangeMsg": "Please select a date range (from - to)",
        "CreativesFieldLabel": "Creatives",
        "CreativesFieldPlaceholder": "Creatives",
        "CampaignsFieldLabel": "Campaigns",
        "CampaignsFieldPlaceholder": "Campaigns",
        "UsersFieldLabel": "Users",
        "UsersFieldPlaceholder": "Users",
        "CampaignTagsFieldLabel": "Campaign Tags",
        "CampaignTagsFieldPlaceholder": "Campaign Tags",
        "CreativeTagsFieldLabel": "Creative Tags",
        "CreativeTagsFieldPlaceholder": "Creative Tags",
        "Timezone": "Timezone",
        "FieldLabel_country": "Country",
        "FieldLabel_region": "Region",
        "FieldLabel_city": "City",
        "FieldLabel_language_short": "Language",
        "FieldLabel_device": "Device",
        "FieldLabel_os_name": "OS",
        "FieldLabel_os_version": "OS Version",
        "FieldLabel_browser_name": "Browser",
        "FieldLabel_browser_version": "Browser Version",
        "FieldLabel_connection_type": "Connection type",
        "FieldLabel_domain": "Domain",
        "FieldLabel_isp": "ISP"
      },
      "Title": "Title",
      "Campaign": "Campaign",
      "FEED_ID": "Feed ID",
      "DOMAIN": "Domain (origin)",
      "ISP": "ISP",
      "Preview": "Preview",
      "CAMPAIGN_ID": "Campaign",
      "CREATIVE_ID": "Creative",
      "CONNECTION_TYPE": "Connection Type",
      "COUNTRY_CODE": "Country Code",
      "REGION_NAME": "Region Name",
      "POSTAL_CODE": "Postal Code",
      "CITY": "City",
      "TIMEZONE": "Timezone",
      "STREAM": "Stream",
      "SUBSTREAM": "Substream",
      "T1": "T1",
      "T2": "T2",
      "T3": "T3",
      "T4": "T4",
      "T5": "T5",
      "IS_ACTIVE": "Is Active",
      "INACTIVE_TIME": "Inactive Time",
      "A1": "A1",
      "A2": "A2",
      "A3": "A3",
      "A4": "A4",
      "A5": "A5",
      "A6": "A6",
      "A7": "A7",
      "A8": "A8",
      "A9": "A9",
      "A10": "A10",
      "HOUR": "Hour",
      "WEEKDAY": "Weekday",
      "MONTH": "Month",
      "YEAR": "Year",
      "DATE": "Date",
      "PayoutECPM": "Payout ECPM",
      "LeadsCR": "Leads CR",
      "LPClicksCTR": "LPClicks CTR",
      "ClosedCTR": "Closed CTR",
      "ClickedCTR": "Clicked CTR",
      "ShownCTR": "Shown CTR",
      "Payout": "Payout",
      "Leads": "Leads",
      "LPClicks": "LPClicks",
      "Clicked": "Clicked",
      "Closed": "Closed",
      "Shown": "Shown",
      "Sent": "Sent",
      "requests": "Requests",
      "bids": "Bids",
      "wonBids": "Won bids",
      "clicks": "Clicks",
      "fill": "Fill",
      "winRate": "Win rate",
      "cpc": "CPC",
      "cpm": "CPM",
      "cost": "Cost",
      "wonBidCost": "Won bid cost",
      "conv1": "Conversion 1",
      "conv2": "Conversion 2",
      "conv3": "Conversion 3",
      "payout": "Payout"
    },
    "StreamReport": {
      "Controls": {
        "GroupBy": "Group by",
        "GroupBy1": "Additional group by",
        "GroupByErrorMsg": "Please select a term for groupping by. This is a required field.",
        "GroupByPlaceholder": "Select a term for groupping by",
        "DateRangeLabel": "Date range",
        "DateRangeMsg": "Please select a date range (from - to)",
        "CreativesFieldLabel": "Creatives",
        "CreativesFieldPlaceholder": "Creatives",
        "CampaignsFieldLabel": "Campaigns",
        "CampaignsFieldPlaceholder": "Campaigns",
        "UsersFieldLabel": "Users",
        "UsersFieldPlaceholder": "Users",
        "StreamsFieldLabel": "Streams",
        "StreamsFieldPlaceholder": "Streams",
        "FieldLabel_country": "Country",
        "FieldLabel_language_short": "Language",
        "FieldLabel_device": "Device",
        "FieldLabel_os_name": "OS",
        "FieldLabel_os_version": "OS Version",
        "FieldLabel_browser_name": "Browser",
        "FieldLabel_browser_version": "Browser Version",
        "FieldLabel_connection_type": "Connection type",
        "FieldLabel_domain": "Domain",
        "FieldLabel_isp": "ISP",
        "ShowDeletedFlagLabel": "Show deleted streams"
      },
      "Title": "Campaign",
      "Stream": "Stream",
      "Preview": "Preview",
      "CAMPAIGN_ID": "Campaign",
      "CREATIVE_ID": "Creative",
      "STREAM_ID": "Stream",
      "SUBSTREAM": "Substream",
      "HOUR": "Hour",
      "WEEKDAY": "Weekday",
      "MONTH": "Month",
      "YEAR": "Year",
      "DATE": "Date",
      "PayoutECPM": "Payout ECPM",
      "LeadsCR": "Leads CR",
      "LPClicksCTR": "LPClicks CTR",
      "ClosedCTR": "Closed CTR",
      "ClickedCTR": "Clicked CTR",
      "ShownCTR": "Shown CTR",
      "Payout": "Payout",
      "Leads": "Leads",
      "LPClicks": "LPClicks",
      "Clicked": "Clicked",
      "Closed": "Closed",
      "Shown": "Shown",
      "Sent": "Sent",
      "requests": "Requests",
      "bids": "Bids",
      "wonBids": "Won bids",
      "clicks": "Clicks",
      "fill": "Fill",
      "winRate": "Win rate",
      "cpc": "CPC",
      "cpm": "CPM",
      "cost": "Cost"
    },
    "AdvertisersReport": {
      "Controls": {
        "GroupBy": "Group by",
        "GroupBy1": "Additional group by",
        "GroupByErrorMsg": "Please select a term for groupping by. This is a required field.",
        "GroupByPlaceholder": "Select a term for groupping by",
        "DateRangeLabel": "Date range",
        "DateRangeMsg": "Please select a date range (from - to)",
        "CreativesFieldLabel": "Creatives",
        "CreativesFieldPlaceholder": "Creatives",
        "CampaignsFieldLabel": "Campaigns",
        "CampaignsFieldPlaceholder": "Campaigns",
        "UsersFieldLabel": "Users",
        "UsersFieldPlaceholder": "Users"
      },
      "Title": "Title",
      "Campaign": "Campaign",
      "FEED_ID": "Feed ID",
      "DOMAIN": "Domain (origin)",
      "ISP": "ISP",
      "Preview": "Preview",
      "ADVERTISER_ID": "Advertiser",
      "HOUR": "Hour",
      "WEEKDAY": "Weekday",
      "MONTH": "Month",
      "YEAR": "Year",
      "DATE": "Date",
      "Payout": "Payout",
      "Clicked": "Clicked",
      "Closed": "Closed",
      "Shown": "Shown",
      "Sent": "Sent",
      "requests": "Requests",
      "bids": "Bids",
      "wonBids": "Won bids",
      "clicks": "Clicks",
      "fill": "Fill",
      "winRate": "Win rate",
      "cpc": "CPC",
      "cpm": "CPM",
      "cost": "Cost",
      "wonBidCost": "Won bid cost",
      "conv1": "Conversion 1",
      "conv2": "Conversion 2",
      "conv3": "Conversion 3"
    }
  },
  "Billing": {
    "List": {
      "Title": "Billing",
      "ID": "ID",
      "Type": "Type",
      "Amount": "Amount",
      "Status": "Status",
      "Description": "Description",
      "Updated": "Updated",
      "Details": "Details"
    },
    "PaymentPage": {
      "Title": "Bill Payment",
      "Status": {
        "Process": "Processing",
        "Success": "Success"
      },
      "PaymentForm": {
        "SubmitButton": "Pay"
      }
    },
    "BillingPage": {
      "Loading": "Loading ...",
      "Billing": "Billing",
      "Current plan": "Current plan",
      "Max push subscription": "Max. push subscription",
      "Monthly payment": "Monthly payment",
      "Active until": "Active until",
      "Updating of plan": "Your current plan will be updated automatically when the push subscription limit is reached. ",
      "See a pricing table": "See a pricing table",
      "Current balance": "Current balance",
      "Top up": "Top up",
      "Visa": "Visa, Mastercard, cryptocurrencies",
      "Amount": "Amount ($)",
      "Other top up methods": "Other top up methods",
      "Webmoney": "Webmoney, Payoneer: Contact support",
      "Contact support": "If you were unable to top up using a card, contact support and we will issue you an invoice manually",
      "Transactions": "Transactions"
    }
  },
  "Campaign": {
    "Campaign": "Campaign",
    "Campaigns": "Campaigns",
    "Creatives": "Creatives",
    "Title": "Title",
    "Loading": "Loading ...",
    "Url": "URL",
    "Price": "Price",
    "Tags": "Tags",
    "Tags Help": "The tags of a campaign are used solely for filtering campaigns on the reports page.",
    "Sent": "Sent",
    "Shown": "Shown",
    "Clicked": "Clicked",
    "Closed": "Closed",
    "Feeds": "Feeds",
    "Streams": "Streams",
    "Substreams": "Substreams",
    "Domains": "Domains",
    "UserAuditories": "User Auditories",
    "Useragents": "User-Agents",
    "Languages": "Languages",
    "Countries": "Countries",
    "Browsers": "Browsers",
    "Browser Version": "Browser Versions",
    "Devices": "Devices",
    "Target Hours": "Target Hours",
    "Target Days": "Target Days",
    "Target Days And Hours": "Days And Hours",
    "OS": "OS",
    "OS Version": "OS Version",
    "Os Version Warning": "Some of the streams are not support client hints.",
    "Os Version Warning Description": "Targeting for some OS versions will be unpredicatable. Without client hints: Windows last version - 10; MacOS last version - 10.15.7",
    "Max shows": "Max shows",
    "Max clicks": "Max clicks",
    "Status": {
      "Status": "Status",
      "Running": "Running",
      "Stopped": "Stopped"
    },
    "Success Edit URL": "Success Edit URL",
    "CampaignType": "Campaign Type",
    "CampaignTypeShort": "Type",
    "BidModel": "Bid Model",
    "connectionType": "Connection Type",
    "isp": "ISP",
    "Hide additional targets": "Hide additional targets",
    "Show additional targets": "Show additional targets",
    "Settings": "Settings",
    "CampaignStreams": {
      "Controls": {
        "Timezone": "Timezone",
        "StreamFieldLabel": "Stream",
        "StreamFieldPlaceholder": "Stream",
        "DownloadReport": "Download report",
        "CreateReport": "Create report",
        "Filters": "Filters",
        "ClearAll": "Clear filters",
        "GroupBy": "Group By",
        "GroupByErrorMsg": "Please select a term for groupping by. This is a required field.",
        "GroupByPlaceholder": "Select a term for groupping by",
        "DateRangeLabel": "Date range",
        "DateRangeMsg": "Please select a date range (from - to)"
      },
      "Stream": "Stream",
      "Substream": "Substream",
      "STREAM_ID": "Stream",
      "SUBSTREAM_ID": "Substream",
      "HOUR": "Hour",
      "WEEKDAY": "Weekday",
      "MONTH": "Month",
      "YEAR": "Year",
      "DATE": "Date",
      "PayoutECPM": "Payout ECPM",
      "LeadsCR": "Leads CR",
      "LPClicksCTR": "LPClicks CTR",
      "ClosedCTR": "Closed CTR",
      "ClickedCTR": "Clicked CTR",
      "ShownCTR": "Shown CTR",
      "Payout": "Payout",
      "Leads": "Leads",
      "LPClicks": "LPClicks",
      "Clicked": "Clicked",
      "Closed": "Closed",
      "Shown": "Shown",
      "Sent": "Sent",
      "requests": "Requests",
      "bids": "Bids",
      "wonBids": "Won bids",
      "clicks": "Clicks",
      "fill": "Fill",
      "winRate": "Win rate",
      "cpc": "CPC",
      "cpm": "CPM",
      "cost": "Cost",
      "conv1": "Conversion 1",
      "conv2": "Conversion 2",
      "conv3": "Conversion 3",
      "payout": "Payout",
      "ToggleStream": "Toggle Stream",
      "ToggleSubstream": "Toggle Substream",
      "StreamBid": "Stream Bid",
      "SubstreamBid": "Substream Bid"
    },
    "EstimateModalTitle": "Estimate campaign settings impact",
    "EstimateParams": {
      "exactTargetsMatch": "Exact Targets Match",
      "includeCreatives": "Include Creatives",
      "includeCreativesExact": "Include Creatives",
      "includeBlWl": "Include Black and White list(similar lists)",
      "includeBlWlExact": "Include Black and White lists(exact)",
      "includeUserAuditories": "User Auditories(similar)",
      "includeUserAuditoriesExact": "User Auditories(exact)",
      "includeUserAuditoriesConditions": "User Auditories Conditions",
      "includeUserAuditoriesConditionsExact": "User Auditories Conditions",
      "includeCampaignLimits": "Campaign Limits less that or equal",
      "includeCampaignLimitsExact": "Campaign Limits equal",
      "includeUserLimits": "User Limits less than or equal",
      "includeUserLimitsExact": "User Limits equal",
      "includeBudgetLimits": "Buget Limits less than or equal",
      "includeBudgetLimitsExact": "Buget Limits are equal",
      "includeTimeSchedule": "Time Schedule(Days and Hours in Timezone, only exact match)",
      "includeTimeScheduleExact": "Time Schedule(Days and Hours in Timezone, only exact match)"
    },
    "EstimateData": {
      "wonBids": "Won Bids",
      "bids": "Bids",
      "clicks": "Clicks",
      "fill": "Fill",
      "winRate": "Win rate",
      "impressions": "Impressions",
      "requests": "Requests",
      "cpc": "CPC",
      "cpm": "CPM",
      "cost": "Cost",
      "conv1": "Conversion 1",
      "conv2": "Conversion 2",
      "conv3": "Conversion 3",
      "payout": "Payout",
      "costForDay": "Cost for day",
      "costForWeek": "Cost for week",
      "costForMonth": "Cost for month"
    }
  },
  "Creative": {
    "Creative": "Creative",
    "Creatives": "Creatives",
    "Is Random Data": "Random Data",
    "Is Random Data Help": "If you enable \"Random Data\" you will be able to specify a variety of different title, body, body image, and preview image options. When sending this creative to a subscriber, randomly selected values from the specified options will be shown.",
    "Is Random Data Yes": "Yes",
    "Is Random Data No": "No",
    "Title": "Title",
    "Titles": "Titles",
    "Body": "Body",
    "Bodies": "Bodies",
    "Tags": "Tags",
    "Tags Help": "The tags of a creative are used solely for filtering creatives on the page with all creatives and do not affect the sending of push notifications in any way.",
    "URL": "URL",
    "Help": {
      "Frequency tooltip": "The frequency of sending a creative. Measured in hours. 0 - infinitely. ",
      "Pause tooltip": "Pause creative",
      "Start tooltip": "Start creative",
      "Delay tooltip": "The period of time between subscription and sending this creative. 0 — unlimited (In hours)",
      "Send once tooltip": "If selected, the message for this creative will be sent only once",
      "Capping": "When all capping limits are reached, push notifications for this subscription will no longer be sent. 0 — no limits."
    },
    "Delay": "Delay",
    "Send once": "Send once",
    "Capping": "Capping per a subscription",
    "Shown": "Shown",
    "Clicked": "Clicked",
    "Closed": "Closed",
    "Sent": "Sent",
    "LPClicks": "LPClicks",
    "Leads": "Leads",
    "Actions": {
      "ActionID": "ID (i.e. a1)",
      "ActionTitle": "Title (i.e. Order)",
      "ActionURL": "Callback URL (i.e. https://a1.com)",
      "ActionButtonAdd": "Add Action (Button) to Creative",
      "Reccomendation": "*Recommendation: use no more than 2 actions per creative. If you need more, use a different creative.",
      "Error": {
        "ActionID": "Missing action ID (i.e. a1)",
        "ActionTitle": "Missing action title (i.e. Order)",
        "ActionURL": "Missing callback URL (i.e. https://a1.com)"
      }
    },
    "Edit URL": "Edit URL",
    "Bulk Edit Creative URL Title": "Edit Creative URLs",
    "Bulk Edit Creative URL Button": "Update URLs",
    "Bulk Edit Creative URL Label": "New Creatives URL",
    "Success Edit URL Count": "Successfully updated creatives count:",
    "Add Existing Success": "Creative successfully added",
    "Add Existing Title": "Add existing creative",
    "Add Existing Cancel": "Cancel",
    "Add Existing Confirm": "Add",
    "Add Existing Select Creative": "Select creative",
    "Add Existing Choose Url": "Choose URL"
  },
  "BlackWhiteList": {
    "BlackWhiteList": "Black & White Lists",
    "BlackWhiteLists": "Black & White Lists",
    "Title": "Title",
    "Description": "Description",
    "BlockCount": "Total count",
    "Items": "Items",
    "SubscriptionsListListPage": {
      "Help": {
        "SubscriptionsListListPage": "The list of subscriptions (white and black lists)"
      }
    },
    "Whitelists": "Whitelists",
    "Blacklists": "Blacklists",
    "Create": {
      "Title": "Create Black & White List",
      "Help": {
        "Create": "Create a new Black & White list"
      }
    },
    "Settings": {
      "Title": "List Title",
      "Description": "Description",
      "SubscriptionsIds": "Subscriptions IDs",
      "SubscriptionsUids": "Subscriptions UIDs",
      "Help": {
        "Data": {
          "Id": "Please insert IDs of subscriptions to the list. IDs should be separated by commas or new line. Example: 1,2,3,4,5,6,7,8,9,10 or \n1\n2\n3 etc.",
          "Uid": "Please insert UIDs of subscriptions to the list. UIDs should be separated by commas or new line. Example: 1,2,3,4,5,6,7,8,9,10 or \n1\n2\n3 etc."
        },
        "Title": "Please enter a Title for this List in order to easily identify it for future use.",
        "Description": "A complimentary description of the list. This description will be displayed in the list of lists."
      }
    }
  },
  "Feed": {
    "Feed": "Feed",
    "Feeds": "Feeds",
    "Title": "Title",
    "Subscriptions": "Active subscriptions",
    "Unsubscriptions": "Unsubscribed",
    "Description": "Description",
    "Postback URL": "Postback URL",
    "SharedToUsersLabel": "Share this Feed to users:",
    "No Certificates": "Please upload certificates",
    "CRT Certificate": "CRT Certificate",
    "Key Certificate": "Key Certificate",
    "Intermediate Certificate": "Intermediate Certificate",
    "Website name": "Notification title",
    "Link Url": "Link URL",
    "WebsitePushID": "Website Push ID",
    "WebServiceURL": "Webservice URL",
    "AllowedDomains": "Allowed Domains",
    "At least 16 characters": "Authentication token must be at least 16 characters long",
    "WebsitePushID placeholder": "Must be unique for each feed",
    "WebServiceURL placeholder": "For production: https://pushflow.net/api/push",
    "Instruction Header": "How to create certificates?",
    "Instruction": "1. Create an Apple developer account. \n2. Go to Certificates, Identifiers & Profiles -> Identifiers. Create a new Website Push IDs.\n3. In Keychain, go to Certificate Assistant -> Request a Certificate From Certificate Authority. Enter your email and download the CSR file.\n4. In the developer account, go to Certificates -> Create new -> Website Push IDs. Upload the CSR file and download the .cer certificate.\n5. Place the downloaded .cer certificate into the Keychain, then export it in .p12 format with the name key.\n 6. Type in the terminal: openssl pkcs12 -in key.p12 -out key.pem -nodes -clcerts . The resulting .pem file is your Key certificate.\n 7. Make a copy of the .pem key file. Using a text editor, edit the file so that only the part from ----BEGIN CERTIFICATE----- to -----END CERTIFICATE----- inclusive remains. The resulting .pem file is your CRT certificate.",
    "Icon": "Icon 256x256",
    "IconAlt": "Icon preview",
    "Download": "Download",
    "Help": {
      "Macros": "Available macros:",
      "Frequency": "How often a notification can be sent to one user. 0 - no limits (In hours)",
      "SharedToUsers": "Please insert User IDs to share this feed with. IDs should be separated by commas or new line. Example: 1,2,3,4,5,6,7,8,9,10 or \n1\n2\n3 etc."
    },
    "Statistic": {
      "Country": "Country",
      "Subscriptions": "Subscriptions",
      "Device": "Device",
      "Connection Type": "Connection Type",
      "Connection": "Connection",
      "Browser": "Browser",
      "OS": "OS",
      "Language": "Language",
      "Origin": "Origin"
    }
  },
  "Actions": {
    "Create": "Create",
    "Paste": "Paste",
    "Copy": "Copy",
    "Start": "Start",
    "Pause": "Pause",
    "Submit": "Submit",
    "Send": "Send",
    "Send messages": "Send messages",
    "Delete": "Delete",
    "Archive": "Archive",
    "Edit": "Edit",
    "Save": "Save",
    "Download": "Download",
    "Add new": "Add new",
    "Estimate": "Estimate"
  },
  "Table": {
    "Actions": "Actions"
  },
  "Date": {
    "Days": {
      "Monday": "Monday",
      "Tuesday": "Tuesday",
      "Wednesday": "Wednesday",
      "Thursday": "Thursday",
      "Friday": "Friday",
      "Saturday": "Saturday",
      "Sunday": "Sunday"
    },
    "Short Days": {
      "Monday": "Mo",
      "Tuesday": "Tu",
      "Wednesday": "We",
      "Thursday": "Th",
      "Friday": "Fr",
      "Saturday": "Sa",
      "Sunday": "Su"
    }
  },
  "Common": {
    "Loading": "Loading ...",
    "Error": "Error",
    "Frequency": "Frequency",
    "Capping": "Capping",
    "Settings": "Settings",
    "Email": "Email",
    "Password": "Password",
    "Password again": "Password again",
    "Learn more": "Learn more",
    "Title": "Title",
    "Documentation": "Documentation",
    "Statistic": "Statistic",
    "Adtype": {
      "Push": "Web push notifications",
      "Feed Type": "Feed Type",
      "Campaign Type": "Campaign Type"
    },
    "Promo Code": "Promo Code",
    "Unavailable": "Sorry, this section is temporarily unavailable"
  },
  "Messages": {
    "Sent": "Sent",
    "Pasted": "Pasted",
    "Saved": "Saved"
  },
  "CampaignCreatePage": {
    "Create campaign": "Create campaign"
  },
  "CampaignSettingsForm": {
    "Price": "Price",
    "CampaignSettings": "Campaign Settings",
    "Targeting": "Targeting options",
    "Time schedule": "Time schedule",
    "Time schedule description": "Messages will be sent every hour depend on this settings.",
    "Subscription Period": {
      "label": "Subscription period",
      "help": "Range of time in hours when the subscription was added to the feed. Empty field or 0 - not limits.",
      "inputLabel": "From % to % "
    },
    "ShowStreamLists": "Show Back & White stream lists",
    "Help": {
      "Delivery type": "Creative and feed frequency — notifications will be sent based on the creative and feed frequency settings\nCreative schedule — notifications will be sent based on the creative schedule "
    },
    "Found subscriptions": "Found subscriptions",
    "Choose a feed": "Choose a feed or feeds to send notifications.",
    "Choose a stream": "Choose a stream or streams to send notifications.",
    "Whitelists": "Whitelists",
    "Blacklists": "Blacklists",
    "ChooseWhitelists": "Choose whitelists",
    "ChooseBlacklists": "Choose blacklists",
    "AddBlackWhiteList": "Add new Black & White list",
    "SaveBlackWhiteList": "Save",
    "CancelModal": "Cancel",
    "ShowSubscriptionsLists": "Show Black & White lists",
    "HideSubscriptionsLists": "Hide Black & White lists",
    "Upgrade plan": "The number of subscriptions in targeting exceeds the capabilities of your current plan.",
    "Campaign version": "Version",
    "Delivery type": "Delivery type",
    "Frequency delivery": "Creative and feed frequency(LEGACY)",
    "Frequency_V2 delivery": "Creative and feed frequency",
    "Frequency_V3 delivery": "Creative and feed frequency",
    "Schedule delivery": "Creative schedule",
    "Schedule_V2 delivery": "Creative schedule",
    "Creatives frequency": "Creatives frequency, hours",
    "Creatives frequency tooltip": "Frequency for all creatives. Freeds frequency will be ignored! If the interval is less then the frequency(usually it is 1h, subscriptions will be divided into an relatively equal number of groups to be sent at each interval. For example if interval=1h, frequency=3h, there are 900 subscribers and a single creative (so 1 sub = 1 push), then subscribers will be divided in 3 equal parts, so that in the first hour pushes for the first 300 will be sent, second hour the next 300, third hour the next 300, and the same pattern will be repeated each hour. If the schedule is made so that there is a non divisible number of consecutive hours in the schedule, lets say 5, then there will be only 300*5 pushes sent: 600 subsscribers will receive 2 pushes in the span of 5 hours, and 300 will receive only a single push. 0 or no value - same as the interval(1 hour).",
    "Campaign limits": "Campaign limits",
    "Creatives limits": "Creatives limits",
    "Creatives limits tooltip": "Stop sending a creative to a subscriber if ALL of the limits are reached for that subscriber. Limits are counted separately for each subscriber and each creative. 0 or no value - no limits. You can also specify the list where the subscription will go when the limit is reached",
    "Creatives limit list tooltip": "When the limit is reached, the subscription will be added to the selected list",
    "maxShown": "Max shown",
    "MaxShownDescription": "Max shown times to all users",
    "maxClicked": "Max clicked",
    "MaxClickedDescription": "Max clicked times by all users",
    "maxClosed": "Max closed",
    "maxLPClicks": "Max LPClicks",
    "maxLeads": "Max leads",
    "maxDailyBudget": "Max daily budget",
    "MaxDailyBudgetDescription": "Max USD spent for one day",
    "maxTotalBudget": "Max total budget",
    "MaxTotalBudgetDescription": "Max USD spent for whole campaign",
    "AuditoriesConditions": {
      "MaxClickedThreshold": "Clicks",
      "MaxClickedAuditory": "Add to auditory",
      "MaxClickedThresholdDescription": "When user clicked N times his ID will be added to auditory",
      "MaxShownThreshold": "Impressions",
      "MaxShownAuditory": "Add to auditory",
      "MaxShownThresholdDescription": "When campaign shown N times to user his ID will be added to auditory"
    },
    "Ip": "IP",
    "Includes": "Includes",
    "Excludes": "Excludes",
    "Interval": "Sending interval, min",
    "Timezone Policy": "Timezone Policy",
    "Use Subscription Timezone": "Use Subscription Timezone",
    "Use Default Timezone": "Use Default Timezone",
    "Default Timezone": "Default Timezone",
    "sendRandomCreatives": "Send random",
    "sendRandomTooltip": "If the value is greater than 0, then creatives will be sent in random order. The number indicates how many random creatives will be sent at once",
    "Timezone": "Timezone"
  },
  "CreativeForm": {
    "Add creative": "Add creative",
    "Edit creative": "Edit creative",
    "Preview Image": "Upload Preview Image",
    "Body Image": "Upload Body Image",
    "Preview Images": "Preview Images",
    "Body Images": "Body Images",
    "Help": {
      "Macros": "Available macros:"
    },
    "Delete current image": "Delete current image",
    "Batch Cropper": {
      "Delete": "Delete",
      "Upload": "Upload Images",
      "Upload More": "Upload More Images"
    }
  },
  "FeedSettingsForm": {
    "Feed Settings": "Feed Settings"
  },
  "FeedScriptForm": {
    "Script Settings": "Redirects settings",
    "Allow Button Redirect": "Redirect URL when a user clicks \"Allow\"",
    "Script Failed Redirect": "Redirect URL when a user clicks \"Block\" or if there is no support for push notifications",
    "Denied URL redirect loop": "\"Block\" button URL redirects loop:",
    "Help": {
      "Each URL on a new": "Each time users clicks the \"Block\" button, they will be redirected to the next subdomain and the subscription will be requested again. Each URL on a new line starting from the entry page",
      "How it works": "Here you can set up redirects that will occur after the user subscribes to push notifications, blocks a subscription, or a subscription request error occurs. You can also set up a chain of redirects that will happen when user clicks the \"Block\" button in the subscription window. "
    }
  },
  "GeneratedScript": {
    "How to start collecting": "How to start collecting push subscriptions",
    "How to start step by step": "1. <1>Download service worker file (sw-pushflownet.js)</1> and put it to your web-server root directory <br /> 2. Choose one of methods below how you want to collect new subscribers, (Important! Scripts below are uniq for each feed)",
    "Plain Javascript": "Plain Javascript",
    "HTML templates": "HTML templates",
    "Interactive message": "Interactive message (In development)",
    "Page overlay": "Page overlay (In development)"
  },
  "FeedSubscribe": {
    "Already Subscribed": "You are already subscribed to this feed",
    "Subscribed Successfully": "You have successfully subscribed to this feed",
    "Subscribe": "Subscribe"
  },
  "PlainJavascript": {
    "On deny open in new tab": "If user denied to subscribe, it will open a new tab with url from 'denied' urls",
    "Wrap code in a script tag": "Wrap code to a <script> tag",
    "Ask for a subscription on a page load": "Ask for a subscription on a page load",
    "How it works": "How it works",
    "Place this Javascript code to your": "Copy and paste this Javascript code to your {{pageOrCode}} {{callOrNot}} to show subscription window {{isPageLoad}}",
    "Copy Script to clipboard": "Copy Script to clipboard",
    "Description": {
      "Main": "Copy and paste this code at the bottom of the web page before the </body> tag.",
      "Call function": "To call the subscription window, call the function:",
      "Start on page load": "A subscription request will be made as soon as the page is loaded."
    },
    "Script filename title": "SDK variants",
    "Script filenames": {
      "Standard": "Standard",
      "Lighter": "Light",
      "Ancient": "Ancient"
    },
    "Script popovers": {
      "Standard": "Great for modern browsers. \nChrome: 70+\nEdge: 90+\nSamsung: 11+",
      "Lighter": "Lighter version, comes without widgets. Great for modern browsers. \nChrome: 70+\nEdge: 90+\nSamsung: 11+",
      "Ancient": "Great for older browser versions. \nChrome: 60+\nEdge: 80+\nSamsung: 6+"
    },
    "Subscription window": "Subscription window",
    "Lock a page": "Lock a website content when the subscription window is active",
    "Show message on success": "Show message about successful subscription",
    "Dialog widget": {
      "Dialog widget": "Dialog widget",
      "Description": "Leave the fields blank to use the default value in this widget.",
      "Position on page": "Position on page",
      "Cancel Button": "Cancel Button",
      "Subscribe Button": "Subscribe Button",
      "Secondary text": "Secondary text",
      "Icon default": "By default the notification icon is used",
      "Delay": "Display delay, ms",
      "Lock background": "Lock a website when the widget is active?"
    },
    "Bubble widget": {
      "Description": "The widget will appear in the bottom right corner of the page. Leave the fields blank to use the default value.",
      "Text": "Text in bubble",
      "Title": "Bubble widget"
    },
    "Robot widget": {
      "Description": "A widget in the appearance of a captcha, after clicking on which, a subscription request window appears. After which the widget disappears.",
      "Title": "Captcha widget"
    },
    "Help widget": {
      "Title": "Help widget",
      "Description": "The widget displays if the user has disabled notifications in the browser and tells him how to enable notifications. Leave the fields blank to use the default value",
      "Title Label": "Title",
      "Description Label": "Description"
    }
  },
  "HtmlTemplates": {
    "All templates ready to use": "All templates ready to use. Simple download template and upload it to your web server."
  },
  "LoginPage": {
    "Login title": "Login",
    "Login button": "Login",
    "Forgot password": "Forgot password?"
  },
  "ResetPasswordPage": {
    "Reset title": "Password Reset",
    "Back to login": "Back to login",
    "The password recovery link has been sent to": "The password recovery link has been sent to",
    "If you don't receive the email": "If you don't receive the email within 10 minutes, be sure to check the Spam folder and then contact the support.",
    "Create new password": "Create new password",
    "Passwords don't match": "Passwords don't match"
  },
  "SignupPage": {
    "Sign up title": "Sign up",
    "Name": "Name",
    "Sign up button": "Sign up",
    "Messenger": "Messenger",
    "Affiliate Type": "Who're you?",
    "Traffic Volume": "Clicks daily",
    "Affiliate Geo": "Affiliate Geos"
  },
  "CampaignPage": {
    "Change plan": "Choose another plan",
    "Not allowed to send": "Your free subscription plan does not allow you to send push notifications.",
    "Run on schedule": "Run on schedule",
    "Stop scheduled sending": "Stop scheduled sending",
    "Send notifications once": "Send notifications now",
    "Last delivery": "Last delivery"
  },
  "ReferralPage": {
    "How it works": "How it works",
    "How it works text": "Copy the link from the right column and send it to your friends. Every user registered by your link who confirms their email becomes your referral and gets $15 to the account.",
    "Referral rewards": "Referral rewards",
    "Referral rewards text": "1.You will receive 20% of the amount of each deposit from each of your referrals. <br/>\n2. You will receive $5 into your account for the first 3 users, who will confirm their email.",
    "Terms and conditions": "Terms and conditions",
    "Terms and conditions text": "* You cannot withdraw money received from referrals from your account.<br/>\n* If fraud is detected with the referral program, all referral funds will be canceled(for Example, registration with one-day emails, etc.)"
  },
  "FeedsListPage": {
    "Help": {
      "Feed": "Feed - a bucket where your push subscriptions will be stored. After it is created and configured, a unique JS script and prelanders will be generated for the feed. After adding it to your webpage or webserver, push subscriptions will be saved in this feed."
    }
  },
  "CampaignsListPage": {
    "Help": {
      "Campaign": "Campaigns are needed to send notifications. To send notifications, you need to create a feed and start collecting subscriptions to it."
    },
    "Edit URL": "Edit URL",
    "Success Started Count": "Successfully started campaigns count:",
    "Success Paused Count": "Successfully paused campaigns count:",
    "Success Delete Count": "Successfully deleted campaigns count:",
    "Success Archive Count": "Successfully archived campaigns count:",
    "Success Edit URL Count": "Successfully updated campaigns count:",
    "Bulk Archive Title": "Archive Campaigns",
    "Bulk Archive Button": "Archive All",
    "Bulk Delete Title": "Delete Campaigns",
    "Bulk Delete Button": "Delete All",
    "Bulk Edit URL Title": "Edit Campaigns URL",
    "Edit Campaign URL Title": "Edit Campaign URL",
    "Edit Campaign URL Button": "Update URL",
    "Bulk Edit Campaign URL Button": "Update URL",
    "Bulk Edit Campaign URL Label": "New Campaigns URL",
    "Edit URL Label": "New Campaign URL",
    "Cancel": "Cancel"
  },
  "CreativesListPage": {
    "Page Title": "Creatives",
    "Bulk Copy Success": "Creatives successfully copied",
    "Bulk Copy Button": "Bulk Copy Creatives",
    "Bulk Copy Title": "Bulk Copy Creatives",
    "Bulk Copy Cancel": "Cancel",
    "Bulk Copy Confirm": "Bulk Copy Creatives",
    "Bulk Copy Creative URL Label": "Creative URL",
    "Table": {
      "Campaign ID": "Campaign ID",
      "CreativeType": "Creative Type",
      "Title": "Title",
      "Body": "Body",
      "Tags": "Tags",
      "Random Data": "Random Data",
      "Preview Image": "Preview Image",
      "Body Image": "Body Image"
    }
  },
  "Dashboard": {
    "Dashboard": "Dashboard",
    "Campaigns": {
      "Campaigns": "Campaigns",
      "Sent in 14 days": "Sent in the last 14 days",
      "Shown in 14 days": "Shown in the last 14 days",
      "Clicked in 14 days": "Clicked in the last 14 days",
      "Closed in 14 days": "Closed in the last 14 days"
    },
    "Subscriptions": {
      "Total subscriptions": "Total subscriptions",
      "Yesterday": "Subscriptions yesterday (Unsub., %)",
      "14 days": "Subscriptions 14 days (Unsub.%)",
      "Subscriptions": "Push subscriptions"
    }
  },
  "SubscriptionStatsContainer": {
    "Total subscriptions": "Active subscriptions",
    "Total unsubscriptions": "Total unsubscriptions",
    "Subscriptions 14 days": "Last 14 days (Unsub.%)",
    "Unsub": "(Unsub., %)",
    "On the last day": "On the last active day (Unsub., %)",
    "Subscriptions Desc": "The number of subscriptions on this day.",
    "Unsubscriptions Desc": "The number of subscriptions that were created on this day but later unsubscribed.",
    "Inactivations Desc": "The number of subscriptions that were unsubscribed on this day, regardless of when they were created."
  },
  "CampaignStatsContainer": {
    "Campaigns": "Campaigns",
    "Bids in 14 days": "Bids in the last {{count}} days",
    "WonBids in 14 days": "Won Bids in the last {{count}} days",
    "Clicks in 14 days": "Clicked in the last {{count}} days",
    "Cost in 14 days": "Cost in the last {{count}} days"
  },
  "UserSettings": {
    "Email": "Email",
    "Name": "Name",
    "Telegram": "Telegram",
    "Postback": "Postback",
    "Password": "Password",
    "Password again": "Password again",
    "Public": "Public:",
    "Private": "Private:",
    "Settings": "Settings",
    "Change password": "Change password",
    "Vapid keys": "Vapid keys"
  },
  "UserAuditory": {
    "Users": "Users"
  },
  "CompareOperations": {
    "EQUAL": "Equal",
    "NOT_EQUAL": "Not equal",
    "GREATER": "Greater",
    "GREATER_OR_EQUAL": "Greater or equal",
    "LESS": "Less",
    "LESS_OR_EQUAL": "Less or equal"
  },
  "TrafficBlockersModal": {
    "TrafficBlockers": "Traffic Blockers",
    "TimeInterval": {
      "Hour": "Hour",
      "Day": "Day",
      "Week": "Week",
      "Month": "Month"
    },
    "BlockerName": {
      "price_locks": "Price",
      "campaign_type": "Campaign Type",
      "stream_settings_status": "Stream Settings Status",
      "substream_settings_status": "Substream Settings Status",
      "stream_whitelist_not_includes": "Stream Whitelist Not Includes",
      "substream_whitelist_not_includes": "Substream Whitelist Not Includes",
      "stream_blacklist_includes": "Stream Blacklist Includes",
      "substream_blacklist_includes": "Substream Blacklist Includes",
      "subscription_period_missing": "Subscription Period Missing",
      "subscription_period_from": "Subscription Period From",
      "subscription_period_to": "Subscription Period To",
      "blocked_by_stream": "Stream",
      "blocked_by_streams": "Stream",
      "blocked_by_substream": "Substream",
      "blocked_by_substreams": "Substream",
      "blocked_by_domain": "Domain",
      "blocked_by_language": "Language",
      "blocked_by_lang": "Language",
      "blocked_by_isp": "ISP",
      "blocked_by_country": "Country",
      "blocked_by_region": "Region",
      "blocked_by_os_name": "OS",
      "blocked_by_os": "OS",
      "blocked_by_os_version": "OS Version",
      "blocked_by_browser_name": "Browser",
      "blocked_by_browser": "Browser",
      "blocked_by_browser_version": "Browser Version",
      "blocked_by_connection_type": "Connection Type",
      "blocked_by_device": "Device",
      "blocked_by_osName": "OS",
      "blocked_by_osVersion": "OS Version",
      "blocked_by_browserName": "Browser",
      "blocked_by_browserVersion": "Browser Version",
      "blocked_by_connectionType": "Connection Type",
      "blocked_by_subage": "Subage",
      "auditories_missing_user_id": "Auditories Missing User ID",
      "auditories_user_not_in_whitelist_auditory": "Auditories User Not In Whitelist Auditory",
      "auditories_user_in_blacklist_auditory": "Auditories User In Blacklist Auditory",
      "limits_missing_user_id": "Limits Missing User ID",
      "limits_impressions": "Limits Impressions",
      "limits_clicks": "Limits Clicks",
      "limits_daily_budget": "Limits Daily Budget",
      "limits_total_budget": "Limits Total Budget",
      "limits_impressions_user": "Limits Impressions User",
      "limits_clicks_user": "Limits Clicks User"
    }
  }
}