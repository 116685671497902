import { Condition, ConditionSet } from '@pushflow/backend-http-contract'
import { Col } from 'antd'
import React, { FC, useState } from 'react'
import { AntDActionElement, QueryBuilderAntD } from '@react-querybuilder/antd'
import QueryBuilder, { defaultOperators, Field, RuleGroupType, RuleType, toFullOption } from 'react-querybuilder'

import './qb.css'
import 'react-querybuilder/dist/query-builder.css'
import { conditionSetToQuery, queryToConditionSet } from '../utils/qb-to-condition'

export const defaultCondition: Condition = {
  conditionTarget: 'clicks',
  operator: '<',
  value: 1,
}

export const getConditionOperator = (condition: ConditionSet) => {
  if ('and' in condition) {
    return 'and'
  }
  if ('or' in condition) {
    return 'or'
  }

  throw new Error('Invalid condition op')
}

export const defaultConditionSet = (op: 'or' | 'and' = 'and'): ConditionSet => {
  return { combinator: op, conditions: [defaultCondition] } as ConditionSet
}

export const getConditions = (c: ConditionSet): (Condition | ConditionSet)[] => {
  return c.conditions
}

export const conditionSet = (op: 'or' | 'and', conditions: (Condition | ConditionSet)[]): ConditionSet => {
  return { combinator: op, conditions } as ConditionSet
}

// type T = ['and' | 'or', Array<(Condition | ConditionSet)>]
const initialQuery: RuleGroupType = {
  combinator: 'and',
  rules: [
    {
      field: 'clicks',
      operator: '>',
      value: 5,
    },
    {
      field: 'impressions',
      operator: '>',
      value: 10,
    },
  ],
}

export const validator = (r: RuleType) => !!r.value && r.value >= 0

const compareOps = defaultOperators.filter(o => o.name === '<' || o.name === '>')

const fields = (
  [
    { name: 'clicks', label: 'Clicks', operators: compareOps, defaultValue: 1, validator, defaultOperator: '<' },
    {
      name: 'conversion',
      label: 'Conversions',
      operators: compareOps,
      defaultValue: 1,
      validator,
      defaultOperator: '<',
    },
    { name: 'cost', label: 'Cost', operators: compareOps, defaultValue: 1, validator, defaultOperator: '<' },
    { name: 'ecpm', label: 'ECPM', operators: compareOps, defaultValue: 1, validator, defaultOperator: '<' },
    { name: 'roi', label: 'ROI', operators: compareOps, defaultValue: 1, validator, defaultOperator: '<' },
  ] satisfies Field[]
).map(o => toFullOption(o))

export const ConditionSetForm: FC<{
  condition?: ConditionSet
  onConditionSetChange: (r: ConditionSet) => void
}> = ({ condition, onConditionSetChange }) => {
  const [query, setQuery] = useState(condition ? conditionSetToQuery(condition) : initialQuery)
  return (
    <Col span={12} style={{ gap: '10px' }}>
      <QueryBuilderAntD style={{ width: 'auto' }}>
        <QueryBuilder
          fields={fields}
          query={query}
          onQueryChange={q => {
            setQuery(q)
            onConditionSetChange(queryToConditionSet(q))
          }}
          controlElements={{
            addGroupAction: props => (props.level === 0 ? <AntDActionElement {...props} /> : null),
          }}
          showShiftActions={true}
          showCombinatorsBetweenRules={true}
        />
      </QueryBuilderAntD>
    </Col>
  )
}
