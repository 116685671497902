import { ErrorCode, ErrorCodeToMessage, HTTPStatusCodes } from '@pushflow/backend-http-contract'

interface HttpError {
  status: HTTPStatusCodes
  body: { message?: string; requestId?: string; errorCode?: ErrorCode }
}

interface HttpErrorPlainMessage {
  status: HTTPStatusCodes
  body: string
}

export { HttpError, HttpErrorPlainMessage, ErrorCode, ErrorCodeToMessage, HTTPStatusCodes }
