import { FieldTimeOutlined } from '@ant-design/icons'
import { FrequencyType } from '@pushflow/backend-http-contract'
import { Input, Radio, Row } from 'antd'
import React, { FC, useState } from 'react'

export const FrequencyForm: FC<{
  frequencyType: FrequencyType
  frequency: number
  onChange: (ft: FrequencyType, f: number) => void
}> = ({ frequency: f, frequencyType: ft, onChange }) => {
  const [frequency, setFrequency] = useState(f)
  const [frequencyType, setFrequencyType] = useState(ft)

  return (
    <Row align={'middle'} style={{ gap: '1rem' }}>
      <Radio.Group
        defaultValue="no"
        buttonStyle="solid"
        value={frequencyType}
        onChange={e => {
          let f = 0
          if (e.target.value === 'every') {
            f = 1
          }
          setFrequencyType(e.target.value)
          setFrequency(f)
          onChange(e.target.value, f)
        }}
      >
        <Radio.Button value="at">Every day at</Radio.Button>
        <Radio.Button value="every">Every</Radio.Button>
      </Radio.Group>
      {frequencyType === 'at' && (
        <Row align={'middle'} style={{ gap: '1rem' }}>
          <Input
            placeholder="Enter time"
            style={{ width: '100px' }}
            type="number"
            value={frequency}
            onChange={e => {
              let newVal = parseInt(e.target.value)
              if (isNaN(parseInt(e.target.value)) || parseInt(e.target.value) < 0) {
                newVal = 0
              }
              if (parseInt(e.target.value) > 23) {
                newVal = 23
              }
              setFrequency(newVal)
              onChange(frequencyType, newVal)
            }}
          />
          (<FieldTimeOutlined /> {frequency < 10 ? `0${frequency}` : frequency}:00 o'clock)
        </Row>
      )}
      {frequencyType === 'every' && (
        <Row align={'middle'} style={{ gap: '1rem' }}>
          <Input
            required
            placeholder="hours"
            style={{ width: '100px' }}
            type="number"
            step={1}
            value={frequency}
            onChange={e => {
              let newVal = parseInt(e.target.value)
              if (isNaN(parseInt(e.target.value)) || (frequencyType === 'every' && parseInt(e.target.value) < 1)) {
                newVal = 1
              }
              if (parseInt(e.target.value) > 23) {
                newVal = 23
              }

              setFrequency(newVal)
              onChange(frequencyType, newVal)
            }}
          />
          hours
        </Row>
      )}
    </Row>
  )
}
