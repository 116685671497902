import { ConfigProvider } from 'antd'
import React, { Suspense } from 'react'
import { ApolloProvider } from '@apollo/client'
import { createRoot } from 'react-dom/client'
import AppRoutes from './Routes.jsx'
import { getToken } from './helpers/jwtHelper'
import enUS from 'antd/es/locale/en_US'
import ruRU from 'antd/es/locale/ru_RU'
import Cookies from 'js-cookie'
import client from './graphql/client'
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary'
import TagManager from 'react-gtm-module'
import config from './config'
import './i18/i18'
import './styles/index.sass'
import 'video-react/dist/video-react.css'
// Sentry
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import Loading from './components/Loading'
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import showNotification from './helpers/showNotification.js'
import { ErrorCode, ErrorCodeToMessage, HttpError, HttpErrorPlainMessage } from './types/error.type.js'

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    autoSessionTracking: true,
    tracesSampleRate: 1.0,
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    release: process.env.REACT_APP_SOURCE_VERSION,
    dsn: config.sentryDsn,
  })
}

const token = getToken()
const lang = Cookies.get('lang')

let locale

if (lang === 'ru') {
  locale = ruRU
} else {
  locale = enUS
}

if (!config.isDev) {
  TagManager.initialize({
    gtmId: 'GTM-5ZZCBLK',
  })
}

// Change user id if an admin
const urlParams = new URLSearchParams(window.location.search)
const urlUid = urlParams.get('uid')
if (urlUid) {
  const inOneHour = new Date(new Date().getTime() + 60 * 60 * 1000)
  Cookies.set('uid', urlUid, { expires: inOneHour, sameSite: 'Lax' })
}

const onRequestError = (err: HttpError | HttpErrorPlainMessage | Error) => {
  const defaultMessage = ErrorCode.UNKNOWN_ERROR

  if (!('body' in err)) {
    showNotification({ type: 'error', message: err.message ?? defaultMessage })
    return
  }

  if (typeof err.body === 'string') {
    showNotification({ type: 'error', message: err.body })
    return
  }

  const { status, body } = err as HttpError
  const { requestId, errorCode, message = defaultMessage } = body

  if (status === 500) {
    const description = requestId ? `Request id: ${requestId}` : ''
    showNotification({ type: 'error', message, description })

    return
  }

  if (status === 400) {
    if (errorCode) {
      const { type, message } = ErrorCodeToMessage[errorCode]
      showNotification({ type, message })

      return
    }

    showNotification({ type: 'warning', message, description: 'Some fields are invalid' })
  }
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (err, query) => {
      if (query.queryHash === '["me"]') {
        return
      }

      onRequestError(err)
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, _variables, _ctx, mutation) => {
      if (mutation.options.onError) return

      onRequestError(err)
    },
  }),
  defaultOptions: {
    queries: {
      retry: false,
    },
    mutations: {
      retry: false,
    },
  },
})

const container = document.getElementById('root')
if (container) {
  const root = createRoot(container)

  root.render(
    <Suspense fallback={<Loading />}>
      <ErrorBoundary>
        <ConfigProvider locale={locale}>
          <ApolloProvider client={client}>
            <QueryClientProvider client={queryClient}>
              <AppRoutes token={token} lang={lang || 'en'} />
            </QueryClientProvider>
          </ApolloProvider>
        </ConfigProvider>
      </ErrorBoundary>
    </Suspense>
  )
}
