import { PauseCircleOutlined, PlayCircleOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import React, { FC, useState } from 'react'
import { useAppState } from '../../../context/AppContext'
import showNotification from '../../../helpers/showNotification'

export const ToggleTrafficButton: FC<{ danger?: boolean }> = ({ danger = false }) => {
  const { refetchUser, apiClient, user } = useAppState()
  const [loading, setLoading] = useState(false)

  if (!user) {
    return null
  }

  const { isTrafficEnabled } = user

  const toggleTraffic = async () => {
    setLoading(true)

    const { status, body } = await apiClient.user.toggleUserTraffic.mutation({
      body: { isTrafficEnabled: !isTrafficEnabled },
    })

    refetchUser()

    if (status === 400) {
      showNotification({ type: 'error', message: body.message ?? 'Error' })
    }
    setLoading(false)
  }

  const icon = isTrafficEnabled ? <PauseCircleOutlined /> : <PlayCircleOutlined />
  const text = isTrafficEnabled ? 'Suspend traffic' : 'Resume traffic'

  return (
    <Button loading={loading} icon={icon} onClick={toggleTraffic} danger={danger}>
      {text}
    </Button>
  )
}
