import { Col, Form, Input, Row } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Submit from '../../components/forms/Submit/Submit'
import Title from '../../components/layout/Title/Title'
import showNotification from '../../helpers/showNotification'
import { useAppState } from '../../context/AppContext'
import { validatePasswordComplexity } from '../signup/signup.page'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

export const ResetPasswordPage: FC = () => {
  const { apiClient } = useAppState()
  const navigate = useNavigate()

  const [form] = Form.useForm()

  const [lockCodeField, setLockCodeField] = useState<boolean>(false)

  const { token } = useParams()

  const { mutateAsync: createNewPassword } = apiClient.user.resetPassword.useMutation()

  const onFinish = async (values: { password: string; passwordRepeat: string }) => {
    const code = form.getFieldValue('code')
    if (values.password !== values.passwordRepeat) {
      showNotification({ type: 'error', message: 'Passwords do not match' })
      return
    }
    if (!code) {
      showNotification({ type: 'error', message: 'Code is missing' })
      return
    }
    const result = await createNewPassword({
      body: { newPassword: values.password, code },
    })

    if (result.status !== 200) {
      showNotification({ type: 'error', message: 'Failed to update your password' })
      return
    }
    showNotification({ type: 'success', message: 'Password has been updated' })

    navigate('/login')
  }

  useEffect(() => {
    if (token) {
      form.setFieldsValue({ code: token })
      setLockCodeField(true)
    }
  }, [token])

  return (
    <>
      <Form onFinish={onFinish} form={form} {...layout}>
        <Row justify="center">
          <Form.Item>
            <Title level={3} isSeparator={false}>
              Reset&nbsp;password
            </Title>
          </Form.Item>
        </Row>

        <Row justify="center">
          <Col span={12}>
            <Form.Item name="code" label="Reset code" required>
              <Input disabled={lockCodeField} />
            </Form.Item>
            <Form.Item
              name="password"
              label="Password"
              required
              rules={[
                { required: true, validator: validatePasswordComplexity },
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              name="passwordRepeat"
              label="Repeat password"
              required
              rules={[
                {
                  required: true,
                  validator: validatePasswordComplexity,
                },
                {
                  required: true,
                  message: 'The two passwords that you entered do not match!',
                  validator: (_, value) => {
                    if (!value || form.getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject()
                  },
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row justify="center">
          <Submit id="check-token-submit" label="Reset password" />
        </Row>
        <Row justify="center">
          <Form.Item>
            <Link to="/login">Back&nbsp;to&nbsp;Login</Link>
          </Form.Item>
        </Row>
      </Form>
    </>
  )
}
