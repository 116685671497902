/* eslint-disable react/display-name */
import { Form, Input as AntInput, InputNumber, FormItemProps } from 'antd'
import React, { forwardRef } from 'react'
import './Input.sass'
import LabelWithTooltip from '../LabelWithTooltip/LabelWithTooltip'
import cn from 'classnames'

interface InputProps extends Omit<FormItemProps, 'label'> {
  label?: string
  name: string
  isRequired?: boolean
  rules?: FormItemProps['rules']
  validators?: FormItemProps['rules']
  multiLineLabel?: boolean
  isNumber?: boolean
  step?: number
  precision?: number
  type?: 'string' | 'number'
  labelTooltip?: string
  description?: string
  placeholder?: string
  disabled?: boolean
  max?: number
  min?: number
  className?: string
  defaultValue?: string | number
  width?: number
  onChange?: (value: any) => void
}

const Input: React.ForwardRefExoticComponent<InputProps & React.RefAttributes<HTMLDivElement>> = forwardRef(
  (
    {
      label,
      name,
      isRequired = false,
      rules,
      validators,
      multiLineLabel = false,
      isNumber,
      step,
      precision,
      type = 'string',
      labelTooltip,
      description: extra,
      placeholder,
      disabled = false,
      max,
      min = 0,
      className,
      defaultValue,
      width,
      onChange,
      ...props
    },
    ref
  ) => {
    const style: React.CSSProperties = {}

    if (width) {
      style['width'] = `${width}px`
    }

    function renderInput() {
      if (isNumber) {
        return (
          <InputNumber
            placeholder={placeholder || label}
            min={min}
            max={max}
            step={step}
            precision={precision}
            defaultValue={defaultValue}
            disabled={disabled}
            style={style}
            onChange={onChange}
          />
        )
        // } else if (name.includes('password')) { // [Pp]assword
      } else if (name.match(/password/i)) {
        return <AntInput.Password placeholder={placeholder || label} disabled={disabled} onChange={onChange} />
      } else {
        return (
          <AntInput
            ref={ref as any}
            placeholder={placeholder || label}
            defaultValue={defaultValue}
            disabled={disabled}
            onChange={onChange}
          />
        )
      }
    }

    return (
      <>
        <Form.Item
          extra={extra}
          label={labelTooltip ? <LabelWithTooltip label={label || ''} labelTooltip={labelTooltip} /> : label}
          name={name}
          rules={[
            {
              required: isRequired,
              type: isNumber ? 'number' : type,
              ...rules,
            },
            ...(validators || []),
          ]}
          className={cn({
            ...(className ? { [className]: !!className } : {}),
            ['multi-line-label']: !!multiLineLabel,
          })}
          {...props}
          style={{
            ...props.style,
            ...style,
          }}
        >
          {renderInput()}
        </Form.Item>
      </>
    )
  }
)

export default Input
