import React, { FC } from 'react'
import { RuleCreate } from './components/rule-create'
import { PageHeader } from 'antd'

export const RuleCreatePage: FC = () => {
  return (
    <>
      <PageHeader title="Create rule" />
      <RuleCreate />
    </>
  )
}
