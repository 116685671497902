import { FallOutlined, FileAddOutlined, RiseOutlined, StopOutlined } from '@ant-design/icons'
import { ResultAction, ResultActionTargetId, RuleTarget } from '@pushflow/backend-http-contract'
import { Row, Tag } from 'antd'
import React, { FC } from 'react'

export const resultActionColorMap: Record<ResultAction, string> = {
  stop: 'red',
  increase_bid: 'green',
  decrease_bid: 'orange',
  add_to_list: 'blue',
}

export const resultActionNameMap: Record<ResultAction, string> = {
  stop: 'Stop',
  increase_bid: 'Increase bid',
  decrease_bid: 'Decrease bid',
  add_to_list: 'Add to list',
}

export const ruleTargetColorMap: Record<RuleTarget, string> = {
  campaign: 'blue',
  campaign_stream: 'coral',
  campaign_stream_substream: 'purple',
}

export const ruleTargetNameMap: Record<RuleTarget, string> = {
  campaign: 'Campaign',
  campaign_stream: 'Campaign stream',
  campaign_stream_substream: 'Campaign stream substream',
}

export const ResultActionView: FC<{ ruleTarget?: RuleTarget; resultAction: ResultAction }> = ({
  ruleTarget,
  resultAction,
}) => {
  let Icon = <></>
  if (resultAction === 'stop') {
    Icon = <StopOutlined />
  }
  if (resultAction === 'increase_bid') {
    Icon = <RiseOutlined />
  }
  if (resultAction === 'decrease_bid') {
    Icon = <FallOutlined />
  }
  if (resultAction === 'add_to_list') {
    Icon = <FileAddOutlined />
  }
  return (
    <>
      {ruleTarget && resultAction === 'add_to_list' && (
        <>
          <Tag style={{ borderRadius: '10px' }} color={ruleTargetColorMap[ruleTarget]}>
            {ruleTargetNameMap[ruleTarget].toUpperCase()}
          </Tag>
        </>
      )}
      <Tag icon={Icon} color={resultActionColorMap[resultAction]}>
        {resultActionNameMap[resultAction].toUpperCase()}
      </Tag>
      {resultAction !== 'add_to_list' ? (
        ruleTarget && (
          <>
            {resultAction === 'decrease_bid' || resultAction === 'increase_bid' ? 'of ' : ''}
            <Tag style={{ borderRadius: '10px' }} color={ruleTargetColorMap[ruleTarget]}>
              {ruleTargetNameMap[ruleTarget].toUpperCase()}
            </Tag>
          </>
        )
      ) : (
        <></>
      )}
    </>
  )
}

export const ResultTargetIdView: FC<{
  ruleTarget: RuleTarget
  resultAction: ResultAction
  resultActionTargetId: ResultActionTargetId
}> = ({ ruleTarget, resultAction, resultActionTargetId }) => {
  return (
    <>
      {resultAction === 'increase_bid' && (
        <span>
          <Tag>by: {resultActionTargetId?.changeBidObj?.amount}%</Tag> of {ruleTargetNameMap[ruleTarget]} price
          <Tag>limit: {resultActionTargetId?.changeBidObj?.limit}%</Tag> of campaign price
        </span>
      )}
      {resultAction === 'decrease_bid' && (
        <span>
          <Tag>by: {resultActionTargetId?.changeBidObj?.amount}%</Tag>
          <Tag>limit: {resultActionTargetId?.changeBidObj?.limit}%</Tag> of campaign price
        </span>
      )}
      {resultAction === 'add_to_list' && (
        <span>
          with ID: <Tag>{resultActionTargetId?.listId}</Tag>
        </span>
      )}
    </>
  )
}

export const ActionOverview: FC<{
  ruleTarget: RuleTarget
  resultAction: ResultAction
  resultActionTarget: ResultActionTargetId
  onClick?: () => void
}> = ({ ruleTarget, resultAction, resultActionTarget, onClick }) => {
  return (
    <div
      style={{
        margin: '10px',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: '100%',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        gap: '1rem',
      }}
    >
      <Row onClick={() => onClick && onClick()} style={{ gap: '1rem' }}>
        <ResultActionView ruleTarget={ruleTarget} resultAction={resultAction} />
        <ResultTargetIdView
          ruleTarget={ruleTarget}
          resultAction={resultAction}
          resultActionTargetId={resultActionTarget}
        />
      </Row>
    </div>
  )
}
