import { CampaignStatus } from '@pushflow/backend-http-contract'
import { useQueryClient } from '@tanstack/react-query'
import React, { FC } from 'react'
import showNotification from '../../../helpers/showNotification'
import { PauseCircleTwoTone, PlayCircleTwoTone } from '@ant-design/icons'
import { useAppState } from '../../../context/AppContext'

export const ChangeStatusIcon: FC<{
  id: number
  status: CampaignStatus
}> = ({ id, status }) => {
  const queryClient = useQueryClient()

  const { apiClient } = useAppState()

  const changeStatusMutation = apiClient.campaign.changeCampaignStatus.useMutation({
    onSuccess() {
      queryClient.invalidateQueries({ queryKey: ['campaigns'] })

      showNotification({
        type: 'success',
        message: `Campaign ${
          status === CampaignStatus.WORKING ? CampaignStatus.PAUSED.toLowerCase() : CampaignStatus.WORKING.toLowerCase()
        }`,
      })
    },
  })

  const updateStatus = () => {
    changeStatusMutation.mutate({
      body: {
        status: status === CampaignStatus.WORKING ? CampaignStatus.PAUSED : CampaignStatus.WORKING,
      },
      params: {
        campaignId: id,
      },
    })
  }
  return status === CampaignStatus.WORKING ? (
    <PauseCircleTwoTone onClick={() => updateStatus()} twoToneColor="orange" />
  ) : (
    <PlayCircleTwoTone onClick={() => updateStatus()} />
  )
}
