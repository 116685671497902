import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { paginationRequest } from './common/pagination';
import { ErrorCode } from './common/error.enum';
import { RuleSchema } from './rule';
import { CommonResponse } from './common/common-response';

const c = initContract();

export const CampaignIdSchema = z.object({
  ruleId: z.number(),
  campaignId: z.number(),
});

export type CampaignId = z.infer<typeof CampaignIdSchema>;

export const StreamIdSchema = z.object({
  ruleId: z.number(),
  campaignId: z.number(),
  streamUid: z.string(),
});

export type StreamId = z.infer<typeof StreamIdSchema>;

export const SubstreamIdSchema = z.object({
  ruleId: z.number(),
  campaignId: z.number(),
  streamUid: z.string(),
  substream: z.string(),
});
export const SubstreamIdSchema1 = z.object({
  ruleId: z.number(),
  campaignId: z.number(),
  streamId: z.number().optional(),
  substream: z.string().optional(),
});

export type SubstreamId = z.infer<typeof SubstreamIdSchema>;

export const MatchedEntriesResultSchema = z.union([
  SubstreamIdSchema,
  StreamIdSchema,
  CampaignIdSchema,
]);

export type MatchedEntriesResult = z.infer<typeof MatchedEntriesResultSchema>;

export const RuleLogSchema = z.object({
  ruleId: z.number(),
  ruleRaw: RuleSchema,
  matchedEntries: z.array(SubstreamIdSchema1),
});

export type RuleLog = z.infer<typeof RuleLogSchema>;

export const FullRuleLogSchema = RuleLogSchema.merge(
  z.object({
    id: z.number(),
    createdAt: z.string().datetime(),
  }),
);

export type FullRuleLog = z.infer<typeof FullRuleLogSchema>;

export const ruleLog = c.router({
  getRuleLogs: {
    method: 'GET',
    path: '/rule/getRuleLogs',
    query: z.object({
      ruleIds: z.coerce.number().array(),
      pagination: paginationRequest,
    }),
    responses: {
      ...CommonResponse([ErrorCode.RULE_NOT_FOUND]),
      200: z.object({ total: z.number(), items: FullRuleLogSchema.array() }),
    },
  },
});
