import { z } from 'zod';
import { ErrorCode } from './error.enum';

export const FiveHundreedResponseSchema = z.object({
  message: z.string(),
});

export const CommonResponse = (errs: [ErrorCode, ...ErrorCode[]]) => {
  return {
    400: z.object({
      errorCode: z.enum(errs),
      message: z.string().optional(),
    }),
    500: FiveHundreedResponseSchema,
  };
};
