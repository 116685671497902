// @ts-ignore
import { canManageUsers, isAdmin } from '@pushflow/server/src/helpers/featureFlags'
import { Alert } from 'antd'
import 'antd/dist/antd.min.css'
import React, { FC, useEffect, useState } from 'react'
import { Navigate, Route, BrowserRouter as Router, Routes, useParams } from 'react-router-dom'
import { TelegramIcon } from './assets/icons'
import { history } from './common/history.js'
import Loading from './components/Loading'
import Logout from './components/Logout/Logout'
import AppLayout from './components/layout/AppLayout/AppLayout'
import { AUTH_TOKEN, DEFAULT_TITLE } from './constant'
import EmailConfirmation from './containers/EmailConfirmation'
import { ApiClient, AppContext, IUser } from './context/AppContext'
import useLang from './hooks/useLang'
import { LoginPage } from './pages/login/login.page'
import { OptionsPage } from './pages/user-settings/options.page'
import { ResetPasswordPage } from './pages/reset-password/reset-password-page'
import { RequestResetPasswordPage } from './pages/reset-password/request-reset-password-page'
import { SignupPage } from './pages/signup/signup.page'

import { UserInfoPage } from './pages/admin/users/user-info.page'
import { UserListPage } from './pages/admin/users/user-list.page'
import CreateCampaignPage from './pages/push/CampaignCreatePage'
import CampaignSettingsPage from './pages/campaign-settings/campaign-settings.page'
import { CampaignsListPage } from './pages/campaign-list/campaign-list.page'
import { CreativesListPage } from './pages/creative/creative-list.page'
import { DashboardPage } from './pages/dashboard/dashboard.page'
import StreamsListPage from './pages/stream'

import ReportPage from './pages/ReportPage'
import DomainsListPage from './pages/admin/domains/DomainsListPage'
import DomainsEditPage from './pages/admin/domains/DomainsEditPage'
import DomainsCreatePage from './pages/admin/domains/DomainsCreatePage'
import BlackWhiteListsListPage from './pages/push/BlackWhiteLists/BlackWhiteListsPage'
import { InventoryPageOld } from './pages/InventoryPage'
import BlackWhiteListCreatePage from './pages/push/BlackWhiteLists/BlackWhiteListCreatePage'
import BlackWhiteListEditPage from './pages/push/BlackWhiteLists/BlackWhiteListEditPage'
import { CampaignRulePage } from './pages/push/CampaignRules/CampaignRulePage'
import { CampaignRulesList } from './pages/push/CampaignRules/CampaignRulesList'
import { CampaignRuleCreatePage } from './pages/push/CampaignRules/CampaignRuleCreatePage'
import TargetsEditPage from './pages/admin/targets/EditPage'
import { createApiClient } from './api/http-client'
import { StreamsEditPage } from './pages/stream/stream-edit.page'
import { StreamsCreatePage } from './pages/stream/stream-create.page'

import { CreativeModerationPage } from './pages/admin/creative-moderation/creative-moderation.page'

import { AdminUserEditPage } from './pages/admin/users/edit-user.page'
import { AdminUserNewPage } from './pages/admin/users/create-user.page'
import UserAuditoriesListPage from './pages/user-auditory/user-auditory-list.page'
import UserAuditoryCreatePage from './pages/user-auditory/user-auditory-create.page'
import UserAuditoryEditPage from './pages/user-auditory/user-auditory-edit.page'
import { ScheduleTaskPage } from './pages/admin/schedule-task/schedule-task.page'
import { RuleListPage } from './pages/rule/rule-list.page'
import { RuleCreatePage } from './pages/rule/rule-create.page'
import { RuleEditPage } from './pages/rule/rule-edit.page'

const Private: FC<{ user: IUser; Component: any; title: string; updateToken?: (token: string) => void }> = props => {
  const user = props.user
  const Component = props.Component
  const params = useParams()

  useEffect(() => {
    if (props.title) {
      document.title = `${props.title}`
    }

    return () => {
      document.title = DEFAULT_TITLE
    }
  }, [props])

  if (user && user.email) {
    return (
      <>
        <a href="https://t.body/pushflow_net" target="__blank">
          <TelegramIcon
            className="icons__telegram"
            // @ts-ignore
            height="3rem"
            width="3rem"
          />
        </a>
        <Component params={params} history={history} {...props} />
      </>
    )
  }

  return <Navigate to="/login" />
}

const Permitted: FC<{ user: IUser; isPermitted: () => boolean; Component: any; title: string }> = props => {
  const user = props.user
  const isPermitted = props.isPermitted
  const Component = props.Component
  const params = useParams()

  if (!user) {
    return <Navigate to="/login" />
  }

  if (!isPermitted()) {
    return <Alert message="You are not permitted to view this page" type="error" />
  }

  useEffect(() => {
    if (props.title) {
      document.title = `${props.title}`
    }

    return () => {
      document.title = DEFAULT_TITLE
    }
  }, [props])

  return <Component params={params} history={history} {...props} />
}

const AppRoutes: FC<{ token: string | null; lang: string | null }> = ({ token: localToken, lang: initLang }) => {
  const [token, setToken] = useState(localToken)
  const [user, setUser] = useState<null | IUser>(null)
  const [statisticDays, setStatisticDays] = useState(14)

  const { lang, updateLang } = useLang(initLang)
  const [apiClient, setApiClient] = useState<ApiClient>(createApiClient(token))
  const { isLoading, data, refetch } = apiClient.user.getMe.useQuery(['me'], undefined, {
    queryKey: ['me'],
    refetchOnWindowFocus: false,
  })

  const updateToken = (newToken: string | null) => {
    if (newToken) {
      localStorage.setItem(AUTH_TOKEN, newToken)
      setToken(newToken)
      refetch()
    } else {
      setUser(null)
      localStorage.removeItem(AUTH_TOKEN)
      refetch()
    }

    const apiClient = createApiClient(newToken)

    setApiClient(apiClient)
  }

  useEffect(() => {
    updateToken(token)
  }, [refetch, setToken, token])

  useEffect(() => {
    if (!isLoading && data && data.body && data.body.email) {
      setUser(data.body)
    }
  }, [isLoading, data])

  const clearToken = () => {
    localStorage.setItem(AUTH_TOKEN, '')
    setToken('')
  }

  if (isLoading && !user) {
    return <Loading />
  }

  function renderRoute(data: { body: IUser }) {
    return (
      <Routes>
        <Route path="/" element={<Private user={data.body} Component={DashboardPage} title="Dashboard" />} />
        <Route path="/report" element={<Private user={data.body} Component={ReportPage} title="Report" />} />
        {/* CAMPAIGN */}
        <Route
          path="/campaign"
          element={<Private user={data.body} Component={CampaignsListPage} title="Campaigns" />}
        />
        <Route
          path="/campaign/create"
          element={
            <Private user={data.body} Component={CreateCampaignPage.Variants.AsyncMemoized} title="Create Campaign" />
          }
        />
        <Route
          path="/campaign/:id"
          element={<Private user={data.body} Component={CampaignSettingsPage} title="Campaign Settings" />}
        />
        <Route
          path="/creatives"
          element={<Private user={data.body} Component={CreativesListPage} title="Creatives" />}
        />
        <Route
          path="/rules/:campaignId"
          element={<Private user={data.body} Component={CampaignRulesList} title="Campaign Rules" />}
        />
        <Route
          path="/rules/:campaignId/:id"
          element={<Private user={data.body} Component={CampaignRulePage} title="Campaign Rule" />}
        />
        <Route
          path="/rules/:campaignId/create"
          element={<Private user={data.body} Component={CampaignRuleCreatePage} title="Create Rule" />}
        />
        <Route
          path="/automations"
          element={<Private user={data.body} Component={RuleListPage} title="Automation Rules" />}
        />
        <Route
          path="/automations/:id"
          element={<Private user={data.body} Component={RuleEditPage} title="Edit Automation Rule" />}
        />
        <Route
          path="/automations/create"
          element={<Private user={data.body} Component={RuleCreatePage} title="Create Automation Rule" />}
        />
        <Route
          path="/subscriptionlist/create"
          element={
            <Private user={data.body} Component={BlackWhiteListCreatePage} title="Create Black or White Lists" />
          }
        />
        <Route
          path="/subscriptionlist"
          element={<Private user={data.body} Component={BlackWhiteListsListPage} title="Black And White Lists" />}
        />
        <Route
          path="/subscriptionlist/:id"
          element={<Private user={data.body} Component={BlackWhiteListEditPage} title="Black/White Lists" />}
        />

        <Route
          path="/userauditories"
          element={<Private user={data.body} Component={UserAuditoriesListPage} title="User Auditories" />}
        />
        <Route
          path="/userauditory/create"
          element={<Private user={data.body} Component={UserAuditoryCreatePage} title="Create User Auditory" />}
        />
        <Route
          path="/userauditory/:id"
          element={<Private user={data.body} Component={UserAuditoryEditPage} title="User Auditory" />}
        />

        <Route path="/streams" element={<Private user={data.body} Component={StreamsListPage} title="Streams" />} />
        <Route
          path="/streams/create"
          element={<Private user={data.body} Component={StreamsCreatePage} title="Create Stream" />}
        />
        <Route
          path="/streams/:id"
          element={<Private user={data.body} Component={StreamsEditPage} title="Edit Stream" />}
        />

        <Route path="/invetory" element={<Private user={data.body} Component={InventoryPageOld} title="Inventory" />} />

        <Route
          path="/admin/users"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => canManageUsers(data.body)}
              Component={UserListPage}
              title="Users"
            />
          }
        />
        <Route
          path="/admin/schedule-task"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => canManageUsers(data.body)}
              Component={ScheduleTaskPage}
              title="ScheduleTaskPage"
            />
          }
        />
        <Route
          path="/admin/creative-moderation"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => canManageUsers(data.body)}
              Component={CreativeModerationPage}
              title="Creative moderation"
            />
          }
        />
        <Route
          path="/admin/users/new"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => canManageUsers(data.body)}
              Component={AdminUserNewPage}
              title="Create User"
            />
          }
        />
        <Route
          path="/admin/users/:id"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => canManageUsers(data.body)}
              Component={UserInfoPage}
              title="User Page"
            />
          }
        />
        <Route
          path="/admin/users/:id/edit"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => canManageUsers(data.body)}
              Component={AdminUserEditPage}
              title="Edit User"
            />
          }
        />

        <Route
          path="/admin/domains"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => isAdmin(data.body)}
              Component={DomainsListPage}
              title="Domains"
            />
          }
        />
        <Route
          path="/admin/domains/create"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => isAdmin(data.body)}
              Component={DomainsCreatePage}
              title="Create Domain"
            />
          }
        />
        <Route
          path="/admin/domains/:id"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => isAdmin(data.body)}
              Component={DomainsEditPage}
              title="Edit Domain"
            />
          }
        />

        <Route
          path="/admin/targets"
          element={
            <Permitted
              user={data.body}
              isPermitted={() => isAdmin(data.body)}
              Component={TargetsEditPage}
              title="Targets"
            />
          }
        />

        <Route
          path="/options"
          element={<Private user={data.body} updateToken={updateToken} Component={OptionsPage} title="Options" />}
        />
        <Route path="/login" element={user ? <Navigate to="/" /> : <LoginPage updateToken={updateToken} />} />
        <Route path="/signup" element={user ? <Navigate to="/" /> : <SignupPage updateToken={updateToken} />} />
        <Route path="/requestresetpassword" element={user ? <Navigate to="/" /> : <RequestResetPasswordPage />} />
        <Route path="/resetpassword/:token" element={<ResetPasswordPage />} />
        <Route path="/resetpassword/" element={<ResetPasswordPage />} />
        <Route path="/emailconfirm/:token" element={<EmailConfirmation />} />
        <Route path="/logout" element={<Logout clearToken={clearToken} />} />
      </Routes>
    )
  }

  return (
    <Router basename="/app">
      <AppContext.Provider
        value={{
          token,
          setToken,
          user,
          lang,
          updateLang,
          refetchUser: refetch,
          statisticDays,
          setStatisticDays,
          apiClient: apiClient!,
        }}
      >
        <AppLayout>{renderRoute(data || { body: {} as any })}</AppLayout>
      </AppContext.Provider>
    </Router>
  )
}

export default AppRoutes
