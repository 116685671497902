import React, { FC, useEffect, useState } from 'react'
import { useAppState } from '../../context/AppContext'
import { Col, Radio, Row, Select, Table, TableColumnType, Tabs } from 'antd'
import { CampaignType, InventoryData, UserRole } from '@pushflow/backend-http-contract'
import { CountryFlag } from '../../country-flags/country-flag'
import { InventoryOld } from './Inventory.old'
import { Line } from 'react-chartjs-2'
import { getCountryArray, getCountryName } from '../../common/static/countryCodes'

const trafficTypeToLabel: Record<CampaignType, string> = {
  [CampaignType.PUSH]: 'Push',
  [CampaignType.LINK]: 'Link',
  [CampaignType.VIDEO_PRE_ROLL]: 'Video',
}

const countriesArray = getCountryArray()

export const InventoryChart: FC<{
  trafficType: CampaignType
  period: 'DAY' | 'WEEK'
  cpcData?: { minCpc: number[]; avgCpc: number[]; maxCpc: number[]; datehour: string[] }
  country: string | null
}> = ({ trafficType, period, cpcData, country }) => {
  cpcData = cpcData || { minCpc: [], avgCpc: [], maxCpc: [], datehour: [] }
  const max = Math.max(...[...cpcData.maxCpc, 0]) + 0.1
  return (
    <Row gutter={2}>
      <Col span={2}></Col>
      <Col span={18}>
        <Line
          height={'300px'}
          options={{
            responsive: true,
            interaction: {
              mode: 'index' as const,
              intersect: false,
            },
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: `${trafficTypeToLabel[trafficType]} Traffic chart (${period === 'DAY' ? '24 hours' : '7 days'})${
                  !!country ? ` | ${getCountryName(country?.toLowerCase())}` : ''
                }`,
              },
              subtitle: {
                display: true,
                text: period === 'DAY' ? 'Hours' : 'Days',
                position: 'bottom',
                padding: {
                  top: 10,
                },
              },
            },
            scales: {
              y: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
                max,
                min: 0,
              },
              x: {
                type: 'category' as const,
                display: false,
                position: 'bottom' as const,
              },
            },
          }}
          data={{
            labels: cpcData.datehour || [],
            datasets: [
              {
                label: 'Max CPC',
                data: cpcData.maxCpc || [],
                borderColor: 'rgba(255, 99, 132, 0.5)',
                backgroundColor: 'rgba(255, 99, 132, 0.5)',
                yAxisID: 'y',
              },
              {
                label: 'Avg CPC',
                data: cpcData.avgCpc || [],
                borderColor: 'rgba(99, 255, 132, 0.5)',
                backgroundColor: 'rgba(99, 255, 132, 0.5)',
                yAxisID: 'y',
              },
              {
                label: 'Min CPC',
                data: cpcData.minCpc || [],
                borderColor: 'rgba(132, 99, 255, 0.5)',
                backgroundColor: 'rgba(132, 99, 255, 0.5)',
                yAxisID: 'y',
              },
            ],
          }}
        />
      </Col>
      <Col span={2}></Col>
    </Row>
  )
}

export const Inventory: FC<{
  trafficType: CampaignType
  isActive: boolean
}> = ({ trafficType, isActive }) => {
  const { apiClient, user } = useAppState()

  const [period, setPeriod] = useState<'DAY' | 'WEEK'>('DAY')
  const [streams, setStreams] = useState<string[]>([])
  const [device, setDevice] = useState<'DESKTOP' | 'MOBILE' | 'ALL'>('ALL')
  const [countries, setCountries] = useState<string[]>([])

  const [needToRefetchCpcData, setNeedToRefetchCpcData] = useState(false)

  const inventoryQueryKey = ['inventory'] as const

  const { data, refetch: refetchInventory } = apiClient.inventory.getInventory.useQuery(
    inventoryQueryKey,
    {
      query: {
        trafficType,
        period,
        streams: streams.length === 0 ? undefined : streams,
        device: device === 'ALL' ? undefined : device,
        countries: countries.length === 0 ? undefined : countries,
      },
      overrideClientOptions: {
        jsonQuery: false,
      },
    },
    {
      queryKey: inventoryQueryKey,
      refetchOnWindowFocus: false,
    }
  )

  const { data: streamData, refetch: refetchStreams } = apiClient.stream.getStreamAutocomplete.useQuery(
    ['stream-autocomplete', trafficType, period, device],
    {
      query: {
        streamType: trafficType,
      },
    }
  )

  const [activeRow, setActiveRow] = useState<InventoryData['country'] | null>(data?.body[0]?.country || null)

  const cpcDataQueryKey = ['cpc', activeRow] as const
  const { data: cpcData, refetch: refetchCpcData } = apiClient.inventory.getCountryCpcStat.useQuery(
    cpcDataQueryKey,
    {
      query: {
        country: activeRow || undefined,
        period,
        trafficType,
        streams: streams.length === 0 ? undefined : streams,
        device: device === 'ALL' ? undefined : device,
      },
      overrideClientOptions: {
        jsonQuery: false,
      },
    },
    {
      queryKey: cpcDataQueryKey,
      refetchOnWindowFocus: false,
    }
  )

  const numberFormatter = Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 2 })

  const columns: TableColumnType<InventoryData>[] = [
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      render: text => (
        <>
          {text.toUpperCase()} - {getCountryName(text.toLowerCase())}
        </>
      ),
      width: 400,
    },
    {
      title: 'Requests',
      dataIndex: 'requests',
      key: 'requests',
      sorter: (a, b) => a.requests - b.requests,
      defaultSortOrder: 'descend',
      render: text => <>{numberFormatter.format(text)}</>,
    },
    {
      title: 'Impressions',
      dataIndex: 'impressions',
      key: 'impressions',
      sorter: (a, b) => a.impressions - b.impressions,
      render: text => <>{numberFormatter.format(text)}</>,
    },
    ...(user && user.roles.includes(UserRole.INTERNAL_ADVERTISER)
      ? [
          {
            title: 'Clicks',
            dataIndex: 'clicks',
            key: 'clicks',
            sorter: (a: any, b: any) => a.clicks - b.clicks,
            render: (text: number) => <>{numberFormatter.format(text)}</>,
          },
        ]
      : []),
    {
      title: 'Min CPC',
      dataIndex: 'minCpc',
      key: 'minCpc',
      sorter: (a, b) => a.minCpc - b.minCpc,
    },
    {
      title: 'Avg CPC',
      dataIndex: 'avgCpc',
      key: 'avgCpc',
      sorter: (a, b) => a.avgCpc - b.avgCpc,
    },
    {
      title: 'Max CPC',
      dataIndex: 'maxCpc',
      key: 'maxCpc',
      sorter: (a, b) => a.maxCpc - b.maxCpc,
    },
    {
      title: 'Avg CTR',
      dataIndex: 'avgCtr',
      key: 'avgCtr',
      render: text => {
        return <>{parseFloat(text.toFixed(4))} %</>
      },
      sorter: (a, b) => a.avgCtr - b.avgCtr,
    },
  ]

  useEffect(() => {
    const firstCountry = data?.body.sort((a, b) => b.requests - a.requests)[0]?.country
    if (!activeRow && firstCountry) {
      setActiveRow(firstCountry)
      return
    }
    if (activeRow && !data?.body.map(i => i.country).includes(activeRow) && firstCountry) {
      setActiveRow(firstCountry)
    }
    if (data?.body.length === 0) {
      setActiveRow(null)
    }
  }, [data])

  useEffect(() => {
    if (needToRefetchCpcData) {
      refetchCpcData()
      setNeedToRefetchCpcData(false)
      return
    }
    if (!activeRow) {
      return
    }
    refetchCpcData()
  }, [activeRow, needToRefetchCpcData])

  useEffect(() => {
    if (!isActive) {
      return
    }
    setActiveRow(null)
    refetchInventory()
    refetchStreams()
    refetchCpcData()
  }, [isActive])

  useEffect(() => {
    if (!isActive) {
      return
    }
    refetchInventory()
    setNeedToRefetchCpcData(true)
  }, [period, streams, device, countries])

  return (
    <Col span={24}>
      <style>
        {`
          .ant-table-thead > tr > th {
            font-weight: bold !important;
          }
        `}
      </style>

      <InventoryChart trafficType={trafficType} period={period} cpcData={cpcData?.body} country={activeRow} />

      <Row gutter={12} style={{ paddingTop: '10px', paddingBottom: '10px' }}>
        <Col span={6}>
          <Select
            mode="multiple"
            style={{ width: '100%' }}
            placeholder="Select countries"
            value={countries}
            onChange={e => {
              setCountries(e)
            }}
            maxTagCount={4}
            filterOption={(input, option) => {
              return (
                option?.label?.toLowerCase().includes(input.toLowerCase()) ||
                option?.value?.toLowerCase().includes(input.toLowerCase()) ||
                false
              )
            }}
            options={countriesArray.map(c => ({ label: `${c.name} / ${c.value}`, value: c.value }))}
            allowClear
          ></Select>
        </Col>
        {user && user.roles.includes(UserRole.INTERNAL_ADVERTISER) && (
          <Col span={6}>
            <Select
              mode="multiple"
              style={{ width: '100%' }}
              placeholder="Select streams"
              value={streams}
              onChange={e => {
                setStreams(e)
              }}
              options={streamData?.body.items.map(stream => ({
                label: `ID: ${stream.id} | ${stream.title}`,
                value: stream.uid,
              }))}
              filterOption={(input, option) => {
                return option?.label?.toLowerCase().includes(input.toLowerCase()) || false
              }}
              allowClear
            />
          </Col>
        )}
        <Col span={6}>
          <Radio.Group value={device} onChange={e => setDevice(e.target.value)} optionType="button" buttonStyle="solid">
            <Radio.Button value="ALL">All</Radio.Button>
            <Radio.Button value="DESKTOP">Desktop</Radio.Button>
            <Radio.Button value="MOBILE">Mobile</Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={6}>
          <Radio.Group value={period} onChange={e => setPeriod(e.target.value)} optionType="button" buttonStyle="solid">
            <Radio.Button value="DAY">Day</Radio.Button>
            <Radio.Button value="WEEK">Week</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Row gutter={2}>
        <Table<InventoryData>
          style={{ width: '100%' }}
          dataSource={data?.body}
          columns={columns}
          rowKey={'country'}
          size={'small'}
          pagination={{ pageSize: 100 }}
          onRow={(record, id) => {
            return {
              onClick: () => {
                setActiveRow(record.country)
              },
              style: {
                cursor: 'pointer',
                fontWeight: activeRow === record.country ? 'bold' : 'normal',
                backgroundColor: id !== undefined && id % 2 === 0 ? '#f5f5f5' : 'transparent',
              },
            }
          }}
          rowSelection={{
            selectedRowKeys: activeRow ? [activeRow] : [],
            hideSelectAll: true,
            type: 'radio',
            getCheckboxProps: () => ({
              style: {
                display: 'none',
              },
            }),
            columnWidth: 0,
            renderCell: (val, rec) => <CountryFlag country={rec.country} />,
          }}
        />
      </Row>
    </Col>
  )
}

export const InventoryPage: FC = () => {
  const { user } = useAppState()
  const [active, setActive] = useState<CampaignType>(CampaignType.PUSH)
  return (
    <Tabs
      defaultActiveKey={CampaignType.PUSH}
      onChange={e => setActive(e as CampaignType)}
      items={[
        ...[CampaignType.PUSH, CampaignType.LINK, CampaignType.VIDEO_PRE_ROLL].map(type => ({
          key: type,
          label: trafficTypeToLabel[type],
          children: <Inventory trafficType={type} isActive={active === type} />,
        })),
        ...(user && user.roles.includes(UserRole.EXTERNAL_ADVERTISER)
          ? []
          : [{ key: 'old', label: 'Old inventory', children: <InventoryOld /> }]),
      ]}
    />
  )
}
