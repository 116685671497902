import { Col, Form, Row } from 'antd'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import Input from '../../components/forms/Input/Input'
import Submit from '../../components/forms/Submit/Submit'
import Title from '../../components/layout/Title/Title'
import showNotification from '../../helpers/showNotification'
import { useAppState } from '../../context/AppContext'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

const tailLayout = {
  wrapperCol: { offset: 6, span: 18 },
}

export const RequestResetPasswordPage = () => {
  const { apiClient } = useAppState()
  const { mutateAsync: ResetPasswordMutation } = apiClient.user.requestResetPassword.useMutation()
  const [email, setEmail] = useState<string>()
  const navigate = useNavigate()

  const { t } = useTranslation()

  const onFinish = async (values: { email: string }) => {
    const result = await ResetPasswordMutation({ body: values })
    if (result.status === 200) {
      showNotification({
        type: 'success',
        message: 'Reset code will be sent if your email will be found in our database.',
      })
      setEmail(values.email)

      navigate('/resetpassword')
    }
  }

  if (email)
    return (
      <Row justify="center">
        <Col span={10}>
          <Title level={3} style={{ marginBottom: 0 }} isSeparator={false}>
            {t('ResetPasswordPage.The password recovery link has been sent to')} {email}.
          </Title>
          <p style={{ marginTop: 16 }}>{t("ResetPasswordPage.If you don't receive the email")}</p>
        </Col>
      </Row>
    )

  return (
    <Row justify="center">
      <Col span={8}>
        <Form {...layout} onFinish={onFinish}>
          <Form.Item {...tailLayout}>
            <Title level={3} style={{ marginBottom: 0 }} isSeparator={false}>
              {t('ResetPasswordPage.Reset title')}
            </Title>
          </Form.Item>
          <Input
            name="email"
            label={t('Common.Email')}
            type="string"
            isRequired
            rules={[{ required: true, message: 'Email is required', type: 'email' }]}
          />
          <Submit id={'reset-pwd-submit'} label="Request password reset" {...tailLayout} />
          <Form.Item {...tailLayout}>
            <Link to="/login">Back to login</Link>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  )
}
