import { z } from 'zod';
import { initContract } from '@ts-rest/core';
import { paginationRequest, paginationResponse } from './common/pagination';
import { CampaignStatusEnum } from './common/campaign-status';
import { CampaignTypeEnum } from './common/campaign-type';
import { ErrorCode } from './common/error.enum';
import { FiveHundreedResponseSchema } from './common/common-response';
const c = initContract();

const CampaignSettingsScheme = z.object({
  campaignType: CampaignTypeEnum,
  title: z.string(),
  url: z.string(),
  tags: z.array(z.string()),
  bidType: z.enum(['CPC', 'CPM']),
  price: z.number(),
  targetCpa: z.number(),
  streams: z.array(z.string()),
  streamsInclude: z.boolean(),
  substreams: z.string(),
  substreamsInclude: z.boolean(),
  whitelists: z.array(z.number()),
  blacklists: z.array(z.number()),
  auditories: z.array(z.number()),
  auditoriesInclude: z.boolean(),
  domains: z.string(),
  domainsInclude: z.boolean(),
  userAgents: z.string(),
  userAgentsInclude: z.boolean(),
  countryCode: z.array(z.string()),
  countryCodeInclude: z.boolean(),
  region: z.array(z.string()),
  regionInclude: z.boolean(),
  lang: z.array(z.string()),
  langInclude: z.boolean(),
  device: z.array(z.number()),
  deviceInclude: z.boolean(),
  os: z.array(z.number()),
  osInclude: z.boolean(),
  osVersion: z.array(z.number()),
  osVersionInclude: z.boolean(),
  browser: z.array(z.number()),
  browserInclude: z.boolean(),
  browserVersion: z.array(z.number()),
  browserVersionInclude: z.boolean(),
  connectionType: z.array(z.string()),
  connectionTypeInclude: z.boolean(),
  isp: z.array(z.string()),
  ispInclude: z.boolean(),
  subscriptionAgeEnabled: z.boolean(),
  subscriptionAgeFrom: z.number(),
  subscriptionAgeTo: z.number(),
  subscriptionAgeUnitsChoice: z.string(),
  maxShownUser: z.number(),
  maxClickedUser: z.number(),
  maxShownUserAmtPeriod: z.number(),
  maxShownUserPeriod: z.number(),
  maxClickedUserAmtPeriod: z.number(),
  maxClickedUserPeriod: z.number(),
  maxDailyBudget: z.number(),
  maxTotalBudget: z.number(),
  maxClickedThreshold: z.number(),
  maxClickedAuditory: z.number(),
  maxShownThreshold: z.number().default(0),
  maxShownAuditory: z.number().default(0),
  scheduleTimezone: z.string(),
  targetDaysAndHours: z.array(z.object({ day: z.number(), hour: z.number() })),
});

const FullCampaignSettingsScheme = CampaignSettingsScheme.merge(
  z.object({
    id: z.number(),
    status: CampaignStatusEnum,
    notificationEnabled: z.boolean(),
    userId: z.number(),
  }),
);

export const campaign = c.router({
  getCampaignSettingValues: {
    method: 'GET',
    path: '/campaign.getCampaignSettingValues',
    responses: {
      200: z.object({
        userAuditories: z.object({ id: z.number(), title: z.string() }).array(),
        streams: z
          .object({ id: z.number(), title: z.string(), userId: z.number() })
          .array(),
      }),
    },
  },

  getCampaignListPaginated: {
    method: 'GET',
    path: '/campaign/getCampaignListPaginated',
    responses: {
      200: z.object({
        items: z
          .object({
            id: z.number(),
            userId: z.number(),
            title: z.string(),
            status: CampaignStatusEnum,
            price: z.number(),
            campaignType: CampaignTypeEnum,
            tags: z.string().array().default([]),
            streams: z.string().array(),
            countryCode: z.string().array(),
            maxDailyBudget: z.number(),
            enabledNotificationType: z.array(
              z.enum(['DAILY_STAT', 'LOW_BUDGET']),
            ),
            spent: z.number().optional(),
            streamsInfo: z.array(
              z.object({
                id: z.number(),
                title: z.string(),
              }),
            ),
            streamIds: z.number().array(),
          })
          .array(),
        filterValues: z.object({
          streamIds: z.number().array(),
          tags: z.string().array(),
          ids: z.number().array(),
          countries: z.string().array(),
          userIds: z.number().array(),
          statuses: CampaignStatusEnum.array(),
        }),
        pagination: paginationResponse,
      }),
    },
    query: z.object({
      filter: z
        .object({
          title: z.string().optional(),
          ids: z.array(z.coerce.number()).optional(),
          userIds: z.array(z.coerce.number()).optional(),
          streamIds: z.array(z.coerce.number()).optional(),
          statuses: CampaignStatusEnum.array().optional(),
          countries: z.array(z.string()).optional(),
          campaignTypes: CampaignTypeEnum.array().optional(),
          spent: z.boolean().optional(),
          search: z.string().optional(),
          tags: z.array(z.string()).optional(),
          enabledNotificationType: z
            .array(z.enum(['DAILY_STAT', 'LOW_BUDGET']))
            .optional(),
        })
        .optional(),
      pagination: paginationRequest,
    }),
  },

  getCampaignStatistics: {
    method: 'GET',
    path: '/campaign/:campaignId/stats',
    pathParams: z.object({
      campaignId: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        bids: z.number(),
        wonBids: z.number(),
        clicks: z.number(),
        cost: z.number(),
        chart: z.array(
          z.object({
            date: z.string(),
            bids: z.number(),
            wonBids: z.number(),
            clicks: z.number(),
            cost: z.number(),
          }),
        ),
      }),
      400: z.null(),
    },
  },

  changeCampaignStatus: {
    method: 'POST',
    path: '/campaign/:campaignId/status',
    pathParams: z.object({
      campaignId: z.coerce.number(),
    }),
    body: z.object({
      status: CampaignStatusEnum,
    }),
    responses: {
      200: z.literal('ok'),
      400: z.object({
        errorCode: z.enum([ErrorCode.CAMPAIGN_WITHOUT_CREATIVES]),
        message: z.string().optional(),
      }),
      403: z.null(),
    },
  },

  archiveCampaign: {
    method: 'DELETE',
    path: '/campaign/:campaignId/archive', // Change later to /campaign/:campaignId
    pathParams: z.object({
      campaignId: z.coerce.number(),
    }),
    body: z.object({}),
    responses: {
      200: z.literal('ok'),
      403: z.null(),
    },
  },

  getCampaignSettings: {
    method: 'GET',
    path: '/campaign/:campaignId/settings',
    pathParams: z.object({
      campaignId: z.coerce.number(),
    }),
    responses: {
      200: FullCampaignSettingsScheme,
      400: z.null(),
      404: z.null(),
    },
  },

  createCampaign: {
    method: 'POST',
    path: '/campaign',
    body: CampaignSettingsScheme,
    responses: {
      200: FullCampaignSettingsScheme,
      400: z.null(),
    },
  },

  updateCampaignSettings: {
    method: 'PUT',
    path: '/campaign/:campaignId/settings',
    pathParams: z.object({
      campaignId: z.coerce.number(),
    }),
    body: CampaignSettingsScheme,
    responses: {
      200: z.null(),
      400: z.null(),
      404: z.null(),
    },
  },

  bulkPauseCampaignCreatives: {
    method: 'POST',
    path: '/creative/bulkPauseCampaignCreatives',
    body: z.object({
      campaignId: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        pausedCreativesIds: z.number().array(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.CAMPAIGN_NOT_FOUND]),
        message: z.string().optional(),
      }),
      500: z.object({ message: z.string() }),
    },
  },

  bulkStartCampaignCreatives: {
    method: 'POST',
    path: '/creative/bulkStartCampaignCreatives',
    body: z.object({
      campaignId: z.coerce.number(),
      runCampaign: z.boolean().optional().default(false),
    }),
    responses: {
      200: z.object({
        startedCreativesIds: z.number().array(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.CAMPAIGN_NOT_FOUND]),
        message: z.string().optional(),
      }),
      500: z.object({ message: z.string() }),
    },
  },

  bulkDeleteCampaignCreatives: {
    method: 'POST',
    path: '/creative/bulkDeleteCampaignCreatives',
    body: z.object({
      campaignId: z.coerce.number(),
    }),
    responses: {
      200: z.object({
        deletedCreativesIds: z.number().array(),
      }),
      400: z.object({
        errorCode: z.enum([ErrorCode.CAMPAIGN_NOT_FOUND]),
        message: z.string().optional(),
      }),
      500: z.object({ message: z.string() }),
    },
  },

  updateCampaignDailyBudget: {
    method: 'POST',
    path: '/campaign/updateCampaignDailyBudget',
    body: z.object({
      campaignId: z.coerce.number(),
      dailyBudget: z.number().gte(0),
    }),
    responses: {
      200: z.object({}),
      400: z.object({
        errorCode: z.enum([
          ErrorCode.CANT_DELETE_LAST_BUDGET_LIMIT,
          ErrorCode.CAMPAIGN_NOT_FOUND,
        ]),
        message: z.string().optional(),
      }),
      500: z.object({ message: z.string() }),
    },
  },

  replaceDomain: {
    method: 'POST',
    path: '/campaign/replaceDomain',
    body: z.object({
      ids: z.number().array(),
      newOrigin: z.string().url(),
    }),
    responses: {
      200: z.object({}),
      400: z.object({
        errorCode: z.enum([
          ErrorCode.CAMPAIGN_NOT_FOUND,
          ErrorCode.CAMPAIGN_WRONG_DOMAIN,
        ]),
        message: z.string().optional(),
      }),
    },
  },

  getCampaignsAutocomplete: {
    method: 'GET',
    path: '/autocomplete/campaigns',
    query: z
      .object({
        title: z.string().optional(),
        campaignType: CampaignTypeEnum.optional(),
      })
      .optional(),
    responses: {
      200: z.object({
        campaigns: z
          .object({
            id: z.number(),
            title: z.string(),
          })
          .array(),
      }),
      500: FiveHundreedResponseSchema,
    },
  },
});
