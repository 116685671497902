function _createForOfIteratorHelper(r,e){var t="undefined"!=typeof Symbol&&r[Symbol.iterator]||r["@@iterator"];if(!t){if(Array.isArray(r)||(t=_unsupportedIterableToArray(r))||e&&r&&"number"==typeof r.length){t&&(r=t);var _n=0,F=function F(){};return{s:F,n:function n(){return _n>=r.length?{done:!0}:{done:!1,value:r[_n++]};},e:function e(r){throw r;},f:F};}throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");}var o,a=!0,u=!1;return{s:function s(){t=t.call(r);},n:function n(){var r=t.next();return a=r.done,r;},e:function e(r){u=!0,o=r;},f:function f(){try{a||null==t["return"]||t["return"]();}finally{if(u)throw o;}}};}function _unsupportedIterableToArray(r,a){if(r){if("string"==typeof r)return _arrayLikeToArray(r,a);var t={}.toString.call(r).slice(8,-1);return"Object"===t&&r.constructor&&(t=r.constructor.name),"Map"===t||"Set"===t?Array.from(r):"Arguments"===t||/^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t)?_arrayLikeToArray(r,a):void 0;}}function _arrayLikeToArray(r,a){(null==a||a>r.length)&&(a=r.length);for(var e=0,n=Array(a);e<a;e++)n[e]=r[e];return n;}import dayjs from"dayjs";import advancedFormat from"dayjs/plugin/advancedFormat.js";import customParseFormat from"dayjs/plugin/customParseFormat.js";import localeData from"dayjs/plugin/localeData.js";import weekOfYear from"dayjs/plugin/weekOfYear.js";import weekYear from"dayjs/plugin/weekYear.js";import weekday from"dayjs/plugin/weekday.js";import{noteOnce}from"rc-util/lib/warning";dayjs.extend(customParseFormat);dayjs.extend(advancedFormat);dayjs.extend(weekday);dayjs.extend(localeData);dayjs.extend(weekOfYear);dayjs.extend(weekYear);dayjs.extend(function(o,c){var proto=c.prototype;var oldFormat=proto.format;proto.format=function f(formatStr){var str=(formatStr||"").replace("Wo","wo");return oldFormat.bind(this)(str);};});var localeMap={// ar_EG:
// az_AZ:
// bg_BG:
bn_BD:"bn-bd",by_BY:"be",// ca_ES:
// cs_CZ:
// da_DK:
// de_DE:
// el_GR:
en_GB:"en-gb",en_US:"en",// es_ES:
// et_EE:
// fa_IR:
// fi_FI:
fr_BE:"fr",// todo: dayjs has no fr_BE locale, use fr at present
fr_CA:"fr-ca",// fr_FR:
// ga_IE:
// gl_ES:
// he_IL:
// hi_IN:
// hr_HR:
// hu_HU:
hy_AM:"hy-am",// id_ID:
// is_IS:
// it_IT:
// ja_JP:
// ka_GE:
// kk_KZ:
// km_KH:
kmr_IQ:"ku",// kn_IN:
// ko_KR:
// ku_IQ: // previous ku in antd
// lt_LT:
// lv_LV:
// mk_MK:
// ml_IN:
// mn_MN:
// ms_MY:
// nb_NO:
// ne_NP:
nl_BE:"nl-be",// nl_NL:
// pl_PL:
pt_BR:"pt-br",// pt_PT:
// ro_RO:
// ru_RU:
// sk_SK:
// sl_SI:
// sr_RS:
// sv_SE:
// ta_IN:
// th_TH:
// tr_TR:
// uk_UA:
// ur_PK:
// vi_VN:
zh_CN:"zh-cn",zh_HK:"zh-hk",zh_TW:"zh-tw"};var parseLocale=function parseLocale(locale){var mapLocale=localeMap[locale];return mapLocale||locale.split("_")[0];};var parseNoMatchNotice=function parseNoMatchNotice(){noteOnce(false,"Not match any format. Please help to fire a issue about this.");};var generateConfig={// get
getNow:function getNow(){return dayjs();},getFixedDate:function getFixedDate(string){return dayjs(string,["YYYY-M-DD","YYYY-MM-DD"]);},getEndDate:function getEndDate(date){return date.endOf("month");},getWeekDay:function getWeekDay(date){var clone=date.locale("en");return clone.weekday()+clone.localeData().firstDayOfWeek();},getYear:function getYear(date){return date.year();},getMonth:function getMonth(date){return date.month();},getDate:function getDate(date){return date.date();},getHour:function getHour(date){return date.hour();},getMinute:function getMinute(date){return date.minute();},getSecond:function getSecond(date){return date.second();},getMillisecond:function getMillisecond(date){return date.millisecond();},// set
addYear:function addYear(date,diff){return date.add(diff,"year");},addMonth:function addMonth(date,diff){return date.add(diff,"month");},addDate:function addDate(date,diff){return date.add(diff,"day");},setYear:function setYear(date,year){return date.year(year);},setMonth:function setMonth(date,month){return date.month(month);},setDate:function setDate(date,num){return date.date(num);},setHour:function setHour(date,hour){return date.hour(hour);},setMinute:function setMinute(date,minute){return date.minute(minute);},setSecond:function setSecond(date,second){return date.second(second);},setMillisecond:function setMillisecond(date,milliseconds){return date.millisecond(milliseconds);},// Compare
isAfter:function isAfter(date1,date2){return date1.isAfter(date2);},isValidate:function isValidate(date){return date.isValid();},locale:{getWeekFirstDay:function getWeekFirstDay(locale){return dayjs().locale(parseLocale(locale)).localeData().firstDayOfWeek();},getWeekFirstDate:function getWeekFirstDate(locale,date){return date.locale(parseLocale(locale)).weekday(0);},getWeek:function getWeek(locale,date){return date.locale(parseLocale(locale)).week();},getShortWeekDays:function getShortWeekDays(locale){return dayjs().locale(parseLocale(locale)).localeData().weekdaysMin();},getShortMonths:function getShortMonths(locale){return dayjs().locale(parseLocale(locale)).localeData().monthsShort();},format:function format(locale,date,_format){return date.locale(parseLocale(locale)).format(_format);},parse:function parse(locale,text,formats){var localeStr=parseLocale(locale);var _iterator=_createForOfIteratorHelper(formats),_step;try{for(_iterator.s();!(_step=_iterator.n()).done;){var format=_step.value;var formatText=text;if(format.includes("wo")||format.includes("Wo")){var year=formatText.split("-")[0];var weekStr=formatText.split("-")[1];var firstWeek=dayjs(year,"YYYY").startOf("year").locale(localeStr);for(var j=0;j<=52;j+=1){var nextWeek=firstWeek.add(j,"week");if(nextWeek.format("Wo")===weekStr){return nextWeek;}}parseNoMatchNotice();return null;}var date=dayjs(formatText,format,true).locale(localeStr);if(date.isValid()){return date;}}}catch(err){_iterator.e(err);}finally{_iterator.f();}if(text){parseNoMatchNotice();}return null;}}};export default generateConfig;