import { Col, Form, Row, Input, Button, FormProps, Alert, Typography, Space } from 'antd'
import './../../i18/config'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import Title from '../../components/layout/Title/Title'
import { defaultFormLayout } from '../../helpers/formLayouts'
import { DEFAULT_TITLE } from '../../constant'
import { useAppState } from '../../context/AppContext'
import { useQueryClient } from '@tanstack/react-query'
import showNotification from '../../helpers/showNotification'

const { Text } = Typography

export const validatePasswordComplexity = (_: any, value: string) => {
  if (!value) {
    return Promise.resolve()
  }

  const passwordCriteria = [
    {
      regex: /[0-9]/,
      message: 'Password must contain at least one number',
    },
    {
      regex: /.{8,}/,
      message: 'Password must be at least 8 characters long',
    },
  ]

  for (const criterion of passwordCriteria) {
    if (!criterion.regex.test(value)) {
      return Promise.reject(new Error(criterion.message))
    }
  }

  return Promise.resolve()
}

// @ts-ignore
export const SignupPage: React.FC<{ updateToken: (token: string) => void }> = ({ updateToken }) => {
  const { apiClient } = useAppState()
  const [showVerificationCodeForm, setShowVerificationCodeForm] = useState(false)
  const [alertError, setAlertError] = useState('')
  const [userInfo, setUserInfo] = useState<{ email: string; jwtToken: string } | null>(null)
  const queryClient = useQueryClient()
  const [form] = Form.useForm()

  const { t } = useTranslation()

  useEffect(() => {
    document.title = `Signup`

    return () => {
      document.title = DEFAULT_TITLE
    }
  }, [])

  const onFinish: FormProps<{
    email: string
    preferredContact?: string
    password: string
    confirmPassword: string
  }>['onFinish'] = async ({ email, password, confirmPassword, preferredContact }) => {
    if (password !== confirmPassword) {
      setAlertError('Password not equal')
      setTimeout(() => setAlertError(''), 4000)
      return
    }

    const res = await apiClient.auth.register.mutation({ body: { email, password, preferredContact } })

    if (res.status === 201) {
      setShowVerificationCodeForm(true)

      setUserInfo({ email, jwtToken: res.body.jwtToken })
    }
  }

  const confirmEmailCode: FormProps<{
    code: string
  }>['onFinish'] = async formProps => {
    if (userInfo) {
      const { status } = await apiClient.user.confirmEmailCode.mutation({
        body: { email: userInfo?.email, code: formProps.code },
      })

      if (status === 201 && userInfo.jwtToken) {
        await updateToken(userInfo.jwtToken)
        queryClient.clear()
      }
    }
  }

  const resendConfirmationEmailCode = async (e: any) => {
    e.preventDefault()
    if (userInfo) {
      const { status } = await apiClient.user.resendConfirmationEmailCode.mutation({
        body: { email: userInfo?.email },
      })

      if (status === 201) {
        showNotification({ type: 'success', message: 'Code was sent' })
      }
    }
  }

  return (
    <>
      {alertError && <Alert message={alertError} type="error" closable />}

      <Form
        hidden={showVerificationCodeForm}
        {...defaultFormLayout}
        labelCol={{ span: 8 }}
        onFinish={onFinish}
        form={form}
      >
        <Row>
          <Col offset={12}>
            <Title level={3} isSeparator={true}>
              {t('SignupPage.Sign up title')}
            </Title>
          </Col>
        </Row>
        <Row>
          <Col offset={8} span={10}>
            <Form.Item
              hasFeedback
              label={t('Common.Email' as const)}
              name="email"
              rules={[{ required: true, type: 'email', message: 'Please input your email!' }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={8} span={10}>
            <Form.Item
              hasFeedback
              label={t('Common.Password' as const)}
              name="password"
              rules={[
                { required: true, message: 'Please input your password!' },
                {
                  required: true,
                  validator: validatePasswordComplexity,
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col offset={8} span={10}>
            <Form.Item
              hasFeedback
              label={'Confirm password'}
              name="confirmPassword"
              rules={[
                { required: true, message: 'Please input your confirm password!' },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(new Error('The new password that you entered do not match!'))
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={8} span={10}>
            <Form.Item label={`${'Contact'}`} name="preferredContact" rules={[{ required: false }]}>
              <Input placeholder="phone number, telegram, etc" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col offset={12}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Col>
        </Row>
      </Form>

      <Form hidden={!showVerificationCodeForm} {...defaultFormLayout} onFinish={confirmEmailCode}>
        <Row justify="center">
          <Col>
            <Text>We have sent a confirmation code to your email.</Text>
          </Col>
        </Row>
        <Row justify="center">
          <Col>
            <Form.Item
              label={'Code'}
              name="code"
              rules={[
                {
                  required: true,
                  min: 6,
                  max: 6,
                  message: 'Please input verification code! Maximum length 6 symbols.',
                },
              ]}
            >
              <Space.Compact style={{ width: '100%' }}>
                <Input />
                <Button type="primary" htmlType="submit">
                  Submit
                </Button>
              </Space.Compact>
            </Form.Item>
          </Col>
        </Row>

        <Row justify="center">
          <Col>
            <Button type="link" block onClick={resendConfirmationEmailCode}>
              Resend code
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}
