import { ConditionSet, RuleTarget } from '@pushflow/backend-http-contract'
import { Row, Tag } from 'antd'
import React, { FC } from 'react'
import { ruleTargetColorMap, ruleTargetNameMap } from './action-overview'
import { RuleGroupType, RuleType } from 'react-querybuilder'
import { conditionSetToQuery } from '../utils/qb-to-condition'
import { v4 } from 'uuid'

interface TaggedQueryDisplayProps {
  query: RuleGroupType
}

const SafeQueryDisplay: FC<TaggedQueryDisplayProps> = ({ query }) => {
  const renderRule = (rule: RuleType) => {
    return (
      <span>
        <Tag>{rule.field}</Tag>
        <Tag color={rule.operator === '>' ? 'green' : 'red'}>{rule.operator}</Tag>
        <Tag>{rule.value}</Tag>
      </span>
    )
  }

  const renderGroup = (group: RuleGroupType) => {
    const renderedRules = group.rules.map((rule, index) => {
      const combinatorTag =
        index > 0 ? (
          <Tag
            key={rule.id}
            color={group.combinator === 'and' ? 'orange' : 'blue'}
            style={{ marginLeft: 4, marginRight: 4 }}
          >
            {group.combinator.toUpperCase()}
          </Tag>
        ) : null

      if ('field' in rule) {
        return (
          <React.Fragment key={rule.id || v4()}>
            {combinatorTag}
            {renderRule(rule as RuleType)}
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment key={(rule as RuleGroupType).id || v4()}>
            {combinatorTag}
            {renderGroup(rule as RuleGroupType)}
          </React.Fragment>
        )
      }
    })

    return group.rules.length > 1 ? (
      <span key={group.id}>({renderedRules})</span>
    ) : (
      <span key={group.id}>{renderedRules}</span>
    )
  }

  return <div>{renderGroup(query)}</div>
}

export const ConditionSetOverview: FC<{
  ruleTarget: RuleTarget
  conditions: ConditionSet
  analysisPeriod: number
  isTop: boolean
}> = ({ ruleTarget, conditions, isTop, analysisPeriod }) => {
  if (!conditions) {
    return <>No data about condition</>
  }

  return (
    <div
      style={{
        margin: isTop ? '10px' : '0',
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: '100%',
        alignItems: 'baseline',
        justifyContent: 'space-between',
        gap: '1rem',
      }}
    >
      {/* {isTop === true ? <b>IF&nbsp;</b> : <></>} */}
      <Row align="middle" style={{ cursor: 'pointer' }}>
        {isTop && (
          <Tag style={{ borderRadius: '10px' }} color={ruleTargetColorMap[ruleTarget]}>
            {ruleTargetNameMap[ruleTarget].toUpperCase()}
          </Tag>
        )}
        <SafeQueryDisplay query={conditionSetToQuery(conditions)} />
        {analysisPeriod === 1 ? (
          <>&nbsp;for last 24 hours</>
        ) : (
          <>
            for last&nbsp;<Tag>{analysisPeriod}</Tag>days
          </>
        )}
      </Row>
    </div>
  )
}
