import React, { FC, useEffect, useState } from 'react'
import { ConditionSetForm, defaultCondition } from './condition-set.form'
import { ConditionSetOverview } from './condition-set-overview'
import {
  ConditionSet,
  ResultAction,
  ResultActionTargetId,
  RuleTarget,
  ResultActionTargetForChangeBid,
  ResultActionTargetForAddToList,
  FrequencyType,
  Rule,
} from '@pushflow/backend-http-contract'
import { Button, Col, Divider, Form, Input, Row, Select, Typography } from 'antd'
import { useAppState } from '../../../context/AppContext'
import { ActionOverview } from './action-overview'
import { getListType, ResultActionForm } from './result-action.form'
import showNotification from '../../../helpers/showNotification'
import { useNavigate } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { getRuleError } from '../utils/validate-rule'
import { FrequencyForm } from './frequency.form'

export const RuleCreate: FC = () => {
  const navigate = useNavigate()
  const { apiClient } = useAppState()
  const queryClient = useQueryClient()

  const { mutateAsync: createRule } = apiClient.rule.createRule.useMutation({
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['rule'] })
      await queryClient.invalidateQueries({ queryKey: ['rules'] })
      await queryClient.invalidateQueries({ queryKey: ['rule-matched'] })
    },
  })
  const { data: campaigns } = apiClient.campaign.getCampaignsAutocomplete.useQuery(['campaigns-autocomplete'], {
    query: {},
  })

  const [showConditionEditor, setShowConditionEditor] = useState(false)
  const [showActionEditor, setShowActionEditor] = useState(false)
  const [checkCamapigns, setCheckCampaigns] = useState<'all' | 'selected'>('all')

  const [title, setTitle] = useState('')
  const [conditions, setConditions] = useState<ConditionSet>({ combinator: 'and', conditions: [defaultCondition] })
  const [ruleTarget, setRuleTarget] = useState<RuleTarget>('campaign')
  const [campaignIds, setCampaignIds] = useState<number[]>([])
  const [analysisPeriod, setAnalysisPeriod] = useState(1)
  const [resultAction, setResultAction] = useState<ResultAction>('stop')
  const [resultActionTarget, setResultActionTarget] = useState<ResultActionTargetId>(null)
  const [frequencyType, setFrequencyType] = useState<FrequencyType>('at')
  const [frequency, setFrequency] = useState<number>(0)

  const { data: blackWhiteLists, refetch: refetchBlWls } = apiClient.blackWhiteList.getListListPaginated.useQuery(
    ['blackWhiteLists'],
    {
      query: { filter: { listType: getListType(ruleTarget) }, limit: 1000, offset: 0 },
      overrideClientOptions: {
        jsonQuery: false,
      },
    }
  )

  useEffect(() => {
    refetchBlWls()
    setResultAction('stop')
    setResultActionTarget(null)
  }, [ruleTarget])

  const handleSubmit = async () => {
    const newRule: Omit<Rule, 'status'> = {
      title,
      condition: conditions,
      ruleTarget,
      targetId: {
        campaignIds,
        streamIds: null,
        substreams: null,
      },
      resultAction,
      resultActionTarget,
      analysisPeriod,
      frequencyType,
      frequency,
    }
    const err = getRuleError(newRule, checkCamapigns, campaignIds)
    if (err) {
      showNotification({ type: err.type, message: err.message })
      return
    }

    try {
      const res = await createRule({
        body: {
          status: 'WORKING',
          ...newRule,
        },
      })

      showNotification({ type: 'success', message: 'Rule created' })
      navigate('/automations/' + res.body.id)
    } catch (e) {
      console.error(e)
      showNotification({ type: 'error', message: 'Failed to create rule' })
    }
  }

  return (
    <>
      <Form.Item label="Rule name">
        <Input
          style={{ width: '50%' }}
          value={title}
          onChange={e => setTitle(e.target.value)}
          placeholder="Rule name"
        />
      </Form.Item>
      <Col>
        <Row align={'middle'} style={{ gap: '10px' }}>
          <Col>For </Col>
          <Col span={4}>
            <Select
              style={{ width: '100%' }}
              value={checkCamapigns}
              options={[
                { label: 'all campaigns', value: 'all' },
                { label: 'selected campaigns', value: 'selected' },
              ]}
              onChange={(v: any) => setCheckCampaigns(v)}
            />
          </Col>
          {checkCamapigns === 'selected' && (
            <Col span={18}>
              <Select
                placeholder="Select campaigns"
                style={{ width: '100%' }}
                mode="multiple"
                value={campaignIds}
                maxTagCount={4}
                options={campaigns?.body.campaigns.map(c => {
                  return { label: `ID: ${c.id} | ${c.title}`, value: c.id }
                })}
                onChange={(v: number[]) => {
                  setCampaignIds(v)
                }}
                filterOption={(str, o) => {
                  const { label } = o || { value: '', label: '' }
                  return label.toLowerCase().includes(str.toLowerCase()) && label !== ''
                }}
              />
            </Col>
          )}
          <Col span={4}>check stats of </Col>
          <Col>
            <Select
              labelInValue={true}
              value={ruleTarget}
              options={[
                { label: 'campaigns', value: 'campaign' },
                { label: 'streams of campaign', value: 'campaign_stream' },
                { label: 'substreams of campaign', value: 'campaign_stream_substream' },
              ]}
              onChange={(v: any) => setRuleTarget(v.value)}
            />
          </Col>
          <Col>for last</Col>
          <Col span={2}>
            <Input
              placeholder="Enter days (integer 1-7)"
              value={analysisPeriod}
              onChange={e => {
                const value = parseInt(e.target.value)
                if (!isNaN(value)) {
                  if (value >= 1 && value <= 7) {
                    setAnalysisPeriod(value)
                    return
                  }
                  if (value < 1) {
                    setAnalysisPeriod(1)
                    return
                  }
                  if (value > 7) {
                    setAnalysisPeriod(7)
                    return
                  }
                }
                setAnalysisPeriod(1)
              }}
              min={1}
              max={7}
              style={{ width: '100%' }}
              type="number"
            />
          </Col>
          <Col>days</Col>
        </Row>
      </Col>
      <Divider />
      <Typography.Title level={5}>IF</Typography.Title>
      <ConditionSetOverview
        ruleTarget={ruleTarget}
        conditions={conditions}
        isTop={true}
        analysisPeriod={analysisPeriod}
      />
      <Button
        size="small"
        onClick={() => {
          setShowConditionEditor(!showConditionEditor)
        }}
      >
        {showConditionEditor ? 'Hide' : 'Show'} editor
      </Button>
      {showConditionEditor && (
        <ConditionSetForm condition={conditions} onConditionSetChange={cs => setConditions(cs)} />
      )}
      <Divider />
      <Typography.Title level={5}>THEN</Typography.Title>

      <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
        <ActionOverview
          ruleTarget={ruleTarget}
          resultAction={resultAction}
          resultActionTarget={resultActionTarget}
          onClick={() => setShowActionEditor(!showActionEditor)}
        />
      </div>
      <Button
        size="small"
        onClick={() => {
          setShowActionEditor(!showActionEditor)
        }}
      >
        {showActionEditor ? 'Hide' : 'Show'} editor
      </Button>
      {showActionEditor && (
        <ResultActionForm
          ruleTarget={ruleTarget}
          resultAction={resultAction}
          blackWhiteLists={blackWhiteLists?.body.items || []}
          onResultAction={ra => {
            setResultAction(ra)
            if (ra === 'stop') {
              setResultActionTarget(null)
            }
            if (ra === 'increase_bid' || ra === 'decrease_bid') {
              const change = 10
              const limit = ra === 'increase_bid' ? 110 : 40
              setResultActionTarget(ResultActionTargetForChangeBid(change, limit))
            }
            if (ra === 'add_to_list') {
              if (!blackWhiteLists || blackWhiteLists?.body.items.length === 0) {
                showNotification({ type: 'warning', message: 'No black/white lists available. Please, create one' })
                return
              }

              setResultActionTarget(ResultActionTargetForAddToList(blackWhiteLists?.body.items[0].id || 0))
            }
          }}
          resultActionTargetId={resultActionTarget}
          onResultActionTargetId={rat => setResultActionTarget(rat)}
        />
      )}
      <Divider />
      <Typography.Title level={5}>Repeat</Typography.Title>
      <FrequencyForm
        frequency={frequency}
        frequencyType={frequencyType}
        onChange={(ft, f) => {
          setFrequencyType(ft)
          setFrequency(f)
        }}
      />
      <Divider />
      <Button type="primary" onClick={handleSubmit}>
        Save rule
      </Button>
    </>
  )
}
